import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyValueDisplay from "../../KeyValue";
import { useTranslation } from "react-i18next";

const ViewDepartment = () => {
  const selectedDepartment = useSelector(
    (store) => store.setting.selectedDepartView
  );
console.log('selectedDepartment', selectedDepartment);

  const navigate = useNavigate();
  const {t}=useTranslation()

  if (!selectedDepartment) {
    return (
      <div className="font-roboto">No account sub type data available.</div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center font-roboto">
        <h2 className="flex p-5 font-bold">View Department</h2>
        <div className="flex justify-end p-5">
          <button
            onClick={() => navigate("/body/departmentList")}
            className="bg-color-light-blue text-color-white px-4 py-2 rounded"
          >
            {t("Department.back")}
          </button>
        </div>
      </div>

      <div className="border w-[78] h-auto mx-5 p-4 rounded shadow-md font-roboto bg-color-white">
        <KeyValueDisplay data={selectedDepartment} />
      </div>
    </div>
  );
};

export default ViewDepartment;
