//export const MAIN_API_URL = "http://192.168.1.23:8036"
export const MAIN_API_URL = "https://api.innovitegrasuite.online"
export const LOGIN_URL = MAIN_API_URL + "/qmsadmin/user/user_login";

export const serverpath = MAIN_API_URL;

//INSITUTION APIs:
export const LIST_INSTITUTION = MAIN_API_URL + "/qmsadmin/inst/view_inst";
export const ADD_INSTITUTION = MAIN_API_URL + "/qmsadmin/inst/add_inst";
export const EDIT_INSTITUTION = MAIN_API_URL + "/qmsadmin/inst/edit_inst";
export const DELETE_INSTITUTION = MAIN_API_URL + "/qmsadmin/inst/delete_inst"
//DEPAARTMENT APIs:
export const LIST_DEPARTMENT =
  MAIN_API_URL + "/qmsadmin/department/view_department";
export const ADD_DEPARTMENT =
  MAIN_API_URL + "/qmsadmin/department/add_department";
export const EDIT_DEPARTMENT =
  MAIN_API_URL + "/qmsadmin/department/edit_department";
export const DELETE_DEPARTMENT =
  MAIN_API_URL + "/qmsadmin/department/delete_department";

//SERVICE APIs:
export const SERVICE_LIST = MAIN_API_URL + "/qmsadmin/service/view_services";
export const ADD_SERVICE = MAIN_API_URL + "/qmsadmin/service/add_service";
export const EDIT_SERVICE = MAIN_API_URL + "/qmsadmin/service/edit_service";
export const DELETE_SERVICE = MAIN_API_URL + "/qmsadmin/service/delete_service";
export const SERVICE_GET_BYID =
  MAIN_API_URL + "/qmsadmin/service/fetch_service";

//Users Apis:
export const ADD_USERS = MAIN_API_URL + "/qmsadmin/user/add_user";
export const USERS_LIST_INADMIN = MAIN_API_URL + "/qmsadmin/user/view_users";
export const USER_DELETE = MAIN_API_URL + "/qmsadmin/user/delete_user";
export const EDIT_USER = MAIN_API_URL + "/qmsadmin/user/edit_user";
export const GET_USER_BYID =  MAIN_API_URL +'/qmsadmin/user/fetch_user'

//BRANCH APIs:
export const LIST_BRANCH = MAIN_API_URL + "/qmsadmin/branch/view_branches";
export const ADD_BRANCH = MAIN_API_URL + "/qmsadmin/branch/add_branch";
export const EDIT_BRANCH = MAIN_API_URL + "/qmsadmin/branch/edit_branch";
export const DELETE_BRANCH = MAIN_API_URL + "/qmsadmin/branch/delete_branch";

//Counter
export const COUNTER_LIST = MAIN_API_URL + "/qmsadmin/counter/view_counters";
export const DELETE_COUNTER = MAIN_API_URL + "/qmsadmin/counter/delete_counter";
export const ADD_COUNTER = MAIN_API_URL + "/qmsadmin/counter/add_counter";
export const EDIT_COUNTER =  MAIN_API_URL + "/qmsadmin/counter/edit_counter"

//Profile Apis
export const PROFILE_LIST =
  MAIN_API_URL + "/qmsadmin/user_profile/view_profiles";
export const ADD_PROFILE =
  MAIN_API_URL + "/qmsadmin/user_profile/create_profile";
export const DELETE_PROFILE = MAIN_API_URL + "/qmsadmin/user_profile/delete_profile";
export const GET_PROFILE_BYID = MAIN_API_URL +'/qmsadmin/user_profile/fetch_profile'
export const UPDATE_PROFILE = MAIN_API_URL + '/qmsadmin/user_profile/update_profile'

//Terminal
export const ADD_TERMINAL = MAIN_API_URL + "/qmsadmin/terminal/create";
export const TERMINAL_LIST = MAIN_API_URL + "/qmsadmin/terminal/view";
export const EDIT_TERMINAL = MAIN_API_URL + "/qmsadmin/terminal/edit";
export const DELETE_TERMINAL = MAIN_API_URL + "/qmsadmin/terminal/delete";

//Token Configuration
export const CREATE_TOKEN_CONFIG = MAIN_API_URL + "/qmsadmin/token/create_config";
export const UPDATE_TOKEN_CONFIG = MAIN_API_URL + "/qmsadmin/token/update_config";
export const Token_Config_List = MAIN_API_URL + "/qmsadmin/token/view_config";
export const DELETE_TOKEN_CONFIG = MAIN_API_URL + "/qmsadmin/token/delete_config";

  //Token management
  export const COMPLETE_TOKEN = MAIN_API_URL + '/qmsadmin/counter/serve_token'
  export const CALL_NEXT_TOKEN = MAIN_API_URL + '/qmsadmin/counter/assign_token'
  export const HOLD_TOKEN = MAIN_API_URL +'/qmsadmin/counter/hold_token'
  export const HOLD_TOKEN_LIST = MAIN_API_URL +'/qmsadmin/token/view_tokens'
  export const TOKENS_LIST = MAIN_API_URL + '/qmsadmin/token/view_tokens'
  export const DELETE_RECALL_TOKEN = MAIN_API_URL + '/qmsadmin/token/delete_token'
  export const SERVE_HELD_TOKEN = MAIN_API_URL + '/qmsadmin/counter/assign_holded_token'
  export const Transfer_TOKEN = MAIN_API_URL + '/qmsadmin/counter/transfer_token'

//Password Plolicy
export const PP_CREATE = MAIN_API_URL + '/qmsadmin/user_password/create_policy'
export const PP_LIST = MAIN_API_URL + '/qmsadmin/user_password/view_policies'
export const PP_EDIT = MAIN_API_URL + '/qmsadmin/user_password/update_policy'
export const PP_DELETE = MAIN_API_URL + '/qmsadmin/user_password/delete_policy'

//Terminal
export const CREATE_TOKEN  = MAIN_API_URL + "/qmsadmin/terminal/create_token"

//Tokens Panel
export const PANEL_CREATE = MAIN_API_URL  + "/qmsadmin/panel/create_panel"
export const PANEL_LIST = MAIN_API_URL + '/qmsadmin/panel/view_panels'
export const PANEL_UPDATE = MAIN_API_URL + '/qmsadmin/panel/update_panel'
export const PANEL_DELETE = MAIN_API_URL + '/qmsadmin/panel/delete_panel'

//Panel Display
export const PANEL_DISPLAY = MAIN_API_URL + "/qmsadmin/counter/view_counters"

//DashBoard
export const DashBoardTATApi = MAIN_API_URL + "/qmsadmin/dashboard/turn_around_time"
