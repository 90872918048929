import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../Redux/UserManagementSlice";
import Modal from "./LoginPop";
import LoginForm from "./LoginForm";
import { setDeviceId, setToken } from "../../Redux/AuthToken";
import { LOGIN_URL } from "../../Utils/Constant";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({}); 
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const deviceID = useSelector((state) => state.token.deviceId)
  const { t } = useTranslation();

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      dispatch(setDeviceId(result.visitorId));
    };

    loadFingerprint();
  }, [dispatch]);

  const validateForm = (userName, password) => {
    let isValid = true;
    let errors = {};

    if (!userName) {
      errors.userName = "User ID is required";
      isValid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const loginUser = async ({ userName, password }) => {
    if (!validateForm(userName, password)) return;
    const username = "webadmin";
    const pwd = "4970FAB298E271E430010235E9C88EA5E467DEEF";

    const basicAuth = "Basic " + btoa(username + ":" + pwd);

    try {
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
          DeviceID: deviceID,
        },
        body: JSON.stringify({
          user_name: userName,
          password,
          user_type: "CORPORATE",
        }),
      });

      const data = await response.json();

      if (response.ok && data.user_details !== null) {
        dispatch(userData(data));
        dispatch(setToken(data.token_info.refresh_token));
        sessionStorage.setItem("refreshToken", data.token_info.refresh_token);
        navigate("/body");
        toast.success("User login successful");
      } 
      else if (response.ok && data?.terminal_details !== null) {
        dispatch(userData(data));
        sessionStorage.setItem("refreshToken", data.token_info.refresh_token);
        dispatch(setToken(data.token_info.refresh_token));
        navigate("/body/kiosk");
        toast.success("Kiosk login successful");
      }
      else if(response.ok && data?.panel_details !== null) {
        dispatch(userData(data));
        navigate("/body/panelDisplay");
        dispatch(setToken(data.token_info.refresh_token));
        sessionStorage.setItem("refreshToken", data.token_info.refresh_token);
        toast.success("Panel login successful");
      } 
      else {
        setErrorMessage("Invalid username or password. Please try again.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An error occurred. Please try again later.");
      setShowModal(true);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row h-screen">
      {/* Left section (Image and Background) */}
      <div className="hidden sm:flex flex-col justify-center items-center w-[50%] bg-gradient-to-r from-gray-700 to-gray-800 p-10 relative">
        <div className="text-center">
          <h1 className="text-4xl text-white font-bold">{t("login_title")}</h1>
          <p className="mt-4 text-white">{t("login_subtitle")}</p>
        </div>
        <footer className="absolute bottom-4 text-white text-sm">
          {t("footer_title")}
        </footer>
      </div>

      {/* Right section (Login Form) */}
      <div className="flex justify-center items-center w-full sm:w-[50%] p-8 h-full sm:h-auto">
  <div>
    <h1 className="font-roboto font-bold text-4xl text-gray-800 mb-4">
      {t("rlogin_title")}
    </h1>
    <p className="text-gray-500 mb-8">{t("rlogin_subtitle")}</p>

    <LoginForm onSubmit={loginUser} errors={errors} />
  </div>
</div>


      {/* Modal for errors */}
      {showModal && (
        <Modal message={errorMessage} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default Login;