import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'lucide-react';

const Modal = ({
  onClose = () => {},
  title,
  width = '600px',
  height,
  paddingBottom,
  children,
  keepOpen = true,
  showCloseButton = true,
  shortcut = true,
  isDarkMode = false,
  titleBgColor = '#F59E0B',
}) => {
  const [isHover, setIsHover] = useState(false);
  let modalRef = null;

  React.useEffect(() => {
    document.documentElement.classList.add('overflow-hidden');
    return () => document.documentElement.classList.remove('overflow-hidden');
  }, []);

  const onClickOutside = (event) => {
    if (modalRef === null || modalRef.contains(event.target) || keepOpen) return;
    onClose();
  };

  const modalContent = (
    <div
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
      onClick={onClickOutside}
      onKeyDown={(e) => {
        if (e.key === 'Escape' && shortcut) onClose();
      }}
    >
      <div
        ref={(ref) => (modalRef = ref)}
        tabIndex="0"
        className={`bg-white rounded-lg shadow-xl ${
          isDarkMode ? 'bg-gray-800' : ''
        }`}
        style={{
          width: '100%', 
          maxWidth: width, 
          height: height || 'auto', 
          paddingBottom: paddingBottom,
        }}
      >
        {showCloseButton && (
          <div
            className="flex items-center justify-between h-[45px] px-4 rounded-t-lg"
            style={{ backgroundColor: titleBgColor }}
          >
            <div className="text-black font-semibold text-sm">{title}</div>
            <div>
              <button
                onClick={onClose}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className={`p-1 rounded-full transition-colors ${
                  isHover
                    ? 'bg-white border border-[#457AB1]'
                    : 'text-white hover:bg-[#457AB1]'
                }`}
              >
                <X
                  size={20}
                  className={isHover ? 'text-black' : 'text-white'}
                />
              </button>
            </div>
          </div>
        )}
        <div className="p-4">{children}</div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default Modal;
