import React, { useEffect, useState } from "react";
import { InputField } from "../../CustomReusableComps/InputField";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ToggleButton from "../../CustomReusableComps/ToggleButton";
import { PP_CREATE, PP_EDIT } from "../../../Utils/Constant";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setSelectedPasswordPolicyView } from "../../../Redux/PasswordPolicy";

const PasswordPolicyForm = () => {
  const [policyData, setPolicyData] = useState({
    policyName: "",
    minCharacter: null,
    maxCharacter: null,
    upperCase: false,
    specialCase: false,
    numericCase: false,
    loginRetyCount: null,
    passwordResetCount: null,
    inactiveUserInterval: null,
    previousPasswordRestrictCount: null,
    passwordValidity: null,
    notifyResetPassInDay: null,
    notification_frequency_flag: null,
    notification_frequency: null,
    policyStatus: false,
  });

  const t = useTranslation();
  const navigate = useNavigate();
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector(
    (state) => state.userManagement.items.user_details
  );
  const selectedPasswordPolicy = useSelector(
    (state) => state.passwordPolicy.selectedpasswordPolicyView
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedPasswordPolicy) {
      setPolicyData({
        policyName: selectedPasswordPolicy.policy_name,
        minCharacter: selectedPasswordPolicy.min_length,
        maxCharacter: selectedPasswordPolicy.max_length,
        upperCase: selectedPasswordPolicy.uppercase_flag,
        specialCase: selectedPasswordPolicy.specialcase_flag,
        numericCase: selectedPasswordPolicy.numeric_flag,
        loginRetyCount: selectedPasswordPolicy.login_retry_count,
        passwordResetCount: selectedPasswordPolicy.pwd_reset_count,
        inactiveUserInterval: selectedPasswordPolicy.allowed_inactive_days,
        previousPasswordRestrictCount:
          selectedPasswordPolicy.previous_pwd_restrict_count,
        passwordValidity: selectedPasswordPolicy.pwd_validity,
        notifyResetPassInDay: selectedPasswordPolicy.notify_before_pwd_expiry,
        notification_frequency_flag:
          selectedPasswordPolicy.notification_frequency_flag,
        notification_frequency: selectedPasswordPolicy.notification_frequency,
        policyStatus: selectedPasswordPolicy.status,
      });
    }
  }, [selectedPasswordPolicy]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;

    // Fields that should accept only numeric input
    const numericFields = [
      "minCharacter",
      "maxCharacter",
      "loginRetyCount",
      "passwordResetCount",
      "inactiveUserInterval",
      "previousPasswordRestrictCount",
      "passwordValidity",
      "notifyResetPassInDay",
      "notification_frequency",
    ];

    if (numericFields.includes(id)) {
      const numericValue = value === "" ? null : value.replace(/[^0-9]/g, "");
      setPolicyData((prevData) => ({
        ...prevData,
        [id]: numericValue,
      }));
    } else {
      setPolicyData((prevData) => ({
        ...prevData,
        [id]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setPolicyData((prev) => ({
      ...prev,
      policyStatus: prev.policyStatus === false ? true : false,
    }));
  };
  const handleFrequencyToggle = (e) => {
    e.preventDefault();
    setPolicyData((prev) => ({
      ...prev,
      notification_frequency_flag: !prev.notification_frequency_flag,
      // Clear notification_frequency when toggling off
      notification_frequency: !prev.notification_frequency_flag ? prev.notification_frequency : null
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (selectedPasswordPolicy) {
        // Create payload for edit
        const editPayload = {
          pwd_policy_id: selectedPasswordPolicy.pwd_policy_id,
          policy_name: policyData.policyName,
          min_length: Number(policyData.minCharacter),
          max_length: Number(policyData.maxCharacter),
          uppercase_flag: policyData.upperCase,
          specialcase_flag: policyData.specialCase,
          numeric_flag: policyData.numericCase,
          login_retry_count: Number(policyData.loginRetyCount),
          pwd_reset_count: Number(policyData.passwordResetCount),
          allowed_inactive_days: Number(policyData.inactiveUserInterval),
          previous_pwd_restrict_count: Number(policyData.previousPasswordRestrictCount),
          pwd_validity: Number(policyData.passwordValidity),
          notify_before_pwd_expiry: Number(policyData.notifyResetPassInDay),
          notification_frequency_flag: policyData.notification_frequency_flag,
          notification_frequency: policyData.notification_frequency,
          status: policyData.policyStatus,
          scope_id: selectedPasswordPolicy.scope_id,
          policy_scope_level: "Institution",
        };
  
        // Only add notification_frequency if flag is true
        if (policyData.notification_frequency_flag) {
          editPayload.notification_frequency = Number(policyData.notification_frequency);
        }
  
        const response = await fetch(PP_EDIT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify(editPayload),
        });
  
        const responseData = await response.json();
        if (response.ok) {
          toast.success("Counter updated successfully!");
          dispatch(setSelectedPasswordPolicyView(null));
          setPolicyData({
            policyName: "",
            minCharacter: null,
            maxCharacter: null,
            upperCase: false,
            specialCase: false,
            numericCase: false,
            loginRetyCount: null,
            passwordResetCount: null,
            inactiveUserInterval: null,
            previousPasswordRestrictCount: null,
            passwordValidity: null,
            notifyResetPassInDay: null,
            notification_frequency_flag: null,
            notification_frequency: null,
            policyStatus: false,
          });
          navigate("/body/passwordPolicy");
        } else {
          toast.error(`Failed to update Password policy: ${responseData.message}`);
        }
      } else {
        // Create payload for new policy
        const createPayload = {
          policy_name: policyData.policyName,
          min_length: Number(policyData.minCharacter),
          max_length: Number(policyData.maxCharacter),
          specialcase_flag: policyData.specialCase,
          uppercase_flag: policyData.upperCase,
          numeric_flag: policyData.numericCase,
          status: policyData.policyStatus,
          login_retry_count: Number(policyData.loginRetyCount),
          pwd_reset_count: Number(policyData.passwordResetCount),
          allowed_inactive_days: Number(policyData.inactiveUserInterval),
          previous_pwd_restrict_count: Number(policyData.previousPasswordRestrictCount),
          pwd_validity: Number(policyData.passwordValidity),
          notify_before_pwd_expiry: Number(policyData.notifyResetPassInDay),
          notification_frequency_flag: policyData.notification_frequency_flag,
          notification_frequency : policyData.notification_frequency,
          policy_scope_level: "Institution",
          scope_id: userData?.inst_id,
        };
  
        // Only add notification_frequency if flag is true
        if (policyData.notification_frequency_flag) {
          createPayload.notification_frequency = Number(policyData.notification_frequency);
        }
  
        const response = await fetch(PP_CREATE, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify(createPayload),
        });
  
        const responseData = await response.json();
        if (response.ok) {
          toast.success("Password Policy Created Successfully");
          navigate("/body/passwordPolicy");
        } else {
          toast.error(responseData.message || t("An error occurred"));
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message || t("An error occurred"));
    }
  };

  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold">{" Password Policy"}</h1>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <InputField
              type="text"
              id="policyName"
              value={policyData.policyName}
              onChange={handleChange}
              required
              label={"Password Policy Name *"}
            />
            <InputField
              label={"Minimum Character *"}
              type="number"
              id="minCharacter"
              value={policyData.minCharacter}
              onChange={handleChange}
              required
            />
            <InputField
              label={"Maximum Character"}
              type="number"
              id="maxCharacter"
              value={policyData.maxCharacter}
              onChange={handleChange}
              required
            />
            <div>
              <div className="flex justify-start gap-4 mt-4">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      id="upperCase"
                      checked={policyData.upperCase}
                      onChange={handleChange}
                    />
                    Upper Case
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      id="specialCase"
                      checked={policyData.specialCase}
                      onChange={handleChange}
                    />
                    Special Case
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      id="numericCase"
                      checked={policyData.numericCase}
                      onChange={handleChange}
                    />
                    Numeric Case
                  </label>
                </div>
              </div>
            </div>
            <InputField
              label={"Login Retry Count *"}
              id="loginRetyCount"
              type="number"
              value={policyData.loginRetyCount}
              onChange={handleChange}
              required
            />
            <InputField
              label={"Password Reset Count*"}
              type="number"
              id="passwordResetCount"
              value={policyData.passwordResetCount}
              onChange={handleChange}
              required
            />
            <InputField
              label={"Inactive User Interval (in days) *"}
              type="number"
              id="inactiveUserInterval"
              value={policyData.inactiveUserInterval}
              onChange={handleChange}
              required
            />
            <InputField
              label={"Password History Restrict Count *"}
              type="number"
              id="previousPasswordRestrictCount"
              value={policyData.previousPasswordRestrictCount}
              onChange={handleChange}
              required
            />
            <InputField
              label={"Password Validity (in days) *"}
              type="number"
              id="passwordValidity"
              value={policyData.passwordValidity}
              onChange={handleChange}
            />
            <InputField
              label={"Password Reset Alert (day) "}
              type="number"
              id="notifyResetPassInDay"
              value={policyData.notifyResetPassInDay}
              onChange={handleChange}
            />
            <ToggleButton
              isOn={policyData.notification_frequency_flag === true}
              onToggle={handleFrequencyToggle}
              label={"Add Notify Frequency (optional)"}
            />
            {policyData.notification_frequency_flag === true && (
              <InputField
                label={"Notification Frequency Interval (day) "}
                type="number"
                id="notification_frequency"
                value={policyData.notification_frequency}
                onChange={handleChange}
              />
            )}
            <ToggleButton
              isOn={policyData.policyStatus === true}
              onToggle={handleToggleStatus}
              label={
                policyData.policyStatus === true
                  ? "Policy Active"
                  : "Policy In-Active"
              }
            />
          </div>
          <div className="flex flex-row mt-7 gap-3">
            <button
              type="submit"
              className={`bg-[#0f52ba] text-white py-2 px-6 rounded shadow hover:bg-blue-700 transition`}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => navigate("/body/passwordPolicy")}
              className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordPolicyForm;
