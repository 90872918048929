import React from 'react'

const DataRetention = () => {
  return (
    <div>
      <h1>Data Retention</h1>
    </div>
  )
}

export default DataRetention
