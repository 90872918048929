import React, { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material"; // MUI components

const InternetConnectionStatus = () => {
  const [showAlert, setShowAlert] = useState(!navigator.onLine); // Control visibility of the alert

  // Update connection status when the user goes online or offline
  useEffect(() => {
    const handleOnline = () => {
      setShowAlert(false); // Dismiss alert when back online
    };

    const handleOffline = () => {
      setShowAlert(true); // Show alert when offline
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners when the component is unmounted
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <Snackbar
        open={showAlert} // Show only when offline
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position of the alert
      >
        <Alert
          severity="error"
          onClose={() => setShowAlert(false)} // Allow dismissing the alert manually
          sx={{
            width: "100%",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          You are offline. Please check your internet connection.
        </Alert>
      </Snackbar>
    </>
  );
};

export default InternetConnectionStatus;
