import React, { useState, useEffect } from "react";
import {
  Clock,
  Repeat,
  Pause,
  Share2,
  CheckCircle,
  FastForward,
} from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import {
  CALL_NEXT_TOKEN,
  COMPLETE_TOKEN,
  GET_PROFILE_BYID,
  HOLD_TOKEN,
} from "../../Utils/Constant";

import { toast } from "react-toastify";
import Modal from "../CustomReusableComps/modal";
import RecallTokenList from "./RecallTokensList";
import AvialServicesToTrans from "./AvialServicesToTrans";
import { setSelectedCurrentToken } from "../../Redux/TokenManagementSlice";
const TokenManagement = () => {
  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [tokenResponse, setTokenResponse] = useState(null);
  console.log("tokenResponse", tokenResponse);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpeningTransferModal, setIsOpeningTransferModal] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [isTokenOnHold, setIsTokenOnHold] = useState(false);
  const [isTokenTransfered, setIsTokenTransfered] = useState(false);
  const [profileData, setprofileData] = useState([]);
  console.log("profileData", profileData);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector((state) => state.userManagement.items);
  console.log("userData", userData);

  const dispatch = useDispatch();
  useEffect(() => {
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Display a confirmation dialog in most browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const getProfilleById = async () => {
      if (!userData?.user_details?.user_id || !token) {
        console.warn("User ID or token is missing");
        return;
      }

      // Replace with your actual DeviceID value.

      try {
        const response = await fetch(GET_PROFILE_BYID, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: userData.user_details.user_id,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setprofileData(data); // Assuming setProfileData is a state updater for profile data
        console.log("Profile data fetched:", data);
      } catch (error) {
        console.error("Error fetching profile by ID:", error);
      }
    };

    getProfilleById();
  }, [userData?.user_details?.user_id, token]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const CallNextToken = async () => {
    const payload = {
      branch_id: userData?.user_details?.profile_details?.branch_id,
      counter_id: userData?.user_details?.profile_details?.counter_id,
    };
    try {
      const response = await fetch(CALL_NEXT_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });
      console.log("response", response);

      if (response.status === 404) {
        toast.error("No More Tokens In Queue");
        return;
      }
      if (!response.ok) throw new Error("API request failed");
      const data = await response.json();
      console.log("data", data);

      setTokenResponse(data);
      dispatch(setSelectedCurrentToken(data));
      setIsTimerActive(true);
      setTimer(0);
      setIsCompleting(false);
      setIsTokenOnHold(false);
      setIsTokenTransfered(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to process next token");
    }
  };

  const handleCompleteToken = async () => {
    // if (!tokenResponse?.data?.token_id ) {
    //   toast.error("current token is already completed");
    //   return;
    // }
    setIsCompleting(true);
    try {
      const response = await fetch(COMPLETE_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          token_id:
            tokenResponse?.data?.token_id ||
            tokenResponse?.data?.svc_layer_details?.token_id,
        }),
      });
      if (!response.ok) throw new Error("API request failed");
      const data = await response.json();
      setTokenResponse(data);
      setIsTimerActive(false);
      toast.success(data.message);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to complete token action");
      setIsCompleting(false);
    }
  };

  const handleHoldToken = async () => {
    try {
      const response = await fetch(HOLD_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          token_id:
            tokenResponse?.data?.token_id ||
            tokenResponse?.data?.svc_layer_details?.token_id,
        }),
      });
      if (!response.ok) throw new Error("API request failed");
      const data = await response.json();
      setTokenResponse(data);
      setIsTimerActive(false);
      toast.success(data.message);
      setIsTokenOnHold(true);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to complete token action");
    }
  };

  const handleServeToken = (data) => {
    console.log("handleServeToken", data);
    setTokenResponse(data);
    setIsTimerActive(true);
    setTimer(0);
    setIsCompleting(false);
    setIsTokenOnHold(false);
  };
  const handleTransferredToken = (data) => {
    setTokenResponse(data);
    setIsTimerActive(false);
    setIsCompleting(false);
    setIsTokenOnHold(false);
    setIsTokenTransfered(true);
  };

  return (
    <div className="container mx-auto px-6 py-8 ">
      <div id="modal-root"></div>
      {/* New User Info Section */}
      <div className="bg-white rounded-xl shadow-lg p-6 mb-4">
        {/* Row Layout for Username, Department, and Services */}
        <div className="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-6">
          {/* Username Section */}
          <div className="w-full md:w-1/3">
            <h3 className="text-lg font-semibold text-gray-700">Username</h3>
            <div className="mt-2">
              <p className="text-[15px] text-gray-800">
                {`${userData.user_details.firstname} ${userData.user_details.lastname}`}
              </p>
              <p className="text-xxl text-gray-500">
                Counter ID: {userData?.user_details?.profile_details?.counter_id}
              </p>
            </div>
          </div>

          {/* Department Section with Overflow for Multiple Entries */}
          <div className="w-full md:w-1/3">
            <h3 className="text-lg font-semibold text-gray-700">Department</h3>
            <div className="mt-2 h-32  overflow-y-auto">
              {profileData?.data?.department_details?.department_name}
            </div>
          </div>

          {/* Services Section with Overflow for Multiple Entries */}
          <div className="w-full md:w-1/3">
            <h3 className="text-lg font-semibold text-gray-700">Services</h3>
            <div className="mt-2 h-32 overflow-y-auto">
              {profileData?.data?.svc_group_details?.length > 0 ? (
                <ul className="space-y-1 ml-4 list-disc text-xxl text-gray-600">
                  {profileData.data.svc_group_details.map((group, index) => (
                    <li key={index}>
                      <span className="font-medium text-gray-800">
                        {group.svc_group_name}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-sm text-gray-600">No Services Available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-lg p-8">
        {/* Timer Display */}
        <div className="mb-8 bg-gray-50 rounded-xl p-6 flex items-center justify-center space-x-4">
          <Clock className="w-10 h-10 text-blue-600" />
          <span className="text-5xl font-mono text-gray-800 font-bold">
            {formatTime(timer)}
          </span>
        </div>

        {/* Token Information Display */}
        {tokenResponse && (
          <div className="mb-8 space-y-4">
            {/* Current Token Display */}
            <div className="bg-blue-50 rounded-xl p-6 text-center">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Current Token
              </h2>
              <p className="text-5xl text-blue-600 font-bold mb-2">
                {tokenResponse?.data?.token ||
                  tokenResponse?.data?.svc_group_details?.token}
              </p>
            </div>
            {/* Status Display */}
            <div className="bg-gray-50 rounded-xl p-4">
              <div className="flex justify-between items-center">
                <span className="text-gray-600 font-medium">Status:</span>
                {tokenResponse?.api === "serve token api" && (
                  <span className="text-green-600 font-semibold">
                    {tokenResponse?.data?.svc_layer_details?.status}
                  </span>
                )}
                {tokenResponse?.api === "assign token api" && (
                  <span className="text-green-600 font-semibold">
                    {tokenResponse?.data?.status}
                  </span>
                )}
                {tokenResponse?.api === "Hold token api" && (
                  <span className="text-green-600 font-semibold">
                    {tokenResponse?.data?.svc_layer_details?.status}
                  </span>
                )}
                {tokenResponse.api === "Hold token api" && (
                  <span className="text-green-600 font-semibold">
                    {tokenResponse?.data?.svc_layer_details?.status}
                  </span>
                )}
              </div>
              {tokenResponse?.message && (
                <div className="mt-2 text-gray-600">
                  {tokenResponse?.message}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Action Buttons */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
          {/* First Row: Three Buttons */}
          <button
            onClick={() => CallNextToken()}
            className="flex items-center justify-center space-x-3 p-6 bg-blue-600 text-white hover:bg-blue-700 transition-colors shadow-md hover:shadow-lg"
          >
            <FastForward className="w-6 h-6" />
            <span className="text-lg font-semibold">Call Next Token</span>
          </button>

          <button
            onClick={() => handleHoldToken()}
            disabled={isCompleting || isTokenOnHold || isTokenTransfered}
            className={`flex items-center justify-center space-x-3 p-6 ${
              isCompleting || isTokenOnHold || isTokenTransfered
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-orange-500 hover:bg-orange-600"
            } text-white transition-colors shadow-md hover:shadow-lg`}
          >
            <Pause className="w-6 h-6" />
            <span className="text-lg font-semibold">Hold Token</span>
          </button>

          <button
            onClick={() => handleCompleteToken()}
            disabled={isCompleting || isTokenOnHold || isTokenTransfered}
            className={`flex items-center justify-center space-x-3 p-6 ${
              isCompleting || isTokenOnHold || isTokenTransfered
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-green-600 hover:bg-green-700"
            }  text-white transition-colors shadow-md hover:shadow-lg`}
          >
            <CheckCircle className="w-6 h-6" />
            <span className="text-lg font-semibold">Complete Token</span>
          </button>

          {/* Second Row: Two Buttons Without Gap */}
          <div className="col-span-1 md:col-span-2 lg:col-span-3 flex justify-center mt-4">
            <div className="flex w-full">
              <button
                onClick={() => setIsOpeningTransferModal(true)}
                disabled={isCompleting || isTokenOnHold || isTokenTransfered}
                className={`flex-1 flex items-center justify-center space-x-3 p-6 ${
                  isCompleting || isTokenOnHold || isTokenTransfered
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-purple-500 hover:bg-purple-600"
                } text-white transition-colors shadow-md hover:shadow-lg`}
              >
                <Share2 className="w-6 h-6" />
                <span className="text-lg font-semibold">Transfer Token</span>
              </button>

              <button
                onClick={() => setIsOpen(true)}
                className="flex-1 flex items-center justify-center space-x-3 p-6 bg-yellow-500 text-white hover:bg-yellow-600 transition-colors shadow-md hover:shadow-lg"
              >
                <Repeat className="w-6 h-6" />
                <span className="text-lg font-semibold">
                  Recall On Held Token
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <Modal
          title="On Held Tokens List"
          onClose={() => setIsOpen(false)}
          width="800px"
        >
          <div>
            <RecallTokenList
              onClose={() => setIsOpen(false)}
              onServeToken={handleServeToken}
            />
          </div>
        </Modal>
      )}
      {isOpeningTransferModal && (
        <Modal
          title="Available service to transfer"
          onClose={() => setIsOpeningTransferModal(false)}
          width="800px"
          titleBgColor="#A855F7"
        >
          <div>
            <AvialServicesToTrans
              onClose={() => setIsOpeningTransferModal(false)}
              onTransferedToken={handleTransferredToken}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TokenManagement;
