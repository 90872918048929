import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../CustomReusableComps/InputField";
import { toast } from "react-toastify";
import { EDIT_COUNTER } from "../../../Utils/Constant";
import { setSelectedCounterView } from "../../../Redux/CounterSlice";
import ToggleButton from "../../CustomReusableComps/ToggleButton";

const CounterForm = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 const userData = useSelector((state) => state.userManagement.items.user_details);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const selectedCounter = useSelector((state) => state.counter.selectedcounterView);
  console.log('selectedCounterselectedCounter', selectedCounter);
  
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    counter_name: "",
    status: 0,
    branch_id : userData?.branch_id
  });

  // Populate form on edit
  useEffect(() => { 
    if (selectedCounter) {
      setFormData({
        counter_name: selectedCounter?.counter_name || "",
        status: selectedCounter?.status,
      });
    }
  }, [selectedCounter]);

  const handleEditCounter = async (rowData) => {
    try {
      const response = await fetch(EDIT_COUNTER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          counter_id: selectedCounter?.counter_id,
          counter_name: rowData.counter_name,
          status: rowData.status,
          branch_id : userData?.branch_id
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(t("Counter updated successfully!"));
        dispatch(setSelectedCounterView(null));
        setFormData({ counter_name: "", status: 0 });
        navigate("/body/counter");
      } else {
        toast.error(t(`Failed to update counter: ${responseData.message}`));
      }
    } catch (error) {
      console.error(t("Error updating counter:"), error);
      toast.error(t("An error occurred while updating the counter."));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    console.log("Form submission triggered");

    selectedCounter ? handleEditCounter(formData) : onSubmit?.(formData);
  };

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, status: prev.status === 0 ? 1 : 0 }));
  };

  const counterNameLabel =
  formSubmitted && !formData.counter_name ? (
    <div className="text-red-500 mt-1">
      {t("counters.countername")} <span className="text-red-500">*</span>
    </div>
  ) : (
    t("counters.countername")
  );

  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold">{t("counters.addcounter")}</h1>
      <div className="mt-5 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <InputField
              name="counter_name"
              label={counterNameLabel}
              placeholder={t("counters.place")}
              value={formData.counter_name}
              onChange={handleInputChange}
              className="w-full"
            />
            <ToggleButton
              isOn={formData.status === 1}
              onToggle={handleToggleStatus}
              label={formData.status === 1 ? t("counters.CounterActive") : t("counters.CounterInActive")}
            />
          </div>
          <div className="flex flex-row mt-7 gap-3">
            <button
              type="submit"
              className={`bg-blue-500 text-white py-2 px-6 rounded shadow hover:bg-blue-600 transition  ${
                isSubmitting && "opacity-50 cursor-not-allowed"
              }`}
              disabled={isSubmitting}
            >
              {selectedCounter ? t("counters.update") : t("Department.submit")}
            </button>
            <button
              type="button"
              onClick={() => navigate("/body/counter")}
              className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
              >
              {t("Department.back")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CounterForm;