import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CREATE_TOKEN, SERVICE_LIST } from "../Utils/Constant";
import { setCreateTokenView, setServiceView } from "../Redux/ServiceSlice";
import { IoCloseOutline } from "react-icons/io5";
import SuccessPopup from "../Components/CustomReusableComps/TokenSuccessPopUp";
import KioskErrorState from "./KioskErrorState";

const Kiosk = () => {
  const user = useSelector((store) => store.userManagement.items);
  console.log('user', user);
  
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [createdToken, setCreatedToken] = useState(null);

  const apiHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    DeviceID: deviceId,
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    let timer;
    if (isSuccessPopupVisible) {
      timer = setTimeout(() => {
        closeSuccessPopup();
      }, 10000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isSuccessPopupVisible]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(SERVICE_LIST, {
        method: "POST",
        headers: apiHeaders,
        body: JSON.stringify({
          branch_id: user?.terminal_details.branch_id,
          profile_based_list: true,
          order_by: 1,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch service list.");
      }

      const responseData = await response.json();
      setData(responseData.data);
      dispatch(setServiceView(responseData.data));
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Oops! Something went wrong. We're sorry for the inconvenience. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleYes = async () => {
    try {
      const response = await fetch(CREATE_TOKEN, {
        method: "POST",
        headers: apiHeaders,
        body: JSON.stringify({
          branch_id: user?.terminal_details.branch_id,
          svc_group_id: selectedService?.svc_group_id,
          terminal_id: user?.terminal_details?.terminal_id,
          status: "Issued",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create token.");
      }

      const responseData = await response.json();
      setCreatedToken(responseData.data);
      setIsPopupVisible(false);
      setIsSuccessPopupVisible(true);
      dispatch(setCreateTokenView(responseData.data));
    } catch (error) {
      console.error("Error while fetching token:", error);
      setError("Oops! Something went wrong. We're sorry for the inconvenience.");
    }
  };

  const handleLearnMore = (service) => {
    setSelectedService(service);
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setSelectedService(null);
  };

  const closeSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
    setCreatedToken(null);
  };

  if (!user) return null;

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-8 text-center">Kiosk Services</h1>

      {loading ? (
        <div className="flex justify-center items-center">
          <div className="loader"></div>
        </div>
      ) : error ? (
        <KioskErrorState />
      ) : (
        <div className="grid grid-cols-3 gap-6">
          {/* Left Column for Services */}
          <div className="space-y-4">
            {data
              .filter((_, index) => index % 2 === 0)
              .map((service, index) => (
                <div
                  key={index}
                  className="service-card bg-blue-500 p-5 rounded-lg shadow-lg hover:bg-blue-600 cursor-pointer"
                  onClick={() => handleLearnMore(service)}
                >
                  <h2 className="text-xl font-semibold text-white text-center">{service.svc_group_name || "Service"}</h2>
                </div>
              ))}
          </div>

          {/* Center Column for Advertisement */}
          <div className="flex items-center justify-center">
            <div className="ad-section bg-gray-100 p-5 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-center">Your Ad Here</h2>
              <p className="text-center text-gray-600">Promote your business on this kiosk!</p>
            </div>
          </div>

          {/* Right Column for Services */}
          <div className="space-y-4">
            {data
              .filter((_, index) => index % 2 !== 0)
              .map((service, index) => (
                <div
                  key={index}
                  className="service-card bg-blue-500 p-5 rounded-lg shadow-lg hover:bg-blue-600 cursor-pointer"
                  onClick={() => handleLearnMore(service)}
                >
                  <h2 className="text-xl font-semibold text-white text-center">{service.svc_group_name || "Service"}</h2>
                </div>
              ))}
          </div>
        </div>
      )}

      {isPopupVisible && (
        <div className="popup-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="popup-container bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <button className="close-btn absolute top-2 right-2 text-gray-600 hover:text-black" onClick={closePopup}>
              <IoCloseOutline className="h-7 w-7" />
            </button>
            <h2 className="text-2xl font-bold text-center mb-4">{selectedService?.svc_group_name || "Service Details"}</h2>
            <p className="text-gray-700 text-lg mb-6">{selectedService?.description || "Would you like to proceed with this service?"}</p>
            <div className="flex justify-center gap-6">
              <button className="btn-confirm bg-green-600 text-white px-8 py-2 rounded-lg hover:bg-green-500 transition" onClick={handleYes}>
                Yes, Proceed
              </button>
              <button className="btn-cancel bg-red-600 text-white px-8 py-2 rounded-lg hover:bg-red-500 transition" onClick={closePopup}>
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isSuccessPopupVisible && (
        <SuccessPopup
          isVisible={isSuccessPopupVisible}
          onClose={closeSuccessPopup}
          tokenData={createdToken}
        />
      )}
    </div>
  );
};

export default Kiosk;