import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
//import userReducer from "./SettingsSlice";
import { loadState, saveState } from "./Middleware";
import AuthToken from "./AuthToken";
import settingsReducer from "./SettingsSlice";
import userManagementReducer from "./UserManagementSlice";
import CounterReducer from './CounterSlice'
import TerminalReducer from './TerminalSlice'
import TokenConfigReducer from './TokenConfigSlice'
import ProfileReducer from './ProfileSlice'
import tokenManagementReducer from './TokenManagementSlice'
import PasswordPolicyReducer from  './PasswordPolicy'
import viewQueueTokensReducer from './ViewQueueTokensSlice'
// Load the persisted state
const preloadedState = loadState();

const Store = configureStore({
  reducer: {
    // user: userReducer,
    token: AuthToken,
    setting: settingsReducer,
    userManagement: userManagementReducer,
    counter : CounterReducer,
    terminal: TerminalReducer,
    tokenConfig : TokenConfigReducer,
    profile : ProfileReducer, 
    tokenManagement : tokenManagementReducer,
    passwordPolicy : PasswordPolicyReducer,
    viewQueueTokens : viewQueueTokensReducer  
  },
  preloadedState, // Set the preloaded state
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Use the named import
});

//Save the state to localStorage whenever it changes
Store.subscribe(() => {
  saveState(Store.getState());
});

export default Store;
