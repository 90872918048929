import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyValueDisplay from "../../KeyValue";

const ViewBranch = () => {
  const selectedBranch = useSelector(
    (store) => store.setting.selectedbranchView
  );
  console.log("selected",selectedBranch);
  
  const navigate = useNavigate();

  if (!selectedBranch) {
    return (
      <div className="font-roboto">No account sub type data available.</div>
    );
  }

  return (
    <div> 
      <div className="flex justify-between items-center font-roboto">
        <h2 className="flex p-5 font-bold">View Branch</h2>
        <div className="flex justify-end p-5">
          <button
            onClick={() => navigate("/body/branch")}
            className="bg-color-light-blue text-color-white px-4 py-2 rounded"
          >
            Back
          </button>
        </div>
      </div>

      <div className="border w-[78] h-auto mx-5 p-4 rounded shadow-md font-roboto bg-color-white">
        <KeyValueDisplay data={selectedBranch} />
      </div>
    </div>
  );
};

export default ViewBranch;
