import React, { useState, useEffect } from "react";
import Table from "../../CustomReusableComps/Table";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import { useTranslation } from "react-i18next";
import { TOKENS_LIST } from "../../../Utils/Constant";
import { useSelector } from "react-redux";

import ShimmerTable from "../../useShimmerTable";

const TokenList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector((state) => state.userManagement.items);
  console.log(userData);
  const userType = useSelector((store) => store?.userManagement?.items?.user_details?.user_type);
  console.log('userType', userType);
  
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const payload = {
        page: page,
        no_of_data: pageSize,
        order_by: 1,
        branch_id: userData?.user_details?.profile_details?.branch_id,
        get_today_data: true,
      };
  
      if (userType !== 3) {
        payload.profile_based_list = true;
      }
  
      const response = await fetch(TOKENS_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    { key: "index", label: "#" },
    { key: "token_id", label: "Token Id" },
    { key: "token", label: t("token") },
    {
      key: "status",
      label: t("Status"),
    },
  ];
  
  const filterOptions = [
    {
      key: 'status',
      label: 'Status',
      options: [
        { value: 'Completed', label: 'Completed' },
        { value: 'Transfered', label: 'Transfered' },
        { value: 'Issued', label: 'Issued' },
        {value:'On Hold', label: 'On Hold'}
      ]
    }
  ];
  const indexedData = data?.map((item, index) => ({
    ...item,
    index: totalCount - ((currentPage - 1) * pageSize + index), // Calculate descending index
  }));
  
  

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" mb-4 flex justify-end items-center">
        <Breadcrumbs
          options={[{ name: "Token List", pathName: "/body/tokenList" }]}
        />
      </div>

      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
          data={indexedData || []} // Use indexed data
          headers={headers}
            loading={loading}
            searchPlaceholder="Search by token id"
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
            filterOptions={filterOptions}

          />
        </div>
      )}
    </div>
  );
};

export default TokenList;
