import React from "react";
import { useTranslation } from "react-i18next";

const KeyValueDisplay = ({ data, level = 0 }) => {
  const { t } = useTranslation();

  const capitalizeWords = (string) => {
    return string
      ?.split(/[\s_]/)
      ?.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  };

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <div className="ml-4 border-l-2 pl-2">
          {value.map((item, index) => (
            <div key={index} className="my-2">
              {typeof item === "object" ? (
                <KeyValueDisplay data={item} level={level + 1} />
              ) : (
                <span>{item ?? "N/A"}</span>
              )}
            </div>
          ))}
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      return <KeyValueDisplay data={value} level={level + 1} />;
    } else {
      return <span>{value ?? "N/A"}</span>;
    }
  };

  return (
    <div className={`p-${level * 2} border-b border-gray-300`}>
      {Object.entries(data || {}).map(([key, value], index) => (
        <div
          key={key}
          className={`py-2 px-2 flex justify-between items-start ${
            index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
          }`}
        >
          <strong className="text-gray-700">
            {t(key.replace(/_/g, " ")) || capitalizeWords(key)}
          </strong>
          <div className="ml-2 text-gray-800">
            {typeof value === "object" && value !== null ? (
              renderValue(value)
            ) : (
              <span>{value ?? "N/A"}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KeyValueDisplay;
