import React, { useState, useRef, useEffect } from "react";
import { RiMenuFold3Line2, RiSearchLine, RiCloseLine } from "react-icons/ri";
import { PiDotsThreeOutlineBold } from "react-icons/pi";

import { FaRegBell } from "react-icons/fa";
import { setIsCollapse, setIsMobile } from "../../Redux/SettingsSlice";
import { useDispatch } from "react-redux";
import User from "../../Assets/user.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchBarComp from "../../Components/MUI_Reusable_Comps/SearchBar";
import LanguageDropdown from "../../Components/LanguageDropdown ";
import { useSelector } from "react-redux";

const Header = ({ loginData }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const userData = useSelector((state) => state.userManagement.items);
  const [avatar, setAvatar] = useState(User);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    dispatch(setIsCollapse());
  };

  const toggleSearchBar = () => {
    setActiveDropdown((prev) => (prev === "search" ? null : "search"));
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const toggleLanguageDropdown = () => {
    setActiveDropdown((prev) => (prev === "language" ? null : "language"));
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("refreshToken");
    navigate("/");
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setAvatar(reader.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    userData?.user_details && (
      <div className="w-full relative">
        {/* Desktop & Tablet Header */}
        <div className="w-full h-20 flex justify-between items-center p-5 bg-gray-100 shadow-md">
          <div className="flex items-center justify-center space-x-6 mb-4">
            {/* Menu Button - Visible only on md screens and above */}
            <button
              className="p-2 outline-none hidden md:flex items-center justify-center"
              onClick={toggleSidebar}
            >
              <RiMenuFold3Line2 className="w-6 h-6" />
            </button>
          
              <span className="text-xl font-semibold">{t("header_title")}</span>
          </div>

          {/* Right Side - Icons & User Profile for Desktop */}
          <div className="hidden md:flex items-center space-x-4">
            <div className="w-10 h-10 flex justify-center items-center">
              <button className="z-50" onClick={toggleLanguageDropdown}>
                <LanguageDropdown />
              </button>
            </div>

            {/* Search Bar */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleSearchBar}
                className="flex items-center justify-center w-10 h-10"
              >
                <RiSearchLine className="w-5 h-5 text-gray-600" />
              </button>
              {activeDropdown === "search" && (
                <div className="absolute top-16 right-0 bg-white shadow-md rounded-lg w-80 p-2 z-10">
                  <SearchBarComp />
                </div>
              )}
            </div>

            {/* Notifications Icon */}
            <div className="relative w-10 h-10 flex justify-center items-center">
              <FaRegBell className="w-5 h-5 text-gray-500" />
              <span className="absolute top-0 right-0 text-xs text-white bg-red-600 rounded-full w-4 h-4 flex items-center justify-center">
                4
              </span>
            </div>

            {/* User Profile */}
            <div className="relative" ref={dropdownRef}>
              <img
                src={avatar}
                alt="User Avatar"
                className="rounded-full w-10 h-10 cursor-pointer"
                onClick={() => toggleDropdown("user")}
              />
              {activeDropdown === "user" && (
                <div className="absolute right-0 top-12 bg-white border rounded shadow-lg z-10 w-48 p-4">
                  <div className="flex items-center space-x-4">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="rounded-full w-12 h-12"
                    />
                    <div>
                      <h2 className="font-semibold">
                        {userData?.user_details?.firstname || "User"}
                      </h2>
                      <label className="text-sm text-blue-500 cursor-pointer">
                        Change Avatar
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={handleAvatarChange}
                        />
                      </label>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <ul>
                    <li
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={handleLogout}
                    >
                      Logout
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <span className="text-color-black font-semibold">
              {loginData.user_type_name}
            </span>
          </div>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMobileMenu} className="p-2 outline-none">
              {isMobileMenuOpen ? (
                <RiCloseLine className="w-6 h-6" />
              ) : (
                <PiDotsThreeOutlineBold className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu Overlay */}
        {isMobileMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
            onClick={toggleMobileMenu}
          >
            <div
              className="absolute top-20 right-1 w-64 bg-white shadow-lg p-4 space-y-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center space-x-4 mb-4">
                <img
                  src={avatar}
                  alt="User Avatar"
                  className="rounded-full w-12 h-12"
                />
                <div>
                  <h2 className="font-semibold">
                    {userData?.user_details?.firstname || "User"}
                  </h2>
                  <p className="text-sm text-gray-500">
                    {loginData.user_type_name}
                  </p>
                </div>
              </div>

              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <span>Language</span>
                  <LanguageDropdown />
                </div>

                {/* <div className="flex items-center justify-between">
                  <span>Search</span>
                  <button onClick={toggleSearchBar}>
                    <RiSearchLine className="w-5 h-5 text-gray-600" />
                  </button>
                </div> */}

                <div className="flex items-center justify-between">
                  <span>Notifications</span>
                  <div className="relative">
                    <FaRegBell className="w-5 h-5 text-gray-500" />
                    <span className="absolute -top-2 -right-2 text-xs text-white bg-red-600 rounded-full w-4 h-4 flex items-center justify-center">
                      4
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <label className="text-sm text-blue-500 cursor-pointer">
                    Change Avatar
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleAvatarChange}
                    />
                  </label>
                </div>

                <hr />

                <div
                  className="text-red-500 cursor-pointer"
                  onClick={handleLogout}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Search Bar
        {activeDropdown === "search" && (
          <div className="fixed top-20 inset-x-0 bg-white shadow-md p-4 z-50 md:hidden">
            <SearchBarComp />
          </div>
        )} */}
      </div>
    )
  );
};

export default Header;
