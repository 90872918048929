import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

function ConfirmBox({
  open,
  onCancel,
  onAgree,
  title,
  content,
  remove,
  cancel,
  disabled,
  style,
  focusOnCancel = false,
}) {
  const removeButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open) {
      if (focusOnCancel && cancelButtonRef.current) {
        cancelButtonRef.current.focus();
      } else if (!focusOnCancel && removeButtonRef.current) {
        removeButtonRef.current.focus();
      }
    }
  }, [open, focusOnCancel]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          ...style,
        },
      }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={onCancel}
        aria-label="close"
        style={{ position: "absolute", right: 12, top: 5 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div>{content}</div>
      </DialogContent>
      <DialogActions>
        <Button
          ref={cancelButtonRef}
          onClick={onCancel}
          tabIndex={focusOnCancel ? 0 : -1}
          autoFocus={focusOnCancel}
        >
          {cancel}
        </Button>
        <Button
          ref={removeButtonRef}
          color="error"
          onClick={onAgree}
          disabled={disabled}
          tabIndex={focusOnCancel ? -1 : 0}
        >
          {remove}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmBox;