import React, { useState, useEffect, useRef } from "react";
import { FaRegUser, FaCog } from "react-icons/fa";
import { RiDashboardLine } from "react-icons/ri";
import { MdToken } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import { RiFileList3Fill } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";

//import { setIsMobile } from "../../Redux/SettingsSlice";

const Sidebar = () => {
  const isSidebarCollapsed = useSelector((store) => store.setting.isCollapse);
  //const isSidebarMobile = useSelector((store) => store.setting.isMobile);

  const userType = useSelector(
    (store) => store?.userManagement?.items?.user_details?.user_type
  );
  console.log("userType", userType);
  const userData = useSelector((state) => state.userManagement.items);
     console.log('userData', userData)
  const [activeLink, setActiveLink] = useState("");
  const [activeSection, setActiveSection] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();
  const sidebarRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  // Update active link based on current route
  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
    // Set active section based on path
    if (path === "body") setActiveSection("dashboard");
    else if (["user", "profile", "institution", "branchlist"].includes(path))
      setActiveSection("user");
    else if (
      [
        "passwordPolicy",
        "data_retention",
        "language",
        "departmentList",
        "service",
        "counter",
        "terminals",
        "tokenConfig",
      ].includes(path)
    )
      setActiveSection("settings");
    else if (path === "tokenmanagement") setActiveSection("token_management");
  }, [location]);
  const toggleSection = (section) => {
    setActiveSection((prev) => (prev === section ? null : section));
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setActiveSection(null); // Close the pop-out menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getLinkClassName = (path) => {
    const baseLinkClass =
      "p-2 block transition-transform duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-color-dim-blue rounded-lg";
    return `${baseLinkClass} ${
      activeLink === path ? "bg-color-dim-blue text-blue-600 font-medium" : ""
    }`;
  };

  //sidebar menus:

  const SidebarContent1 = () => (
    <>
      <div
        className={` px-3 mb-4 ${
          isSidebarCollapsed ? "justify-center pt-4" : ""
        }`}
      >
        <Link to="/body" className="flex items-center mt-3 cursor-pointer">
          <img
            src={`${process.env.PUBLIC_URL}/InnoQueue.svg`}
            alt="Logo"
            // className="w-[80%] h-[100px] p-1 mx-auto"
          />
        </Link>
      </div>
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link
            to="/body"
            className={`flex items-center cursor-pointer ${
              activeLink === "body" ? "text-blue-600" : ""
            }`}
          >
            <RiDashboardLine
              className={`w-5 h-5 mr-3 ${
                activeLink === "body" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>
      {/* User Section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "user" ? "bg-color-dim-blue p-2 rounded-lg" : ""
          }`}
          onClick={() => toggleSection("user")}
        >
          <div className="flex items-center cursor-pointer text-nowrap">
            <FaRegUser
              className={`w-5 h-5 mr-3 ${
                activeSection === "user" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("user_management")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "user" ? (
          <div className="absolute left-20 top-32 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="user"
              className={getLinkClassName("user")}
              onClick={() => setActiveSection(null)}
            >
              {t("user")}
            </Link>
            <Link
              to="institution"
              className={getLinkClassName("institution")}
              onClick={() => setActiveSection(null)}
            >
              {t("institution")}
            </Link>
          </div>
        ) : (
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "user" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link to="user" className={getLinkClassName("user")}>
              {t("user")}
            </Link>
            <Link to="institution" className={getLinkClassName("institution")}>
              {t("institution")}
            </Link>
          </div>
        )}
      </div>

      {/* Settings Section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "settings"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => toggleSection("settings")}
        >
          <div className="flex items-center cursor-pointer">
            <FaCog
              className={`w-5 h-5 mr-3 ${
                activeSection === "settings" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("settings")}
            </span>
          </div>
        </div>

        {/* Settings submenu */}
        {isSidebarCollapsed && activeSection === "settings" ? (
          <div className="absolute left-20 top-44 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="data_retention"
              className={getLinkClassName("data_retention")}
              onClick={() => setActiveSection(null)}
            >
              {t("data_retention")}
            </Link>
            <Link
              to="language"
              className={getLinkClassName("language")}
              onClick={() => setActiveSection(null)}
            >
              {t("language")}
            </Link>
          </div>
        ) : (
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "settings" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="data_retention"
              className={getLinkClassName("data_retention")}
            >
              {t("data_retention")}
            </Link>
            <Link to="language" className={getLinkClassName("language")}>
              {t("language")}
            </Link>
          </div>
        )}
      </div>
    </>
  );

  const SidebarContent2 = () => (
    <>
      <div
        className={`px-3 mb-4 ${
          isSidebarCollapsed ? "justify-center pt-4" : ""
        }`}
      >
             <Link to="/body" className="flex items-center mt-3 cursor-pointer">
          <img
            src={`${process.env.PUBLIC_URL}/InnoQueue.svg`}
            alt="Logo"
            // className="w-[80%] h-[100px] p-1 mx-auto"
          />
        </Link>
      </div>

      {/* Dashboard section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link
            to="/body"
            className={`flex items-center cursor-pointer ${
              activeLink === "body" ? "text-blue-600" : ""
            }`}
          >
            <RiDashboardLine
              className={`w-5 h-5 mr-3 ${
                activeLink === "body" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>

      {/* User Management section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "user" ? "bg-color-dim-blue p-2 rounded-lg" : ""
          }`}
          onClick={() => toggleSection("user")}
        >
          <div className="flex items-center cursor-pointer text-nowrap">
            <FaRegUser
              className={`w-5 h-5 mr-3 ${
                activeSection === "user" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("user_management")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "user" ? (
          <div className="absolute left-20 top-32 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="user"
              className={getLinkClassName("user")}
              onClick={() => setActiveSection(null)}
            >
              {t("user")}
            </Link>
            <Link
              to="branchlist"
              className={getLinkClassName("branchlist")}
              onClick={() => setActiveSection(null)}
            >
              {t("branch")}
            </Link>
          </div>
        ) : (
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "user" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link to="user" className={getLinkClassName("user")}>
              {t("user")}
            </Link>
            <Link to="branchlist" className={getLinkClassName("branchlist")}>
              {t("branch")}
            </Link>
          </div>
        )}
      </div>

      {/* Settings section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "settings"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => toggleSection("settings")}
        >
          <div className="flex items-center cursor-pointer">
            <FaCog
              className={`w-5 h-5 mr-3 ${
                activeSection === "settings" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("settings")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "settings" ? (
          <div className="absolute left-20 top-44 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="passwordPolicy"
              className={getLinkClassName("passwordPolicy")}
              onClick={() => setActiveSection(null)}
            >
              {t("password_policy")}
            </Link>
            <Link
              to="language"
              className={getLinkClassName("language")}
              onClick={() => setActiveSection(null)}
            >
              {t("language")}
            </Link>
          </div>
        ) : (
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "settings" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="passwordPolicy"
              className={getLinkClassName("passwordPolicy")}
            >
              {t("password_policy")}
            </Link>
            <Link to="language" className={getLinkClassName("language")}>
              {t("language")}
            </Link>
          </div>
        )}
      </div>
    </>
  );

  const SidebarContent3 = () => (
    <>
      <div
        className={`px-3 mb-4 ${
          isSidebarCollapsed ? "justify-center pt-4" : ""
        }`}
      >
        <Link to="/body" className="flex items-center mt-3 cursor-pointer">
          <img src={`${process.env.PUBLIC_URL}/InnoQueue.svg`} alt="Logo" />
        </Link>
      </div>

      {/* Dashboard section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link
            to="/body"
            className={`flex items-center cursor-pointer ${
              activeLink === "body" ? "text-blue-600" : ""
            }`}
          >
            <RiDashboardLine
              className={`w-5 h-5 mr-3 ${
                activeLink === "body" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>

      {/* User Management section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "user" ? "bg-color-dim-blue p-2 rounded-lg" : ""
          }`}
          onClick={() => toggleSection("user")}
        >
          <div className="flex items-center cursor-pointer text-nowrap">
            <FaRegUser
              className={`w-5 h-5 mr-3 ${
                activeSection === "user" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("user_management")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "user" ? (
          <div className="absolute left-20 top-32 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="user"
              className={getLinkClassName("user")}
              onClick={() => setActiveSection(null)}
            >
              {t("user")}
            </Link>
            <Link
              to="viewQueueTokens"
              className={getLinkClassName("viewQueueTokens")}
              onClick={() => setActiveSection(null)}
            >
              {t("Panel User")}
            </Link>
            <Link
              to="terminals"
              className={getLinkClassName("terminals")}
              onClick={() => setActiveSection(null)}
            >
              {t("Terminals")}
            </Link>
            <Link
              to="profile"
              className={getLinkClassName("profile")}
              onClick={() => setActiveSection(null)}
            >
              {t("profile")}
            </Link>
          </div>
        ) : (
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "user" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link to="user" className={getLinkClassName("user")}>
              {t("user")}
            </Link>
            <Link to="viewQueueTokens" className={getLinkClassName("viewQueueTokens")}>
              {t("Panel User")}
            </Link>
            <Link to="terminals" className={getLinkClassName("terminals")}>
              {t("terminals")}
            </Link>
            <Link to="profile" className={getLinkClassName("profile")}>
              {t("profile")}
            </Link>
          </div>
        )}
      </div>

      {/* Settings section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "settings"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => toggleSection("settings")}
        >
          <div className="flex items-center cursor-pointer">
            <FaCog
              className={`w-5 h-5 mr-3 ${
                activeSection === "settings" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("settings")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "settings" ? (
          <div className="absolute left-20 top-44 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="departmentList"
              className={getLinkClassName("departmentList")}
              onClick={() => setActiveSection(null)}
            >
              {t("department")}
            </Link>
            <Link
              to="service"
              className={getLinkClassName("service")}
              onClick={() => setActiveSection(null)}
            >
              {t("Service")}
            </Link>
            <Link
              to="counter"
              className={getLinkClassName("counter")}
              onClick={() => setActiveSection(null)}
            >
              {t("Counter")}
            </Link>
            <Link
              to="tokenConfig"
              className={getLinkClassName("tokenConfig")}
              onClick={() => setActiveSection(null)}
            >
              {t("token_Configuration")}
            </Link>
            <Link
              to="language"
              className={getLinkClassName("language")}
              onClick={() => setActiveSection(null)}
            >
              {t("language")}
            </Link>
          </div>
        ) : (
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "settings" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link
              to="departmentList"
              className={getLinkClassName("departmentList")}
            >
              {t("department")}
            </Link>
            <Link to="service" className={getLinkClassName("service")}>
              {t("Service")}
            </Link>
            <Link to="counter" className={getLinkClassName("counter")}>
              {t("counter")}
            </Link>
            <Link to="tokenConfig" className={getLinkClassName("tokenConfig")}>
              {t("token_Configuration")}
            </Link>
            <Link to="language" className={getLinkClassName("language")}>
              {t("language")}
            </Link>
          </div>
        )}
      </div>
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out hover:-translate-x-0.5 ${
            activeSection === "Reports"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => toggleSection("Reports")}
        >
          <div className="flex items-center cursor-pointer">
            <TbReportAnalytics
              className={`w-5 h-5 mr-3 ${
                activeSection === "Reports" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("Reports")}
            </span>
          </div>
        </div>

        {isSidebarCollapsed && activeSection === "Reports" ? (
          <div className="absolute left-20 top-32 bg-white shadow-lg rounded-lg p-3 z-50">
            <Link
              to="tokenList"
              className={getLinkClassName("tokenList")}
              onClick={() => setActiveSection(null)}
            >
              {t("Token List")}
            </Link>
          </div>
        ) : (
          <div
            className={`ml-5 mt-3 overflow-hidden transition-[max-height] duration-[600ms] ease-in-out ${
              activeSection === "Reports" && !isSidebarCollapsed
                ? "max-h-[300px]"
                : "max-h-0"
            }`}
          >
            <Link to="tokenList" className={getLinkClassName("tokenList")}>
              {t("Token List")}
            </Link>
          </div>
        )}
      </div>
    </>
  );

  const SidebarContent4 = () => (
    <>
      <div
        className={`px-3 mb-4 ${
          isSidebarCollapsed ? "justify-center pt-4" : ""
        }`}
      >
        <Link to="/body" className="flex items-center mt-3 cursor-pointer">
          <img src={`${process.env.PUBLIC_URL}/InnoQueue.svg`} alt="Logo" />
        </Link>
      </div>

      {/* Dashboard section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "dashboard"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          <Link
            to="/body"
            className={`flex items-center cursor-pointer ${
              activeLink === "body" ? "text-blue-600" : ""
            }`}
          >
            <RiDashboardLine
              className={`w-5 h-5 mr-3 ${
                activeLink === "body" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("dashboard")}
            </span>
          </Link>
        </div>
      </div>

      {/* Token Management section */}
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "token_management"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("token_management")}
        >
          <Link
            to="/body/tokenmanagement"
            className={`flex items-center cursor-pointer ${
              activeLink === "tokenmanagement" ? "text-blue-600" : ""
            }`}
          >
            <MdToken
              className={`w-5 h-5 mr-3 ${
                activeLink === "tokenmanagement"
                  ? "text-blue-600"
                  : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("token_management")}
            </span>
          </Link>
        </div>
      </div>
      <div className="mb-5 group px-8">
        <div
          className={`flex items-center cursor-pointer transition-transform duration-300 ease-in-out group-hover:-translate-x-0.5 ${
            activeSection === "token_management"
              ? "bg-color-dim-blue p-2 rounded-lg"
              : ""
          }`}
          onClick={() => setActiveSection("tokenList")}
        >
          <Link
            to="/body/tokenList"
            className={`flex items-center cursor-pointer ${
              activeLink === "tokenList" ? "text-blue-600" : ""
            }`}
          >
            <RiFileList3Fill
              className={`w-5 h-5 mr-3 ${
                activeLink === "tokenList" ? "text-blue-600" : "text-gray-600"
              }`}
            />
            <span className={`${isSidebarCollapsed ? "hidden" : "block"}`}>
              {t("Token List")}
            </span>
          </Link>
        </div>
      </div>
    </>
  );

  return (
    userData?.user_details && Object.keys(userData.user_details).length > 0 && (
      <div className="flex-shrink-0">
        {isMobile ? (
          <div>
            <button
              className="p-3 fixed top-2 left-2 rounded-md z-50"
              onClick={toggleDrawer}
            >
              <AiOutlineMenu size={24} />
            </button>
            {isDrawerOpen && (
              <div
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
                onClick={toggleDrawer}
              ></div>
            )}
            <div
              className={`fixed top-0 left-0 h-full bg-white shadow-lg z-50 transition-transform transform ${
                isDrawerOpen ? "translate-x-0" : "-translate-x-full"
              }`}
            >
              {userType === 1 && <SidebarContent1 />}
              {userType === 2 && <SidebarContent2 />}
              {userType === 3 && <SidebarContent3 />}
              {userType === 4 && <SidebarContent4 />}
            </div>
          </div>
        ) : (
          <div
            ref={sidebarRef}
            className={`transition-all duration-300 flex flex-col ${
              isSidebarCollapsed ? "w-20" : "w-[17rem]"
            } h-full bg-white shadow-lg overflow-y-auto`}
          >
            {userType === 1 && <SidebarContent1 />}
            {userType === 2 && <SidebarContent2 />}
            {userType === 3 && <SidebarContent3 />}
            {userType === 4 && <SidebarContent4 />}
          </div>
        )}
      </div>
    )
  );
  
  
};

export default Sidebar;
