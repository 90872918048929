import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputField } from "../../CustomReusableComps/InputField";
import ToggleButton from "../../CustomReusableComps/ToggleButton";

const InsititutionForm = ({ selectedInstitution, onSubmit, isSubmitting }) => {
  console.log(selectedInstitution);
  const [instName, setInstName] = useState("");
  const [hoAddress, setHoAddress] = useState("");
  const [hoEmail, setHoEmail] = useState("");
  const [hoAltEmail, setHoAltEmail] = useState("");
  const [hoPhone, setHoPhone] = useState("");
  const [hoAltPhone, setHoAltPhone] = useState("");
  const [instituteStatus, setinstituteStatus ] = useState(0)
  const navigate = useNavigate();
  const { t } = useTranslation();
    const [formSubmitted, setFormSubmitted] = useState(false);
  

  useEffect(() => {
    if (selectedInstitution) {
      setInstName(selectedInstitution.inst_name || "");
      setHoAddress(selectedInstitution.ho_address || "");
      setHoEmail(selectedInstitution.ho_mailid || "");
      setHoAltEmail(selectedInstitution.ho_alternate_email || "");
      setHoPhone(selectedInstitution.ho_mobile || "");
      setHoAltPhone(selectedInstitution.ho_alternate_mobile || "");
      setinstituteStatus(selectedInstitution.status)
    } else {
      setInstName("");
      setHoAddress("");
      setHoEmail("");
      setHoAltEmail("");
      setHoPhone("");
      setHoAltPhone("");
    }
  }, [selectedInstitution]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    const payload = {
      inst_name: instName,
      ho_address: hoAddress,
      ho_email: hoEmail,
      ho_alt_email: hoAltEmail,
      ho_phone: hoPhone,
      ho_alt_phone: hoAltPhone,
      status: instituteStatus,
      inst_id: selectedInstitution?.inst_id,
    };
    console.log("Submitting payload:", payload);
    onSubmit(payload);
  };
  const instituteNameLabel =
  formSubmitted && !instName ? (
    <div className="text-red-500 mt-1">{t("Institution Name *")}</div>
  ) : (
    t("Institution Name *")
  );
  const hoAddressLabel = formSubmitted && !hoAddress ? (
    <div className="text-red-500 mt-1">{t("Head Office Address *")}</div>
  ): (
    t("Head Office Address *")
  );
  const phoneLabel = formSubmitted && !hoPhone ? (
    <div className="text-red-500 mt-1">{t("Phone *")}</div>
  ): (
    t("Phone *")
  );
  const emailLabel = formSubmitted && !hoEmail ? (
    <div className="text-red-500 mt-1">{t("Email *")}</div>
  ): (
    t("Email *")
  );

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setinstituteStatus(prev => prev === 0 ? 1 : 0); 
  };
  

  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold font-sans">
        Add Institution
      </h1>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <InputField
              type="text"
              label={instituteNameLabel}
              name="instName"
              value={instName}
              onChange={(e) => setInstName(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.name_placeholder")}
              required
            />

            <InputField
              type="text"
              label={hoAddressLabel}
              id="hoAddress"
              value={hoAddress}
              onChange={(e) => setHoAddress(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.headOffice_name")}
              required
            />

            <InputField
              type="email"
              id="hoEmail"
              value={hoEmail}
              label={emailLabel}
              onChange={(e) => setHoEmail(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.email_placeholder")}
              required
            />
            <InputField
              type="email"
              id="hoAltEmail"
              value={hoAltEmail}
              label={t("Institution.alternate_email")}
              onChange={(e) => setHoAltEmail(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.alt_email_placeholder")}
            />

            <InputField
              type="tel"
              label={phoneLabel}
              id="hoPhone"
              value={hoPhone}
              onChange={(e) => setHoPhone(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.phone_placeholder")}
              required
            />

            <InputField
              type="tel"
              label={t("Institution.alternate_phone")}
              id="hoAltPhone"
              value={hoAltPhone}
              onChange={(e) => setHoAltPhone(e.target.value)}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Institution.alt_phone_placeholder")}
            />
                <ToggleButton
              isOn={instituteStatus === 1}
              onToggle={handleToggleStatus}
              label={instituteStatus === 1 ? "Institute Active" : "Institute In-Active"}
            />
          </div>

          <div className="flex flex-row mt-7 gap-3">
            <button
              type="submit"
              className="bg-color-light-blue text-color-white py-2 px-4 rounded hover:bg-color-blue"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? t("Institution.submitting")
                : t("Institution.submit")}
            </button>
            <button
              type="button"
              onClick={() => navigate("/body/institution")}
              className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
            >
              {t("Institution.back")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InsititutionForm;
