import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SERVICE_LIST } from "../../../Utils/Constant";
import { useNavigate } from "react-router-dom";

const ServiceGroupsSelection = ({
  selectedServices,
  onServiceChange,
  initialExpandedGroups = {},
  disabled = false,
}) => {
  const [expandedGroups, setExpandedGroups] = useState(initialExpandedGroups);
  const [servicelist, setServicelist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const navigate = useNavigate();

  useEffect(() => {
    setExpandedGroups(initialExpandedGroups);
  }, [initialExpandedGroups]);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await fetch(SERVICE_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          active_only: true,
          user_based_list: true,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch services");
      }
      const result = await response.json();
      const validServicesList = Array.isArray(result.data) ? result.data : [];
      setServicelist(validServicesList);

      if (selectedServices.length > 0) {
        const groupsToExpand = {};
        validServicesList.forEach((group) => {
          if (group.multi_layer_flag) {
            const hasSelectedService = group.svc_layers?.some((service) =>
              selectedServices.some(
                (selected) => selected.service_id === service.service_id
              )
            );
            if (hasSelectedService) {
              groupsToExpand[group.svc_group_id] = true;
            }
          }
        });
        setExpandedGroups((prev) => ({ ...prev, ...groupsToExpand }));
      }
    } catch (err) {
      setError(err.message);
      toast.error("Failed to load services");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleGroup = (groupId, multiLayerFlag) => {
    if (!multiLayerFlag) return; // Don't toggle if not multi-layer
    setExpandedGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }));
  };

  const handleServiceSelection = (serviceGroup) => {
    if (!serviceGroup.multi_layer_flag) {
      // For non-multi-layer groups, select the first service from svc_layers
      const serviceToSelect = serviceGroup.svc_layers[0];
      const isSelected = selectedServices.some(
        (selected) => selected.service_id === serviceToSelect.service_id
      );
  
      let newSelection;
      if (isSelected) {
        newSelection = selectedServices.filter(
          (selected) => selected.service_id !== serviceToSelect.service_id
        );
      } else {
        newSelection = [
          ...selectedServices,
          {
            service_id: serviceToSelect.service_id,
            service_name: serviceToSelect.service_name,
            svc_group_id: serviceGroup.svc_group_id,
            svc_group_name: serviceGroup.svc_group_name,
            status: 1,
          },
        ];
      }
      onServiceChange(newSelection);
    } else {
      // For multi-layer groups, select/deselect all services
      const allServicesSelected = serviceGroup.svc_layers?.every((service) =>
        selectedServices.some((selected) => selected.service_id === service.service_id)
      );
  
      let newSelection;
      if (allServicesSelected) {
        // If all services are selected, remove them all
        newSelection = selectedServices.filter(
          (selected) => !serviceGroup.svc_layers.some((service) => service.service_id === selected.service_id)
        );
      } else {
        // If not all services are selected, add all services
        const servicesToAdd = serviceGroup.svc_layers.map(service => ({
          service_id: service.service_id,
          service_name: service.service_name,
          svc_group_id: serviceGroup.svc_group_id,
          svc_group_name: serviceGroup.svc_group_name,
          status: 1,
        }));
  
        // Filter out any existing services from this group
        const otherServices = selectedServices.filter(
          (selected) => !serviceGroup.svc_layers.some((service) => service.service_id === selected.service_id)
        );
  
        newSelection = [...otherServices, ...servicesToAdd];
      }
      onServiceChange(newSelection);
    }
  };

  const handleChildChange = (service, parentGroup) => {
    if (!parentGroup.multi_layer_flag) return;

    const isSelected = selectedServices.some(
      (selected) => selected.service_id === service.service_id
    );

    let newSelection;
    if (isSelected) {
      newSelection = selectedServices.filter(
        (selected) => selected.service_id !== service.service_id
      );
    } else {
      newSelection = [
        ...selectedServices,
        {
          service_id: service.service_id,
          service_name: service.service_name,
          svc_group_id: parentGroup.svc_group_id,
          svc_group_name: parentGroup.svc_group_name,
          status: 1,
        },
      ];
    }
    onServiceChange(newSelection);
  };

  // Loading State
  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[200px] bg-gray-50 rounded-lg">
        <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600 font-medium">Loading services...</p>
      </div>
    );
  }

  // Error State
  if (error) {
    return (
      <div className="min-h-[200px] flex flex-col items-center justify-center p-8 bg-red-50 rounded-lg border border-red-100">
        <div className="mb-4 text-red-500">
          <svg className="w-12 h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">Failed to load services</h3>
        <p className="text-gray-600 mb-4 text-center">We couldn't load the services list. Please try again.</p>
        <button
          onClick={fetchServices}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center space-x-2"
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
          <span>Try again</span>
        </button>
      </div>
    );
  }

  // No Services Available State
  if (!servicelist.length) {
    return (
      <div className="min-h-[200px] flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border border-gray-200">
        <div className="mb-4 text-gray-400">
          <svg className="w-16 h-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
        </div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">No Services Available Or Active</h3>
        <p className="text-gray-600 text-center">Please add or active services in the Service Section to get started.</p>
        <button
          onClick={() => navigate("/body/service")}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center space-x-2"
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <span>Add Services</span>
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold mb-4">Select Services</h3>
      <div className="grid gap-4 h-52 overflow-y-auto overflow-x-auto relative scrollbar scrollbar-thumb-[#a9a9a9] scrollbar-thumb-rounded-full scrollbar-w-2">
        {servicelist?.map((serviceGroup) => (
          <div
            key={serviceGroup.svc_group_id}
            className="border border-gray-200 rounded-lg bg-white shadow-sm mr-3"
          >
            <div
              className={`flex items-center p-4 ${
                serviceGroup.multi_layer_flag ? "cursor-pointer hover:bg-gray-50" : ""
              }`}
              onClick={() => toggleGroup(serviceGroup.svc_group_id, serviceGroup.multi_layer_flag)}
            >
              {serviceGroup.multi_layer_flag && (
                <span className="text-gray-500 mr-2">
                  {expandedGroups[serviceGroup.svc_group_id] ? "▼" : "▶"}
                </span>
              )}
              <div className="flex items-center flex-1">
                <input
                  type="checkbox"
                  checked={
                    serviceGroup.multi_layer_flag
                      ? serviceGroup.svc_layers?.some((service) =>
                          selectedServices.some(
                            (selected) => selected.service_id === service.service_id
                          )
                        )
                      : selectedServices.some(
                          (selected) =>
                            selected.service_id === serviceGroup.svc_layers[0]?.service_id
                        )
                  }
                  onChange={() => handleServiceSelection(serviceGroup)}
                  className="w-4 h-4 mr-3 rounded border-gray-300"
                  disabled={disabled}
                />
                <span className="font-medium">{serviceGroup.svc_group_name}</span>
              </div>
            </div>

            {serviceGroup.multi_layer_flag &&
              expandedGroups[serviceGroup.svc_group_id] &&
              serviceGroup.svc_layers?.length > 0 && (
                <div className="bg-gray-50 rounded-b-lg p-4">
                  <div className="ml-6 space-y-2">
                    {serviceGroup.svc_layers.map((service) => (
                      <div
                        key={service.service_id}
                        className="flex items-center p-2 hover:bg-gray-100 rounded"
                      >
                        <input
                          type="checkbox"
                          checked={selectedServices.some(
                            (selected) => selected.service_id === service.service_id
                          )}
                          onChange={() => handleChildChange(service, serviceGroup)}
                          className="w-4 h-4 mr-3 rounded border-gray-300"
                          disabled={disabled}
                        />
                        <span className="text-sm">{service.service_name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceGroupsSelection;