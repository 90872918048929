import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../CustomReusableComps/InputField";
import { toast } from "react-toastify";
import { PANEL_CREATE, PANEL_UPDATE } from "../../../Utils/Constant";
import ToggleButton from "../../CustomReusableComps/ToggleButton";
import { setSelectedViewQTokens } from "../../../Redux/ViewQueueTokensSlice";

const CreateViewQueueTokens = ({ isSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const selectedPanel = useSelector(
    (state) => state?.viewQueueTokens?.selectedViewQTokens
  );
  console.log("selectedPanel", selectedPanel);
  const userData = useSelector((state) => state.userManagement.items);
  console.log("userData", userData);

  const [formData, setFormData] = useState({
    panel_id: null,
    panel_name: "",
    status: false,
    branch_id: userData.user_details.branch_id,
    panel_pwd: "",
  });

  // Populate form on edit
  useEffect(() => {
    if (selectedPanel) {
      setFormData({
        panel_id: selectedPanel?.panel_id || null,
        panel_name: selectedPanel?.panel_name || "",
        branch_id: selectedPanel?.branch_id || null,
        panel_pwd: selectedPanel?.pwd || "",
        status: selectedPanel?.status,
      });
    }
  }, [selectedPanel]);

  const handleEditUser = async (rowData) => {
    try {
      const response = await fetch(PANEL_UPDATE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          panel_id: rowData.panel_id,
          panel_name: rowData.panel_name,
          branch_id: rowData.branch_id,
          panel_pwd: rowData.panel_pwd,
          status: rowData.status,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(t("Panel User updated successfully!"));
        dispatch(setSelectedViewQTokens(null));
        setFormData({
          panel_id: null,
          panel_name: "",
          status: false,
          branch_id: null,
          panel_pwd: "",
        });
        navigate("/body/viewQueueTokens");
      } else {
        toast.error(t(`Failed to update Panel User: ${responseData.message}`));
      }
    } catch (error) {
      console.error(t("Error updating Panel User:"), error);
      toast.error(t("An error occurred while updating the Panel User."));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    if (selectedPanel) {
      handleEditUser(formData);
    } else {
      try {
        const response = await fetch(PANEL_CREATE, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify({
            panel_name: formData.panel_name,
            branch_id: formData.branch_id,
            panel_pwd: formData.panel_pwd,
            status: formData.status,
          }),
        });

        const responseData = await response.json();
        if (response.ok) {
          toast.success(t("Panel User created successfully!"));
          setFormData({
            panel_id: null,
            panel_name: "",
            status: false,
            branch_id: null,
            panel_pwd: "",
          });
          navigate("/body/viewQueueTokens");
        } else {
          toast.error(t(`Failed to Panel User: ${responseData.message}`));
        }
      } catch (error) {
        console.error(t("Error creating Panel User:"), error);
        toast.error(t("An error occurred while creating the Panel User."));
      }
    }
  };

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, status: prev.status === false ? true : false }));
  };
  const PanelNameLabel =
  formSubmitted && !formData?.panel_name ? (
    <div className="text-red-500 mt-1">
      {t("Panel Name *")} <span className="text-red-500">*</span>
    </div>
  ) : (
    t("Panel Name *")
  );
  const PanelPasswordlabel =
    formSubmitted && !formData?.panel_pwd ? (
      <div className="text-red-500 mt-1">
        {t("Panel Password")} <span className="text-red-500">*</span>
      </div>
    ) : (
      t("Panel Password")
    );
  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold">{t("Add Panel Users")}</h1>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <InputField
              name="panel_name"
              label={PanelNameLabel}
              placeholder={t("Enter Panel Name")}
              value={formData.panel_name}
              onChange={handleInputChange}
              className="w-full"
            />
            <InputField
              name="panel_pwd"
              label={PanelPasswordlabel}
              placeholder={t("Enter Panel Password")}
              value={formData.panel_pwd}
              onChange={handleInputChange}
              className="w-full"
            />
            <ToggleButton
              isOn={formData.status === true}
              onToggle={handleToggleStatus}
              label={formData.status === true ? "Panel User Active" : "Panel User In-Active"}
            />
          </div>
          <div className="flex flex-row mt-7 gap-3">
            <button
              type="submit"
              className={`bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition  ${
                isSubmitting && "opacity-50 cursor-not-allowed"
              }`}
              disabled={isSubmitting}
            >
              {selectedPanel ? t("Update") : t("Submit")}
            </button>

            <button
              type="button"
              onClick={() => navigate("/body/viewQueueTokens")}
              className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
            >
              {t("Back")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateViewQueueTokens;
