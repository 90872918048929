import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CREATE_TOKEN_CONFIG,
  UPDATE_TOKEN_CONFIG,
} from "../../../Utils/Constant";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setTrackActiveStatus } from "../../../Redux/TokenConfigSlice";
import { InputField } from "../../CustomReusableComps/InputField";
import ToggleButton from "../../CustomReusableComps/ToggleButton";

const CreateTokenConfig = () => {
  const navigate = useNavigate(); 
  const { t } = useTranslation();
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector(
    (state) => state.userManagement.items.user_details
  );
  const selectedTokenConfig = useSelector(
    (state) => state.tokenConfig.selectedTokenConfigView
  );
  const dispatch = useDispatch();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formdata, setFormData] = useState({
    token_config_id: null,
    branch_id: userData?.branch_id,
    token_start_value: "",
    token_prefix: "",
    status: 0,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (selectedTokenConfig) {
      setFormData({
        token_config_id: selectedTokenConfig?.token_config_id || null,
        branch_id: selectedTokenConfig?.branch_id || userData?.branch_id,
        token_start_value:
          selectedTokenConfig?.token_start_value?.toString() || "",
        token_prefix: selectedTokenConfig?.token_prefix || "",
        status: selectedTokenConfig?.status ?? 0,
      });
      setIsEditMode(true);
    } else {
      // Reset form for create mode
      setFormData({
        token_config_id: null,
        branch_id: userData?.branch_id,
        token_start_value: "",
        token_prefix: "",
        status: 0,
      });
      setIsEditMode(false);
    }
  }, [selectedTokenConfig, userData]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Special handling for token_start_value to ensure positive integer
    if (id === "token_start_value") {
      // Remove non-numeric characters and leading zeros
      const numericValue = value.replace(/\D/g, "");

      setFormData((prevState) => ({
        ...prevState,
        [id]: numericValue,
      }));
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [id]: value.trim(),
    }));
  };

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, status: prev.status === 0 ? 1 : 0 }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    setError(null);

    // Validate token start value
    if (
      !formdata.token_start_value ||
      parseInt(formdata.token_start_value) <= 0
    ) {
      setError(t("Token start value must be a positive integer"));
      setIsSubmitting(false);
      return;
    }

    try {
      const apiUrl = isEditMode ? UPDATE_TOKEN_CONFIG : CREATE_TOKEN_CONFIG;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          ...formdata,
          token_start_value: parseInt(formdata.token_start_value),
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(
          isEditMode
            ? t("Token Config Updated Successfully")
            : t("Token Config Created Successfully")
        );
        dispatch(setTrackActiveStatus(responseData));
        navigate("/body/tokenConfig");
      } else {
        setError(responseData.message || t("An error occurred"));
      }
    } catch (err) {
      setError(err.message || t("An error occurred"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const tokenConfigValueName =
    formSubmitted && !formdata.token_start_value ? (
      <div className="text-red-500 mt-1">
        {t("config.TokenStartValue")} <span className="text-red-500">*</span>
      </div>
    ) : (
      t("config.TokenStartValue")
    );
  const tokenPrefixlabel =
    formSubmitted && !formdata.token_prefix ? (
      <div className="text-red-500 mt-1">
        {t("config.TokenPrefix")} <span className="text-red-500">*</span>
      </div>
    ) : (
      t("config.TokenPrefix")
    );
  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold font-sans">
        {isEditMode ? t("config.edit") : t("config.add")}
      </h1>
      <div className="mt-6 bg-white px-6 pb-10 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="flex flex-col p-1 font-roboto w-full ">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-3 w-full">
              <div className="mb-4 flex flex-col gap-2 items-start w-full ">
                <InputField
                  type="text"
                  label={tokenConfigValueName}
                  id="token_start_value"
                  value={formdata.token_start_value}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded outline-none"
                  placeholder={t("config.place")}
                  required
                  pattern="\d+"
                  min="1"
                />
              </div>
              <div className="mb-4 flex flex-col gap-2 items-start w-full ">
                <InputField
                  label={tokenPrefixlabel}
                  type="text"
                  id="token_prefix"
                  value={formdata.token_prefix}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded outline-none"
                  placeholder={t("config.placetoken")}
                  required
                />
              </div>
            </div>
            <div className="">
              <Box display="flex" alignItems="start" gap={2} mt={1} mb={1}>
                <ToggleButton
                  isOn={formdata.status === 1}
                  onToggle={handleToggleStatus}
                  label={
                    formdata.status === 1
                      ? "Token Config Active"
                      : "Token Config In-Active"
                  }
                />
              </Box>
            </div>

            <div className="flex flex-row mt-7 gap-3">
              <button
                type="submit"
                className={`bg-blue-500 text-white py-2 px-6 rounded shadow hover:bg-blue-600 transition ${
                  isSubmitting && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? t("Institution.submitting")
                  : isEditMode
                  ? t("counters.update")
                  : t("Institution.submit")}
              </button>
              <button
                type="button" 
                onClick={() => navigate("/body/tokenConfig")}
                className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
              >
                {t("Back")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTokenConfig;
