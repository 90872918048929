import React, { useState, useEffect } from "react";
import Table from "../../CustomReusableComps/Table";
import { Container, Card, IconButton } from "@mui/material";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import {   DELETE_SERVICE, SERVICE_LIST} from '../../../Utils/Constant'
import { useDispatch, useSelector ,} from "react-redux";
import { toast } from "react-toastify";
import ShimmerTable from "../../useShimmerTable";
import { setSelectedServicetView, setSerView } from "../../../Redux/SettingsSlice";
import { getServiceById } from "../../../Api_Services/serviceApi";

const ServiceList = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const dispatch = useDispatch();
  const userData = useSelector((state)=> state.userManagement.items)
  console.log(userData);

  
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    console.log(selectedRow);
  }; 

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(SERVICE_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          user_based_list: true,
          order_by: 1,
          keyword: "",
          inst_details: true,
          inst_id:userData?.user_details?.inst_id
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
      dispatch(setSerView(responseData.data))
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddUserClick = () => {
    dispatch(setSelectedServicetView(null));
    navigate("/body/serviceForm")
  }

  const handleEditService = async (svc_group_id) => {
    try {
      const serviceData = await getServiceById(svc_group_id, token, deviceId, true);
      dispatch(setSelectedServicetView({ ...serviceData }));
      navigate('/body/serviceForm');
    } catch (error) {
      console.log("Failed to fetch service details", error);
    }
  };

  const handleView = (rowData) => {  
    dispatch(setSelectedServicetView(rowData));
    navigate('/body/serviceView')
  };

  const handleDelete = (rowData) => {
    console.log("Delete clicked for row:", rowData);
    setSelectedRow(rowData);
    handleDeleteService(rowData);
  };

  const handleDeleteService = async (rowData) => {
    setLoading(true);
    try {
      const response = await fetch(DELETE_SERVICE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          svc_group_id: rowData.svc_group_id,
        }),
      });
      const responseData = await response.json(); 
      console.log("Delete response:", responseData);

      if (response.ok) {
        fetchData(currentPage, pageSize); 
        toast.success("Service deleted successfully!");
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete Service: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error deleting Service:", error);
      toast.error("An error occurred while deleting the Service.");
    } finally {
      setLoading(false);
      handleCloseActions();
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    { key: "svc_group_id", label: "Service Id" , content: (item) =>  item.svc_group_id || "-" } ,
    { key: "svc_group_name", label: t("Service Name") , content : (item)=> item.svc_group_name || "-"},
    { key: "status", label: t("Service Status") ,   
        content: (item) => (item.status === 1 ? t("Active") : t("In-Active")),
    },
    { key: "created_by", label: "Created By", content : (item)=> item.created_by || "-" },
    { key: "updated_by", label: t("Updated By") ,content : (item)=> item.updated_by || "-"  },
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" realtive mb-4 flex justify-between items-center">
        <Button name={t("services.addservice")} onClick={handleAddUserClick} />
        <Breadcrumbs options={[{ name: t("services.service"), pathName: "/body/service" }]} />
      </div>

      
      {loading ? (
        <ShimmerTable/>
      ) : (

      
          
            <Table
              data={data || []}
              headers={headers}
              loading={loading}
              searchPlaceholder="Search by name or email"
              filterOptions={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              totalRowsLabel={`Total Rows: ${totalCount}`}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              totalItems={totalCount}
            />
          
        
      )}

      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={()=>handleEditService(selectedRow?.svc_group_id)}
        onDelete={handleDelete}
        onView={handleView}
        hideBlock={true}
      />
    </div>
  );
};

export default ServiceList;
