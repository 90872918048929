import { createSlice } from "@reduxjs/toolkit";

const tokenManagementSlice = createSlice({
  name: "tokenManagement",
  initialState: {
    selectedCurrentToken: {},
  },
  reducers: {
    setSelectedCurrentToken: (state, action) => {
        state.selectedCurrentToken = action.payload;
      },
      
    clearProfileData: (state) => {
      state.selectedCurrentToken = {};
    },
  },
});

// Export the actions for use in components
export const { setSelectedCurrentToken } = tokenManagementSlice.actions;

// Export the reducer to be used in the store
export default tokenManagementSlice.reducer;
