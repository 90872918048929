import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../CustomReusableComps/InputField";
import { toast } from "react-toastify";
import { ADD_TERMINAL, EDIT_TERMINAL } from "../../../Utils/Constant";
import ToggleButton from "../../CustomReusableComps/ToggleButton";
import { setSelectedTerminalView } from "../../../Redux/TerminalSlice";

const CreateTerminal = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const selectedTerminal = useSelector(
    (state) => state.terminal?.selectedTerminalView
  );
  console.log("selectedCounterselectedCounter", selectedTerminal);
  const userData = useSelector((state) => state.userManagement.items);
  console.log("userData", userData);

  const [formData, setFormData] = useState({
    terminal_id: null,
    terminal_name: "",
    status: 0,
    branch_id: userData.user_details.branch_id,
    terminal_pwd: "",
  });

  // Populate form on edit
  useEffect(() => {
    if (selectedTerminal) {
      setFormData({
        terminal_id: selectedTerminal?.terminal_id || null,
        terminal_name: selectedTerminal?.terminal_name || "",
        branch_id: selectedTerminal?.branch_id || null,
        terminal_pwd: selectedTerminal?.terminal_pwd || "",
        status: selectedTerminal?.status || 1,
      });
    }
  }, [selectedTerminal]);

  const handleEditUser = async (rowData) => {
    try {
      const response = await fetch(EDIT_TERMINAL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          terminal_id: rowData.terminal_id,
          terminal_name: rowData.terminal_name,
          branch_id: rowData.branch_id,
          terminal_pwd: rowData.terminal_pwd,
          status: rowData.status,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(t("Terminal updated successfully!"));
        dispatch(setSelectedTerminalView(null));
        setFormData({
          terminal_id: null,
          terminal_name: "",
          status: 0,
          branch_id: null,
          terminal_pwd: "",
        });
        navigate("/body/terminals");
      } else {
        toast.error(t(`Failed to update terminal: ${responseData.message}`));
      }
    } catch (error) {
      console.error(t("Error updating terminal:"), error);
      toast.error(t("An error occurred while updating the terminal."));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    if (selectedTerminal) {
      handleEditUser(formData);
    } else {
      try {
        const response = await fetch(ADD_TERMINAL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify({
            terminal_name: formData.terminal_name,
            branch_id: formData.branch_id,
            terminal_pwd: formData.terminal_pwd,
            status: formData.status,
          }),
        });

        const responseData = await response.json();
        if (response.ok) {
          toast.success(t("Terminal created successfully!"));
          setFormData({
            terminal_id: null,
            terminal_name: "",
            status: 0,
            branch_id: null,
            terminal_pwd: "",
          });
          navigate("/body/terminals");
        } else {
          toast.error(t(`Failed to create terminal: ${responseData.message}`));
        }
      } catch (error) {
        console.error(t("Error creating terminal:"), error);
        toast.error(t("An error occurred while creating the terminal."));
      }
    }
  };

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, status: prev.status === 0 ? 1 : 0 }));
  };
  const terminalNameLabel =
  formSubmitted && !formData?.terminal_name ? (
    <div className="text-red-500 mt-1">
      {t("terminal.TerminalName")} <span className="text-red-500">*</span>
    </div>
  ) : (
    t("terminal.TerminalName")
  );
  const terminalPasswordlabel =
    formSubmitted && !formData?.terminal_pwd ? (
      <div className="text-red-500 mt-1">
        {t("terminal.TerminalPassword")} <span className="text-red-500">*</span>
      </div>
    ) : (
      t("terminal.TerminalPassword")
    );
  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold">{t("terminal.addterminal")}</h1>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <InputField
              name="terminal_name"
              label={terminalNameLabel}
              placeholder={t("terminal.place")}
              value={formData.terminal_name}
              onChange={handleInputChange}
              className="w-full"
            />
            <InputField
              name="terminal_pwd"
              label={terminalPasswordlabel}
              placeholder={t("terminal.placepass")}
              value={formData.terminal_pwd}
              onChange={handleInputChange}
              className="w-full"
            />
            <ToggleButton
              isOn={formData.status === 1}
              onToggle={handleToggleStatus}
              label={formData.status === 1 ? t("terminal.active") : t("terminal.inactive")}
            />
          </div>
          <div className="flex flex-row mt-7 gap-3">
            <button
              type="submit"
              className={`bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition  ${
                isSubmitting && "opacity-50 cursor-not-allowed"
              }`}
              disabled={isSubmitting}
            >
              {selectedTerminal ? t("Update") : t("Submit")}
            </button>

            <button
              type="button"
              onClick={() => navigate("/body/terminals")}
              className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
            >
              {t("Back")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTerminal;
