import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RenderSelect from "../../MUI_Reusable_Comps/RenderSelect";

const LanguageSettings = () => {
  const { i18n, t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  // Available languages
  const availableLanguages = [
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "fr", name: "French" },
    { code: "hi", name: "Hindi" },
    // {code : 'kan', name: 'Kannada'}
  ];

  // Change language handler
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;

    // Change language using i18n
    i18n.changeLanguage(selectedLanguage);

    // Update local state
    setCurrentLanguage(selectedLanguage);

    // Optional: Store language preference in localStorage
    localStorage.setItem("appLanguage", selectedLanguage);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("appLanguage");
    if (
      savedLanguage &&
      availableLanguages.some((lang) => lang.code === savedLanguage)
    ) {
      i18n.changeLanguage(savedLanguage);
      setCurrentLanguage(savedLanguage);
    }
  }, []);

  return (
    <div className="max-w-md  p-6  rounded-lg ">
      <div className="flex items-center mb-6">
        <span className="text-2xl mr-3">🌐</span>
        <h2 className="text-xl font-semibold text-gray-800">
          {t("languageSettings.title")}
        </h2>
      </div>

      <div className="mb-4">
        <RenderSelect
          name="currentLanguage"
          label={t("languageSettings.selectLanguage")}
          onChange={handleLanguageChange}
          value={currentLanguage}
          options={availableLanguages?.map((lang) => ({
            id: lang.code,
            name: lang.name,
          }))}
          fullWidth
          
        />
      </div>

      <div className="mt-6 p-3 bg-gray-100 rounded-md">
        <div className="text-sm">
          <span className="font-medium text-gray-700">
            {t("languageSettings.currentLanguage")}:
          </span>
          <span className="text-blue-600">
            {
              availableLanguages.find((lang) => lang.code === currentLanguage)
                ?.name
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default LanguageSettings;
