import React, { useState , useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBranchView, setSelectedBranchView } from "../../../Redux/SettingsSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../MUI_Reusable_Comps/Button";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import ShimmerTable from "../../useShimmerTable";
import { Container, Card, IconButton } from "@mui/material";
import Table from "../../CustomReusableComps/Table";
import { DELETE_BRANCH, LIST_BRANCH } from "../../../Utils/Constant";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";


const BranchList = ({ branch}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

    useEffect(() => {
      fetchData(currentPage, pageSize);
    }, [currentPage, pageSize]);



    
      const fetchData = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
          const response = await fetch(LIST_BRANCH, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              DeviceID: deviceId,
            },
            body: JSON.stringify({
              page: page,
              no_of_data: pageSize,
              inst_id: 0,
              keyword: "",
              user_based_list: true,
              order_by: 1,
              inst_details: true,
            }),
          });
    
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
    
          const responseData = await response.json();
          setData(responseData.data);
          setTotalCount(responseData.count);
          dispatch(setBranchView(responseData.data))
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

        const handleDeleteBranch = async (rowData) => {
       
           setLoading(true);
           try {
             const response = await fetch(DELETE_BRANCH, {
               method: "POST",
               headers: {
                 "Content-Type": "application/json",
                 Authorization: `Bearer ${token}`,
                 DeviceID: deviceId,
               },
               body: JSON.stringify({
                branch_id: rowData.branch_id,
               }),
             });
             const responseData = await response.json(); 
             console.log("Delete response:", responseData);
       
             if (response.ok) {
               fetchData(currentPage, pageSize); 
               toast.success("Branch deleted successfully!");
             } else {
               const errorData = await response.json();
               toast.error(
                 `Failed to delete branch: ${errorData.message || "Unknown error"}`
               );
             }
           } catch (error) {
             console.error("Error deleting branch:", error);
             toast.error("An error occurred while deleting the branch.");
           } finally {
             setLoading(false);
             handleCloseActions();
           }
         };

 
      const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        fetchData(page, pageSize);
      };
      
  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };
      const handleCloseActions = () => {
        setAnchorEl(null);
        setSelectedRow(null);
      };   
        const handleEdit = (rowData) => {
          dispatch(setSelectedBranchView(rowData));
          navigate('/body/editbranch')
        };

         const handleView = (rowData) => {  
            dispatch(setSelectedBranchView(rowData));
            navigate('/body/viewbranch')
          };
        
          const handleDelete = (rowData) => {
            console.log("Delete clicked for row:", rowData);
            setSelectedRow(rowData);
            handleDeleteBranch(rowData);
          };
      
      const headers = [
        { key: "branch_id", label: "Branch Id" },
        { key: "branch_name", label: t("Branch Name") },
        {key:"branch_address", label: t("Branch Address")},
        {key:"branch_mobile", label:t("Branch contact number")},
        {key:"branch_email", label:t("Branch Email")},
        {key:"branch_alt_email", label:t("Branch Alternate Email")},
        { key: "status", label: t("Branch Status") ,   
            content: (item) => (item.status === 1 ? t("Active") : t("In-Active")),
        },
        {key: "created_time", label: t("Created Time")},
        {key:"created_by", label:t("Created By")},
        {key:"inst_name", label:t("Institution Name")},
        {
          key: "action",
          label: t("UserPage.Actions"),
          nonExportable: true,
          columnStyle: { width: "1%" },
          content: (rowData) => (
            <div>
              <IconButton
                onClick={(event) => handleOpenActions(event, rowData)}
                color="primary"
                sx={{ height: "20px" }}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          ),
        },
      ];
    
  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
    <div className=" mb-4 flex justify-between items-center">
      <Button name="Add Branch" onClick={()=> navigate('/body/addbranch')} />
      <Breadcrumbs options={[{ name: "Branch", pathName: "/body/branchlist" }]} />
    </div>

    {loading ? (
      <ShimmerTable/>
    ) : (

        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by name or email"
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      
    )}

    <ActionPopover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      handleClose={handleCloseActions}
      selectedRow={selectedRow}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onView={handleView}
      hideBlock={true}
    />
  </div>
  );
};

export default BranchList;
