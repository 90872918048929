import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { InputField } from "../../CustomReusableComps/InputField";
import ToggleButton from "../../CustomReusableComps/ToggleButton";

const BranchForm = ({ selectedBranch, onSubmit, isSubmitting }) => {
  const [formData, setFormData] = useState({
    branchName: "",
    branchAddress: "",
    branchEmail: "",
    branchAltEmail: "",
    branchPhone: "",
  branchAltPhone: "",
    branchStatus: 0,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const userData = useSelector((state) => state.userManagement.items);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedBranch) {
      setFormData({
        branchName: selectedBranch?.branch_name || "",
        branchAddress: selectedBranch?.branch_address || "",
        branchEmail: selectedBranch?.branch_email || "",
        branchAltEmail: selectedBranch?.branch_alt_email || "",
        branchPhone: selectedBranch?.branch_mobile || "",
        branchAltPhone: selectedBranch?.branch_alt_mobile || "",
        branchStatus: selectedBranch?.status,
      });
    }
  }, [selectedBranch]);
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{6,12}$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "branchPhone" || name === "branchAltPhone") {
      const numbersOnly = value.replace(/\D/g, "");
      setFormData((prev) => ({
        ...prev,
        [name]: numbersOnly.slice(0, 12),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const hasPhoneError =
      formData.branchPhone && !validatePhone(formData.branchPhone);
    const hasEmailError = formData.email && !validateEmail(formData.email);
    // const hasAltPhoneError =
    //   formData.branchAltPhone &&
    //   !validatePhone(formData.branchAltPhone);
    // const hasAltEmailError =
    //   formData.branchPhone && !validateEmail(formData.branchAltPhone);

    if (
      hasPhoneError ||
      hasEmailError
      // hasAltPhoneError ||
      // hasAltEmailError
    ) {
      return;
    }
    const payload = {
      branch_id: selectedBranch?.branch_id,
      branch_name: formData.branchName,
      branch_email: formData.branchEmail,
      branch_alt_email: formData.branchAltEmail,
      branch_mobile: formData.branchPhone,
      branch_alt_mobile: formData.branchAltPhone,
      branch_address: formData.branchAddress,
      status: formData.branchStatus,
      inst_id: userData?.user_details?.inst_id,
    };
    onSubmit(payload);
  };

  const getFieldLabel = (fieldName, label) => {
    return formSubmitted && !formData[fieldName] ? (
      <div className="text-red-500 mt-1">
        {t(label)} <span className="text-red-500">*</span>
      </div>
    ) : (
      t(label)
    );
  };
  const handleToggleStatus = (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      branchStatus: prev.branchStatus === 0 ? 1 : 0,
    }));
  };
  const phnLabel = formSubmitted ? (
    !formData?.branchPhone ? (
      <div className="text-red-500 mt-1">{t("Phone Number *")}</div>
    ) : !validatePhone(formData.branchPhone) ? (
      <div className="text-red-500 mt-1">
        {t("Enter 6-12 digit phone number *")}
      </div>
    ) : (
      t("Phone Number *")
    )
  ) : (
    t("Phone Number *")
  );
  const altPhnLabel =
    formSubmitted &&
    formData?.branchAltPhone &&
    !validatePhone(formData.branchAltPhone) ? (
      <div className="text-red-500 mt-1">
        {t("Enter 6-12 digit phone number")}
      </div>
    ) : (
      t("Alternate Mobile Number")
    );
  const emailLabel = formSubmitted ? (
    !formData?.branchEmail ? (
      <div className="text-red-500 mt-1">{t("E-mail *")}</div>
    ) : !validateEmail(formData.branchEmail) ? (
      <div className="text-red-500 mt-1">
        {t("Enter valid email address *")}
      </div>
    ) : (
      t("E-mail *")
    )
  ) : (
    t("E-mail *")
  );

  const altEmailLabel =
    formSubmitted &&
    formData?.branchAltEmail &&
    !validateEmail(formData.branchAltEmail) ? (
      <div className="text-red-500 mt-1">{t("Enter valid email address")}</div>
    ) : (
      t("Alternate E-mail")
    );

  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold font-sans">Add Branch</h1>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <InputField
              type="text"
              name="branchName"
              value={formData.branchName}
              label={getFieldLabel("branchName", "BranchPage.branch_name")}
              onChange={handleChange}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.branch_name_placeholder")}
              required
            />
            <InputField
              type="text"
              name="branchAddress"
              label={getFieldLabel(
                "branchAddress",
                "BranchPage.Branch_address"
              )}
              value={formData.branchAddress}
              onChange={handleChange}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.address_placeholder")}
              required
            />
            <InputField
              type="email"
              name="branchEmail"
              label={emailLabel}
              value={formData.branchEmail}
              onChange={handleChange}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.email_placeholder")}
              required
            />
            <InputField
              type="email"
              name="branchAltEmail"
              label={altEmailLabel}
              value={formData.branchAltEmail}
              onChange={handleChange}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("Enter alternate email")}
            />
            <InputField
              name="branchPhone"
              label={phnLabel}
              value={formData.branchPhone}
              onChange={handleChange}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.phone_placeholder")}
              maxLength={12}
            />
            <InputField
              name="branchAltPhone"
              label={altPhnLabel}
              value={formData.branchAltPhone}
              onChange={handleChange}
              className="w-full border p-2 rounded outline-none"
              placeholder={t("BranchPage.phone_placeholder")}
              maxLength={12}
            />
            <ToggleButton
              isOn={formData.branchStatus === 1}
              onToggle={handleToggleStatus}
              label={
                formData.branchStatus === 1
                  ? "Branch Active"
                  : "Branch Inactive"
              }
            />
          </div>
          <div className="flex mt-8 justify-start gap-2">
            <button
              type="submit"
              className="bg-color-light-blue text-color-white py-2 px-4 rounded hover:bg-color-blue"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? t("BranchPage.submitting")
                : t("BranchPage.submit")}
            </button>
            <button
              type="button"
              onClick={() => navigate("/body/branchlist")}
              className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
            >
              {t("BranchPage.back")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BranchForm;
