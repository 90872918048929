import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    serviceView: [],
    isCollapse: false,
    selectedServiceView: [],
    isMobile: false,
    createTokenView: [],
    selectedCreateTokenView: [],
  },
  reducers: {
    setIsCollapse: (state, action) => {
      state.isCollapse = !state.isCollapse;
    },
    setIsMobile: (state, action) => {
      state.isMobile = !state.isMobile;
    },
    setServiceView: (state, action) => {
      state.serviceView = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedServiceView = action.payload;
    },
    setCreateTokenView: (state, action) => {
      state.createTokenView = action.payload;
    },
    setSelectedCreateTokenView :(state, action) => {
      state.selectedCreateTokenView = action.payload;
    },

    clearUserData: (state) => {
      state.isCollapse = false;
      state.isMobile = false;
      state.serviceView = [];
      state.selectedServiceView = [];
      state.createTokenView = [];
      state.selectedCreateTokenView = [];
    },
  },
});

export const {
  setIsCollapse,
  setServiceView,
  setIsMobile,
  setSelectedService,
  setCreateTokenView,
  setSelectedCreateTokenView
} = serviceSlice.actions;

export default serviceSlice.reducer;
