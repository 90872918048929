import React, { useEffect, useState } from 'react'; 
import { LIST_INSTITUTION, USERS_LIST_INADMIN } from '../../Utils/Constant';
import { useSelector } from 'react-redux';
import { 
  Card, 
  CardContent, 
  Grid, 
  Typography, 
  CircularProgress, 
  Box, 
  Paper, 
  Divider 
} from '@mui/material';
import { 
  PieChart, 
  Pie, 
  Cell, 
  ResponsiveContainer, 
  Tooltip, 
  Legend 
} from 'recharts';
import { 
  Business as BusinessIcon, 
  People as PeopleIcon 
} from '@mui/icons-material';

const MainAdminDashboard = () => {
  const [instituteData, setInstituteData] = useState({
    total: 0,
    active: 0,
    inactive: 0
  });
  console.log( "fffffffffff",instituteData.active);
  
  const [userData, setUserData] = useState({
    total: 0,
    active: 0,
    inactive: 0
  });
  const [loading, setLoading] = useState(true);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);

  useEffect(() => {
    Promise.all([
      fetchInstituteData(),
      fetchUserData()
    ]).finally(() => setLoading(false));
  }, []);

  const fetchInstituteData = async () => {
    try {
      const response = await fetch(LIST_INSTITUTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: 1,
          no_of_data: 10
        }),
      });

      const responseData = await response.json();
      
      // Ensure that responseData.data exists and then calculate active and inactive
      const activeInstitutes = responseData?.data?.filter(inst => inst.status === 1).length || 0;
      const inactiveInstitutes = responseData?.data?.filter(inst => inst.status === 0).length || 0;

      setInstituteData({
        total: responseData?.count || 0,
        active: activeInstitutes,
        inactive: inactiveInstitutes
      });
    } catch (error) {
      console.error("Error fetching institute data:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(USERS_LIST_INADMIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: 1,
          no_of_data: 10,
          user_based_list: true,
          order_by: 1,
        }),
      });

      const responseData = await response.json();
      
      // Ensure that responseData.data exists and then calculate active and inactive
      const activeUsers = responseData?.data?.filter(user => user.status === 1).length || 0;
      const inactiveUsers = responseData?.data?.filter(user => user.status === 0).length || 0;

      setUserData({
        total: responseData?.count || 0,
        active: activeUsers,
        inactive: inactiveUsers
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const instituteChartData = [
    { name: 'Active', value: instituteData.active, color: '#4caf50' },
    { name: 'Inactive', value: instituteData.inactive, color: '#f44336' }
  ];

  const userChartData = [
    { name: 'Active', value: userData.active, color: '#2196f3' },
    { name: 'Inactive', value: userData.inactive, color: '#ff9800' }
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        align="left" 
        sx={{ fontWeight: 'bold', color: '#333', mb: 4 }}
      >
        Admin Dashboard
      </Typography>

      <Grid container spacing={4}>
        {/* Institutes Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ borderRadius: 3, p: 3 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <BusinessIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h5" fontWeight="bold">
                Institutes Overview
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1">Total Institutes</Typography>
                    <Typography variant="h4" color="primary">
                      {instituteData.total}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={instituteChartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius="60%"
                      outerRadius="80%"
                    >
                      {instituteChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Users Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ borderRadius: 3, p: 3 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <PeopleIcon sx={{ mr: 2, color: 'secondary.main' }} />
              <Typography variant="h5" fontWeight="bold">
                Users Overview
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1">Total Users</Typography>
                    <Typography variant="h4" color="secondary">
                      {userData.total}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={userChartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius="60%"
                      outerRadius="80%"
                    >
                      {userChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainAdminDashboard;
