import React, { useState, useEffect } from "react";
import Table from "../../CustomReusableComps/Table";
import { IconButton } from "@mui/material";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import { USERS_LIST_INADMIN } from "../../../Utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { USER_DELETE } from "../../../Utils/Constant";
import {
  setSelectedUserView,
  collectFetchUsers,
} from "../../../Redux/UserManagementSlice";
import ShimmerTable from "../../useShimmerTable";
import ConfirmBox from "../../MUI_Reusable_Comps/ConfirmBox";
import { getUserById } from "../../../Api_Services/userService";

const User = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector((state) => state.userManagement.items);
  console.log("dsdsdfsdsdsffs", userData);
  const userType = useSelector((store) => store.userManagement.items.user_type);
  console.log("userType", userType);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleOpenActions = (event, rowData) => {
    console.log("Action Row Data:", rowData);
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  // const handleCloseDialog = () => {
  //   setDeleteDialogOpen(false);
  //   setSelectedRow(null);
  // };
  const fetchData = async (page = 1, pageSize = 10) => {
    console.log(userData?.user_details?.inst_id);
    setLoading(true);
    try {
      const response = await fetch(USERS_LIST_INADMIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          user_based_list: true,
          order_by: 1,
          keyword: "",
          inst_details: true,
          inst_id: userData?.user_details?.inst_id,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
      dispatch(collectFetchUsers(responseData.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddUserClick = () => {
    dispatch(setSelectedUserView(null));
    navigate("/body/adduser");
  };
  const handleEdit = async (data) => {
    const decrypt_pwd = true;
    try {
      const usersData = await getUserById(
        data.user_id,
        decrypt_pwd,
        token,
        deviceId
      );

      dispatch(setSelectedUserView({ ...usersData }));
      navigate("/body/createUser");
    } catch (error) {
      console.log("Failed to fetch user details", error);
    }
  };

  const handleView = (rowData) => {
    dispatch(setSelectedUserView(rowData));
    navigate("/body/viewuser");
  };

  const handleDelete = (rowData) => {
    setUserToDelete(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!userToDelete?.user_id) {
      toast.error("User ID not found");
      handleCloseDialog();
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(USER_DELETE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({ user_id: userToDelete.user_id }),
      });

      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.message);

      toast.success("User deleted successfully!");
      fetchData(currentPage, pageSize);
    } catch (error) {
      toast.error(error.message || "Failed to delete user");
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    {
      key: "user_id",
      label: "User ID",
      content: (item) => item.user_id || "-",
    },
    {
      key: "username",
      label: t("UserPage.User_Name"),
      content: (item) => item.username || "-",
    },
    {
      key: "mobile_number",
      label: t("UserPage.Contact_Number"),
      content: (item) => item.mobile_number || "-",
    },
    {
      key: "alternate_mob_number",
      label: t("UserPage.AlternateMobileNumber"),
      content: (item) => item.alternate_mob_number || "-",
    },
    {
      key: "email",
      label: t("UserPage.Email_Id"),
      content: (item) => item.email || "-",
    },
    {
      key: "alternate_email",
      label: t("UserPage.AlterEmailId"),
      content: (item) => item.alternate_email || "-",
    },
    {
      key: "status",
      label: t("UserPage.userStatus"),
      content: (item) =>
        item.status !== undefined
          ? item.status === 1
            ? t("Active")
            : t("In-Active")
          : "-",
    },
    {
      key: "inst_name",
      label: t("UserPage.InstitutionName"),
      content: (item) => item.inst_name || "-",
    },
    {
      key: "user_type",
      label: t("UserPage.user_type"),
      content: (item) => item.user_type || "-",
    },
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" mb-4 flex justify-between items-center">
        <Button name="Add User" onClick={handleAddUserClick} />
        <Breadcrumbs options={[{ name: "Users", pathName: "/body/user" }]} />
      </div>

      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by name or email"
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      )}

      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        hideBlock={true}
      />

      <ConfirmBox
        open={deleteDialogOpen}
        onCancel={handleCloseDialog}
        onAgree={handleDeleteConfirm}
        title="Confirm Deletion"
        content={`Are you sure you want to delete ${
          userToDelete?.username || "this user"
        }?`}
        remove="Delete"
        cancel="Cancel"
        disabled={loading}
        style={{ minWidth: 400 }}
        focusOnCancel={true}
      />
    </div>
  );
};

export default User;
