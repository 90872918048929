import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDepartView, setSelectedDepartView } from "../../../Redux/SettingsSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DELETE_DEPARTMENT, LIST_DEPARTMENT } from "../../../Utils/Constant";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import Button from "../../MUI_Reusable_Comps/Button";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Table from "../../CustomReusableComps/Table";
import { IconButton } from "@mui/material";
import ShimmerTable from "../../useShimmerTable";
import { toast } from "react-toastify";

const DepartmentList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleAddDepartment = () => {
    dispatch(setSelectedDepartView(null));
    navigate("/body/adddepartment");
  };

  const handleViewDepartment = (rowData, index) => {
    dispatch(setSelectedDepartView(rowData));
    navigate("/body/viewdepartment");
  };

  const handleEditDepartment = (rowData) => {
    dispatch(setSelectedDepartView(rowData));
    navigate(`/body/editdepartment`);
  };

  const handleDeleteDepartment = (rowData) => {
    dispatch(setSelectedDepartView(rowData));
    handleDelDepartment(rowData)
  };

  const headers = [
    { key: "department_id", label: t("Department.Department_id") },
    { key: "department_name", label: t("Department.Department_Name") },
    { key: "status", label: t("Department.status"),
      content : (item) => (item.status === 1 ? t("Active") : t("In-Active"))
     },
    { key: "created_time", label: t("constants.created_time") },
    {
      key: "updated_time",
      label: t("constants.Updated_Time"),
      content: (item) => (
        <div className="text-center">
          {item && item.updated_time ? item.updated_time : "N/A"}
        </div>
      ),
    }, 
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(LIST_DEPARTMENT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          user_based_list: true,
          order_by: 1,
          keyword: "",
          inst_details: true,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
      dispatch(setDepartView(responseData.data))
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelDepartment = async (rowData) => {
    setLoading(true);
    try {
      const response = await fetch(DELETE_DEPARTMENT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          department_id: rowData.department_id
          ,
        }),
      });
      const responseData = await response.json(); 
      console.log("Delete response:", responseData);

      if (response.ok) {
        fetchData(currentPage, pageSize); 
        toast.success("Department deleted successfully!");
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete department: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting the Department.");
    } finally {
      setLoading(false);
      handleCloseActions();
    }
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    console.log(selectedRow);
  };
  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" mb-4 flex justify-between items-center">
        <Button name={t("Department.add")} onClick={handleAddDepartment} />
        <Breadcrumbs
          options={[{ name:t("department"), pathName: "/body/departmentList" }]}
        />
      </div>

      {loading ? (
        <ShimmerTable /> 
      ) : (
        
              <div className="">
                <Table
                  data={data || []}
                  headers={headers}
                  loading={loading}
                  searchPlaceholder="Search by name or email"
                  filterOptions={[
                    { label: "Active", value: "Active" },
                    { label: "Inactive", value: "Inactive" },
                  ]}
                  totalRowsLabel={`Total Rows: ${totalCount}`}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  totalItems={totalCount}
                />
              </div>
           
      )}
      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={handleEditDepartment}
        onDelete={handleDeleteDepartment}
        onView={handleViewDepartment}
        hideBlock={true}
      />
    </div>
  );
};

export default DepartmentList;
