import React from "react";
import UserForm from "../User/UserForm";
import {  ADD_USERS } from "../../../Utils/Constant";
import useAdd from "../../../Hooks/useAdd";

const AddUser = () => {
  const { handleAddSubmit, isSubmitting } = useAdd(
    ADD_USERS,
    "/body/user"
  );

  return (
    <div>
      <UserForm onSubmit={handleAddSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default AddUser;
