import React, { useState, useEffect } from "react";
import Table from "../CustomReusableComps/Table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ShimmerTable from "../useShimmerTable";
import { SERVICE_LIST, Transfer_TOKEN } from "../../Utils/Constant";
import { toast } from "react-toastify";

const AvialServicesToTrans = ({ onClose, onTransferedToken }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const tokenDetails = useSelector(
    (state) => state?.tokenManagement?.selectedCurrentToken
  );
  console.log("tokenDetails", tokenDetails);
  const token = useSelector((store) => store?.token?.token);
  const deviceId = useSelector((store) => store?.token?.deviceId);
  const userData = useSelector((state) => state?.userManagement?.items);

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(SERVICE_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          branch_id: userData?.user_details?.profile_details?.branch_id,
          profile_based_list: true,
          order_by: 1,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch held tokens");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const transferToken = async (rowData) => {
    try {
      const response = await fetch(Transfer_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          token_id: tokenDetails.data.token_id,
          svc_group_id: rowData.svc_group_id,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      onTransferedToken(data); // Pass the response data to parent
      onClose(); // Close the modal
      toast.success("Token transfered successfully");
    } catch (error) {
      console.log("error", error);
      console.error("Error serving token:", error);
      toast.error("Failed to transfer token");
    }
  };

  const headers = [
    { key: "svc_group_id", label: "Service Id" },
    { key: "svc_group_name", label: t("Service Name") },
    {
      key: "status",
      label: t("Service Status"),
      content: (item) => (item.status === 1 ? t("Active") : t("In-Active")),
    },
    { key: "created_by", label: "Created By" },
    { key: "created_on", label: t("Created On") },
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <button
            onClick={() => transferToken(rowData)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Transfer
          </button>
        </div>
      ),
    },
  ];
  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by token name "
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      )}
    </div>
  );
};

export default AvialServicesToTrans;
