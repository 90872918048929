import { createSlice } from "@reduxjs/toolkit";

const ViewQueueTokensSlice = createSlice({
  name: "viewQueueTokens",
  initialState: {
    selectedViewQTokens: [],
    ViewQTokens : []
  },
  reducers: {
    setViewQTokens: (state, action) => {
      state.ViewQTokens = action.payload;
    },
    setSelectedViewQTokens: (state, action) => {
      state.selectedViewQTokens = action.payload;
    },

    clearViewQTokensData: (state) => {
      state.ViewQTokens = [];
      state.selectedViewQTokens = [];
    },
  },
});

// Export the actions for use in components
export const { setSelectedViewQTokens, setViewQTokens, clearViewQTokensData } =
ViewQueueTokensSlice.actions;

// Export the reducer to be used in the store
export default ViewQueueTokensSlice.reducer;
