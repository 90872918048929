import React from "react";
import Header from "./Pages/Header/header";
import Sidebar from "./Pages/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const Body = () => {
  const user = useSelector((store) => store.userManagement.items);

  if (!user) return null;
  return (
    <div className="flex flex-row h-screen bg-gray-50 overflow-hidden">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header loginData={user} />
        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Body;
