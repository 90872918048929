import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ADD_PROFILE,
  COUNTER_LIST,
  LIST_DEPARTMENT,
  UPDATE_PROFILE,
  USERS_LIST_INADMIN,
} from "../../../Utils/Constant";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ServiceGroupsSelection from "./ServicesListComponent";
import RenderSelect from "../../MUI_Reusable_Comps/RenderSelect";

const ProfileForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const selectedProfile = useSelector(
    (state) => state.profile.selectedProfileView
  );
  console.log("selectedProfile", selectedProfile);

  const userType = useSelector(
    (store) => store?.userManagement?.items?.user_details?.user_type
  );
  console.log("userType", userType);

  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector(
    (state) => state.userManagement.items.user_details
  );
  console.log("userData", userData);

  const [expandedGroups, setExpandedGroups] = useState({});
  const [departmentData, setDepartmentData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  console.log("usersData", usersData);

  const [selectedServices, setSelectedServices] = useState([]);
  const [counterData, setCounterData] = useState([]);
  console.log("counterData", counterData);

  const [profileData, setProfileData] = useState({
    user_id: null,
    branch_id: userData?.branch_id || null,
    department_id: null,
    counter_id: null,
    status: 1,
    user_profile_id: null,
  });

  useEffect(() => {
    console.log("Selected Profile Changed:", selectedProfile?.data);

    if (selectedProfile?.data) {
      const profile = selectedProfile.data;

      // Set profile data first
      setProfileData((prevData) => ({
        user_id: profile.user_details?.user_id,
        branch_id: profile.branch_details?.branch_id,
        department_id: profile.department_details?.department_id,
        counter_id: profile.counter_details?.counter_id,
        status: profile.status,
        user_profile_id: profile.user_profile_id,
      }));

      // Handle services
      if (Array.isArray(profile.svc_group_details)) {
        const services = [];
        const groupsToExpand = {};

        profile.svc_group_details.forEach((group) => {
          if (group.svc_layers && Array.isArray(group.svc_layers)) {
            group.svc_layers.forEach((service) => {
              services.push({
                service_id: service.service_id,
                service_name: service.service_name,
                svc_group_id: group.svc_group_id,
                status: service.status,
              });
              groupsToExpand[group.svc_group_id] = true;
            });
          }
        });

        setExpandedGroups(groupsToExpand);
        setSelectedServices(services);
      }
    }
  }, [selectedProfile]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch(LIST_DEPARTMENT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify({
            user_based_list: true,
            order_by: 1,
            keyword: "",
            inst_details: true,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch department data.");
        }

        const data = await response.json();
        setDepartmentData(data?.data || []);
      } catch (error) {
        console.error("Error fetching departments:", error);
        toast.error("Failed to load departments. Please try again.");
      }
    };

    fetchDepartments();
  }, [token, deviceId]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(USERS_LIST_INADMIN, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify({
            user_based_list: true,
            order_by: 1,
            keyword: "",
            inst_details: true,
            inst_id: userData?.inst_id,
            get_unlinked_list: !selectedProfile, // Only get unlinked list for new profiles
          }),
        });

        if (!response.ok) throw new Error("Network response was not ok");
        const responseData = await response.json();

        // If in edit mode, make sure to include the current user in the list
        if (selectedProfile?.data?.user_details) {
          const currentUser = selectedProfile.data.user_details;
          const userExists = responseData.data.some(
            (user) => user.user_id === currentUser.user_id
          );

          if (!userExists) {
            responseData.data.push(currentUser);
          }
        }

        setUsersData(responseData.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [token, deviceId, userData?.inst_id, selectedProfile]);

  useEffect(() => {
    const fetchCounters = async () => {
      try {
        const response = await fetch(COUNTER_LIST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify({
            get_unlinked_list: !selectedProfile,
            user_based_list: true,
            order_by: 1,
            keyword: "",
            inst_details: true,
            inst_id: userData?.inst_id,
            branch_id: userData?.branch_id,
          }),
        });

        if (!response.ok) throw new Error("Network response was not ok");
        const responseData = await response.json();

        // Include current counter if in edit mode
        if (selectedProfile?.data?.counter_details) {
          const currentCounter = selectedProfile.data.counter_details;
          const counterExists = responseData.data.some(
            (counter) => counter.counter_id === currentCounter.counter_id
          );

          if (!counterExists) {
            responseData.data.push(currentCounter);
          }
        }

        setCounterData(responseData.data);
      } catch (error) {
        console.error("Error fetching counters:", error);
      }
    };

    fetchCounters();
  }, [token, deviceId, userData?.inst_id, selectedProfile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]:
        name === "user_id" || name === "department_id" || name === "counter_id"
          ? Number(value)
          : value,
    }));
  };

  const handleStatusChange = (e) => {
    setProfileData((prev) => ({
      ...prev,
      status: e.target.checked ? 1 : 0,
    }));
  };

  const handleServicesChange = (newSelection) => {
    setSelectedServices(newSelection);
  };

  const validateForm = () => {
    if (!profileData.user_id) {
      toast.error("Please select a user");
      return false;
    }
    if (!profileData.department_id) {
      toast.error("Please select a department");
      return false;
    }
    if (!profileData.counter_id) {
      toast.error("Please select a counter");
      return false;
    }
    if (selectedServices.length === 0) {
      toast.error("Please select at least one service");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const endpoint = selectedProfile ? UPDATE_PROFILE : ADD_PROFILE;
    const method = id ? "POST" : "POST";

    try {
      const response = await fetch(endpoint, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          ...profileData,
          svc_layers: selectedServices,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(
          `Profile ${selectedProfile ? "updated" : "created"} successfully!`
        );
        navigate("/body/Profile");
      } else {
        toast.error(
          `Failed to ${id ? "update" : "create"} profile: ${
            responseData.message || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error(
        `Error while ${id ? "updating" : "creating"} profile:`,
        error
      );
      toast.error(
        `An error occurred while ${id ? "updating" : "creating"} the profile.`
      );
    }
  };

  return (
    <div className="h[80%] bg-gray-50 py-4">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          {selectedProfile ? "Update Profile" : "Create Profile"}
        </h1>

        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* Users Selection */}

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t("Users")}
              </label>
              {/* <select
                id="user_id"
                name="user_id"
                value={profileData.user_id || ""}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              >
                <option value="">{t("Select User")}</option>
                {userType === 3 && usersData?.map((user) => (                  
                  <option key={user?.user_id} value={user?.user_id}>
                    {user.username}
                  </option>
                ))}
              </select> */}
              <RenderSelect
                name="user_id"
                onChange={handleInputChange}
                value={profileData.user_id || ""}
                options={
                  userType === 3 &&
                  usersData?.length
                    ? usersData.map((user) => ({
                        id: user?.user_id,
                        name: user.username,
                      }))
                    : [{ id: "", name: "No users available" }]
                }
                fullWidth
                height="41px"
              />
            </div>

            {/* Department Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t("Select Department")}
              </label>
              <RenderSelect
                name="department_id"
                onChange={handleInputChange}
                value={profileData.department_id || ""}
                options={
                  userType === 3 && 
                  departmentData?.length
                    ? departmentData.map((dep) => ({
                        id: dep?.department_id,
                        name: dep.department_name,
                      }))
                    : [{ id: "", name: "No Department available" }]
                }
                fullWidth
                height="41px"
              />
            </div>

            {/* Counter Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t("Select Counter")}
              </label>
              <RenderSelect
                name="counter_id"
                onChange={handleInputChange}
                value={profileData.counter_id || ""}
                options={
                  userType === 3 && 
                  counterData?.length
                    ? counterData.map((counter) => ({
                        id: counter?.counter_id,
                        name: counter?.counter_name,
                      }))
                    : [{ id: "", name: "No Counter available" }]
                }
                fullWidth
                height="41px"
              />
            </div>

            {/* Status Toggle */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <div className="mt-2">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={profileData.status === 1}
                    onChange={handleStatusChange}
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                  <span className="ms-3 text-sm font-medium text-gray-700">
                    {profileData.status === 1 ? "Active" : "Inactive"}
                  </span>
                </label>
              </div>
            </div>
          </div>

          {/* Services Selection */}
          <div className="mt-8">
            <ServiceGroupsSelection
              selectedServices={selectedServices}
              onServiceChange={handleServicesChange}
              initialExpandedGroups={expandedGroups}
              key={selectedProfile?.data?.user_profile_id}
            />
          </div>

          {/* Action Buttons */}
          <div className="mt-8 flex gap-4">
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              {selectedProfile ? "Update Profile" : "Create Profile"}
            </Button>

            <Button
              variant="outlined"
              onClick={() => navigate("/body/profile")}
              sx={{
                borderColor: "#1976d2",
                color: "#1976d2",
                "&:hover": {
                  borderColor: "#1565c0",
                  backgroundColor: "rgba(25, 118, 210, 0.04)",
                },
              }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
