import { GET_PROFILE_BYID } from "../Utils/Constant";

export const getProfilleById = async (user_profile_id, token, deviceId, ) => {
    try {
      const response = await fetch(GET_PROFILE_BYID, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          user_profile_id: user_profile_id,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching profile by ID:', error);
      throw error;
    }
  };
  