import { createSlice } from "@reduxjs/toolkit";

const CounterSlice = createSlice({
  name: "counter",
  initialState: {
    items: null,
    isCollapse: false,
    counterView: [],
    selectedcounterView: [],
    selectedCounterDisplay: [],
    collectCounter:[]
  },
  reducers: {
    counterData: (state, action) => {
      state.items = action.payload; 
    },
    collectFetchCounter : (state, action) => {
     state.collectCounter = action.payload
    },
    setIsCollapse: (state, action) => {
      state.isCollapse = !state.isCollapse;
    },
    setCounterView: (state, action) => {
      state.counterView = action.payload;
    },
    setSelectedCounterView: (state, action) => {
      state.selectedcounterView = action.payload;
    },
    setSelectedCounterDisplay: (state, action) =>{
      state.selectedCounterDisplay = action.payload;
    },

    clearCounterData: (state) => {
      state.items = null;
      state.isCollapse = false;
      state.userView = [];
      state.selectedcounterView = [];
      state.selectedCounterDisplay = [];
      state.collectusers = []
    },
  },
});

// Export the actions for use in components
export const { counterData, setIsCollapse, setCounterView, setSelectedCounterView , selectedCounterDisplay, clearCounterData, collectFetchCounter} =
CounterSlice.actions;

// Export the reducer to be used in the store
export default CounterSlice.reducer;
