import React from 'react';

const KioskErrorState = () => {
  return (
    <div className="flex flex-col items-center justify-center p-8">
      <svg 
        viewBox="0 0 200 200" 
        className="w-48 h-48 mb-4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="100" cy="100" r="90" fill="#FEE2E2" />
        <path
          d="M100 40c33.137 0 60 26.863 60 60s-26.863 60-60 60-60-26.863-60-60 26.863-60 60-60zm0 10c-27.614 0-50 22.386-50 50s22.386 50 50 50 50-22.386 50-50-22.386-50-50-50zm0 20c2.761 0 5 2.239 5 5v25c0 2.761-2.239 5-5 5s-5-2.239-5-5V75c0-2.761 2.239-5 5-5zm0 45c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5z"
          fill="#EF4444"
        />
      </svg>
      <h3 className="text-xl font-semibold text-gray-800 mb-2">Oops! Something went wrong</h3>
      <p className="text-gray-600 text-center max-w-md">
        We're sorry for the inconvenience. Please try again later.
      </p>
    </div>
  );
};

export default KioskErrorState;