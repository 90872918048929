import React from 'react';

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="bg-red-500 text-white rounded-lg p-8 shadow-md">
        <h2 className="text-2xl font-bold mb-4">Error</h2>
        <p className="text-lg">Oops! Something didn’t work as expected. Please refresh the page or try again shortly.</p>
      </div>
    </div>
  );
};

export default Error;