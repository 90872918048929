import React, { useState, useEffect } from "react";
import Table from "../../CustomReusableComps/Table";
import { IconButton } from "@mui/material";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import {
  DELETE_TOKEN_CONFIG,
  Token_Config_List,
} from "../../../Utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ShimmerTable from "../../useShimmerTable";
import {
  collectFetchTokenConfig,
  setSelectedTokenConfigView,
} from "../../../Redux/TokenConfigSlice";

const TokenConfig = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    console.log(selectedRow);
  };

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(Token_Config_List, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          user_based_list: true,
          order_by: 1,
          keyword: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
      dispatch(collectFetchTokenConfig(responseData.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTokenConfig = async (rowData) => {
    setLoading(true);
    try {
      const response = await fetch(DELETE_TOKEN_CONFIG, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          id: rowData.config_id,
        }),
      });
      const responseData = await response.json();
      console.log("Delete response:", responseData);

      if (response.ok) {
        fetchData(currentPage, pageSize);
        toast.success("Token Configuration deleted successfully!");
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete Token Configuration: ${
            errorData.message || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error("Error deleting terminal:", error);
      toast.error("An error occurred while deleting the Token Configuration.");
    } finally {
      setLoading(false);
      handleCloseActions();
    }
  };
  const handleAddUserClick = () => {
    dispatch(setSelectedTokenConfigView(null));
    navigate("/body/createTokenConfig");
  };

  const handleEdit = (rowData) => {
    dispatch(setSelectedTokenConfigView(rowData));
    navigate("/body/createTokenConfig");
  };

  const handleView = (rowData) => {
    dispatch(setSelectedTokenConfigView(rowData));
    navigate("/body/viewTokenConfig");
  };

  const handleDelete = (rowData) => {
    setSelectedRow(rowData);
    handleDeleteTokenConfig(rowData);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    { key: "token_config_id", label: "Config Id" },
    { key: "token_start_value", label: t("Token Start Value") },
    { key: "token_prefix", label: t("Token Prefix") },
    {
      key: "status",
      label: t("Configuration Status"),
      content: (item) => (item.status === 1 ? t("Active") : t("In-Active")),
    },
    { key: "created_by", label: t("Created By") },
    { key: "created_time", label: t("Created Time") },
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" mb-4 flex justify-between items-center">
        <Button
          name={t("tokenConfig.Add_new_token_config")}
          onClick={handleAddUserClick}
        />
        <Breadcrumbs
          options={[{ name: "Token Config", pathName: "/body/tokenConfig" }]}
        />
      </div>
      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by name or email"
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      )}

      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        hideBlock={true}
      />
    </div>
  );
};

export default TokenConfig;
