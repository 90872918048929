import { createBrowserRouter } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Error from "../Utils/Error";
import Body from "../Body";
import Dashboard from "../Pages/Dashborad/Dashborad";
import Profile from "../Components/UserManagement/Profile/Profile";
import User from "../Components/UserManagement/User/User";
import Institution from "../Components/Settings/Insititution/Insititution";
import AddInsititution from "../Components/Settings/Insititution/AddInsititution";
import ViewInsititution from "../Components/Settings/Insititution/ViewInsititution";
import UserForm from "../Components/UserManagement/User/UserForm";
import EditInsititution from "../Components/Settings/Insititution/EditInsititution";
import AddUser from "../Components/UserManagement/User/AddUser";
// import Department from "../Components/Settings/Department/Department";
import AddDepartment from "../Components/Settings/Department/AddDepartment";
import ViewDepartment from "../Components/Settings/Department/ViewDepartment";
import EditDepartment from "../Components/Settings/Department/EditDepartment";
import AddBranch from "../Components/Settings/Branch/AddBranch";
import ViewBranch from "../Components/Settings/Branch/ViewBranch";
import EditBranch from "../Components/Settings/Branch/EditBranch";
import DeleteDepartment from "../Components/Settings/Department/DeleteDepartment";
import Token from "../Components/TokenManagement/Token";
import ViewUser from "../Components/UserManagement/User/UserView";
import LanguageSettings from "../Components/Settings/Language/LanguageSettings";
import DepartmentList from "../Components/Settings/Department/DepartmentList";
import Counter from "../Components/Settings/Counter/Counter";
import CounterForm from "../Components/Settings/Counter/CounterForm";
import AddCounter from "../Components/Settings/Counter/AddCounter";
import PasswordPolicyForm from "../Components/UserManagement/passwordPolicy/PasswordPolicyForm";
import PasswordPolicy from "../Components/UserManagement/passwordPolicy/PasswordPolicy";
import ViewCounter from "../Components/Settings/Counter/ViewCounter";
import BranchList from "../Components/Settings/Branch/BranchList";
import ServiceManagementForm from "../Components/Settings/Service/ServiceForm";
import ServiceList from "../Components/Settings/Service/ServiceList";
import CreateProfile from "../Components/UserManagement/Profile/CreateProfile";
import Terminals from "../Components/Settings/kiosk_terminal/Terminals";
import CreateTerminal from "../Components/Settings/kiosk_terminal/CreateTerminal";
import TerminalView from "../Components/Settings/kiosk_terminal/TerminalView";
import TokenConfig from "../Components/Settings/Token_Config/TokenConfig";
import CreateTokenConfig from "../Components/Settings/Token_Config/CreateTokenConfig";
import ViewTokenConfig from "../Components/Settings/Token_Config/ViewTokenConfig";
import ServiceView from "../Components/Settings/Service/ServiceView";
import ViewProfile from "../Components/UserManagement/Profile/ViewProfile";
import Modal from "../Components/CustomReusableComps/modal";
import ViewPP from "../Components/UserManagement/passwordPolicy/ViewPP";
import DataRetention from "../Components/Settings/DataRetention.jsx/DataRetention";
import TokenList from "../Components/TokenManagement/tokenList/TokensList";
import ViewQueueTokens from "../Components/UserManagement/ViewQueueTokens/ViewQueueTokens";
import CreateViewQueueTokens from "../Components/UserManagement/ViewQueueTokens/CreateViewQTokens";
import Kiosk from "../Kiosk/KioskUi";
import KioskErrorState from "../Kiosk/KioskErrorState";
import PanelDisplay from "../Components/TokenManagement/PanelDisplay";
import ProtectedRoute from "./ProtectedRoute";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/body",
    element: (
      <ProtectedRoute>
        <Body />
      </ProtectedRoute>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "tokenmanagement",
        element: (
          <ProtectedRoute>
            <Token />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "createProfile",
        element: (
          <ProtectedRoute>
            <CreateProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "viewProfile",
        element: (
          <ProtectedRoute>
            <ViewProfile />{" "}
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "user",
        element: (
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },

      {
        path: "createUser",
        element: (
          <ProtectedRoute>
            <UserForm />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "adduser",
        element: (
          <ProtectedRoute>
            <AddUser />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "viewuser",
        element: (
          <ProtectedRoute>
            <ViewUser />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "passwordPolicy",
        element: (
          <ProtectedRoute>
            <PasswordPolicy />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "PPForm",
        element: (
          <ProtectedRoute>
            <PasswordPolicyForm />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "institution",
        element: (
          <ProtectedRoute>
            <Institution />
          </ProtectedRoute>
        ),
      },

      {
        path: "addinstitution",
        element: (
          <ProtectedRoute>
            <AddInsititution />
          </ProtectedRoute>
        ),
      },
      {
        path: "viewinstitution",
        element: (
          <ProtectedRoute>
            <ViewInsititution />
          </ProtectedRoute>
        ),
      },
      {
        path: "editinstitution",
        element: (
          <ProtectedRoute>
            <EditInsititution />
          </ProtectedRoute>
        ),
      },
      {
        path: "departmentList",
        element: (
          <ProtectedRoute>
            <DepartmentList />
          </ProtectedRoute>
        ),
      },
      {
        path: "adddepartment",
        element: (
          <ProtectedRoute>
            <AddDepartment />
          </ProtectedRoute>
        ),
      },
      {
        path: "viewdepartment",
        element: (
          <ProtectedRoute>
            <ViewDepartment />
          </ProtectedRoute>
        ),
      },
      {
        path: "editdepartment",
        element: (
          <ProtectedRoute>
            <EditDepartment />
          </ProtectedRoute>
        ),
      },
      {
        path: "deletedepartment/:id",
        element: (
          <ProtectedRoute>
            <DeleteDepartment />
          </ProtectedRoute>
        ),
      },
      {
        path: "language",
        element: (
          <ProtectedRoute>
            <LanguageSettings />
          </ProtectedRoute>
        ),
      },
      {
        path: "service",
        element: (
          <ProtectedRoute>
            <ServiceList />
          </ProtectedRoute>
        ),
      },
      {
        path: "serviceForm",
        element: (
          <ProtectedRoute>
            <ServiceManagementForm />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "serviceView",
        element: (
          <ProtectedRoute>
            <ServiceView />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "branchlist",
        element: (
          <ProtectedRoute>
            <BranchList />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "addbranch",
        element: (
          <ProtectedRoute>
            <AddBranch />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "viewbranch",
        element: (
          <ProtectedRoute>
            <ViewBranch />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "editbranch",
        element: (
          <ProtectedRoute>
            <EditBranch />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "counter",
        element: (
          <ProtectedRoute>
            <Counter />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "counterform",
        element: (
          <ProtectedRoute>
            {" "}
            <CounterForm />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "addCounter",
        element: (
          <ProtectedRoute>
            <AddCounter />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "counterView",
        element: (
          <ProtectedRoute>
            <ViewCounter />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "terminals",
        element: (
          <ProtectedRoute>
            <Terminals />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "createTerminal",
        element: (
          <ProtectedRoute>
            <CreateTerminal />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "terminalView",
        element: (
          <ProtectedRoute>
            <TerminalView />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "tokenConfig",
        element: (
          <ProtectedRoute>
            <TokenConfig />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "createTokenConfig",
        element: (
          <ProtectedRoute>
            <CreateTokenConfig />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "viewTokenConfig",
        element: (
          <ProtectedRoute>
            <ViewTokenConfig />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "modal",
        element: (
          <ProtectedRoute>
            <Modal />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "ViewPP",
        element: (
          <ProtectedRoute>
            <ViewPP />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "data_retention",
        element: (
          <ProtectedRoute>
            <DataRetention />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "tokenList",
        element: (
          <ProtectedRoute>
            <TokenList />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "viewQueueTokens",
        element: (
          <ProtectedRoute>
            <ViewQueueTokens />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "createViewQueueTokens",
        element: (
          <ProtectedRoute>
            <CreateViewQueueTokens />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "kiosk",
        element: (
          <ProtectedRoute>
            <Kiosk />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "kioskErrorState",
        element: (
          <ProtectedRoute>
            <KioskErrorState />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
      {
        path: "panelDisplay",
        element: (
          <ProtectedRoute>
            <PanelDisplay />
          </ProtectedRoute>
        ),
        errorElement: <Error />,
      },
    ],
    future: {
      v7_fetcherPersist: true, // Enable the v7 fetcher persist flag
    },
  },
]);

export default appRouter;
