import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import Table from "../../CustomReusableComps/Table";
import ShimmerTable from "../../useShimmerTable";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { setSelectedPasswordPolicyView } from "../../../Redux/PasswordPolicy";
import { PP_DELETE, PP_LIST } from "../../../Utils/Constant";

const PasswordPolicy = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
     const userData = useSelector((state) => state.userManagement.items.user_details);
  
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    console.log(selectedRow);
  };

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(PP_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          inst_id: userData?.inst_id,
          order_by: 1,
          keyword: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTokenConfig = async (rowData) => {
    setLoading(true);
    try {
      const response = await fetch(PP_DELETE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          pwd_policy_id: rowData.pwd_policy_id,
        }),
      });
      const responseData = await response.json();
      console.log("Delete response:", responseData);

      if (response.ok) {
        fetchData(currentPage, pageSize);
        toast.success("Password Policy deleted successfully!");
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete Password Policy: ${
            errorData.message || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error("Error deleting terminal:", error);
      toast.error("An error occurred while deleting the Password Policy.");
    } finally {
      setLoading(false);
      handleCloseActions();
    }
  };
  const handleAddUserClick = () => {
    dispatch(setSelectedPasswordPolicyView(null));
    navigate("/body/PPForm");
  };

  const handleEdit = (rowData) => {
    dispatch(setSelectedPasswordPolicyView(rowData));
    navigate("/body/PPForm");
  };

  const handleView = (rowData) => {
    dispatch(setSelectedPasswordPolicyView(rowData));
    navigate("/body/ViewPP");
  };

  const handleDelete = (rowData) => {
    setSelectedRow(rowData);
    handleDeleteTokenConfig(rowData);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    { key: "pwd_policy_id", label: "Password Policy Id" },
    { key: "policy_name", label: t("Password policy Name") },
    {key: "status" , label : t("Active Status"), 
      content : (item) => item.status === true ? "Active" : "inactive"
    },
    { key: "min_length", label: t("Minimum length") },
    {
      key: "max_length",
      label: t("Maximum length"),
      content: (item) => (item.max_length || "-"),
    },
    { key: "specialcase_flag", label: t("Special Charact"),
      content : (item) => (item.specialcase_flag === true ? "yes" : "No")
     },
    { key: "uppercase_flag", label: t("Upper Case") ,
      content : (item) => item.uppercase_flag === true ? "yes" : "No"
    },
    { key: "numeric_flag", label: t("Numeric") ,
      content : (item) => item.numeric_flag === true ? "yes" : "No"
    },
    { key: "login_retry_count", label: t("Password Retry Count")},
    { key: "pwd_reset_count", label: t("Password Reset Count")},
    { key: "allowed_inactive_days", label: t("Inactive User Interval")},
    { key: "previous_pwd_restrict_count", label: t("Password History Restrict Count")},
    { key: "pwd_validity", label: t("Password Validity")},
    { key: "notify_before_pwd_expiry", label: t("Password Reset Alert (day)")},
    { key: "frequent_notification_flag", label: t("Set Notification Frequency"),
      content : (item) => item.notification_frequency === true ? "Yes" : "No"
    },
    { key: "notification_frequency", label: t("Notification Frequency Interval")},
     


    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" mb-4 flex justify-between items-center">
        <Button
          name={t("Add Password Policy")}
          onClick={handleAddUserClick}
        />
        <Breadcrumbs
          options={[{ name: "Password Policy", pathName: "/body/passwordPolicy" }]}
        />
      </div>
      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by name or email"
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      )}

      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        hideBlock={true}
      />
    </div>
  );
};

export default PasswordPolicy;
