import React from "react";
import { useSelector } from "react-redux";
import DepartmentForm from "./DepartmentForm";
import { EDIT_DEPARTMENT } from "../../../Utils/Constant";
import useEdit from "../../../Hooks/useEdit";

const EditDepartment = () => {
  const selectedDepartment = useSelector(
    (store) => store.setting.selectedDepartView
  );
  console.log(selectedDepartment);

  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);

  const { handleEditSubmit, isSubmitting } = useEdit(EDIT_DEPARTMENT);

  const handleSubmit = (payload) => {
    handleEditSubmit(payload, token, deviceId, "/body/departmentList");
  };

  console.log(handleSubmit);
  return (
    <div>
      <DepartmentForm
        onSubmit={handleSubmit}
        selectedDepartment={selectedDepartment}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default EditDepartment;
