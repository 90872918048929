import React from "react";
import CounterForm from "./CounterForm";
import { ADD_COUNTER } from "../../../Utils/Constant";
import useAdd from "../../../Hooks/useAdd";

const AddCounter = () => {
  const { handleAddSubmit, isSubmitting } = useAdd(
    ADD_COUNTER,
    "/body/counter"
  );
  console.log("Submitting form...");


  return (
    <div>
      <CounterForm onSubmit={handleAddSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default AddCounter;
