import { createSlice } from "@reduxjs/toolkit";

const PasswordPolicySlice = createSlice({
  name: "passwordPolicy",
  initialState: {
    passwordPolicyConfigView: [],
    selectedpasswordPolicyView: [],
  },
  reducers: {
    setpasswordPolicyView: (state, action) => {
      state.passwordPolicyConfigView = action.payload;
    },
    setSelectedPasswordPolicyView: (state, action) => {
      state.selectedpasswordPolicyView = action.payload;
    },
    clearTokenPasswordPolicyData: (state) => {
      state.passwordPolicyConfigView = [];
      state.selectedpasswordPolicyView = []
    },
  },
});

// Export the actions for use in components
export const {
    setpasswordPolicyView,
    setSelectedPasswordPolicyView,
    clearTokenPasswordPolicyData,
} = PasswordPolicySlice.actions;

// Export the reducer to be used in the store
export default PasswordPolicySlice.reducer;
