import React from "react";
import { Popover, Box, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import CloseIcon from "@mui/icons-material/Close";

const ActionPopover = ({
  anchorEl,
  open,
  handleClose,
  selectedRow,
  onEdit,
  onDelete,
  onView,
  hideEdit = false,
  hideDelete = false,
  hideView = false,
  hideBlock = false,
}) => {
  const popoverId = open ? "row-actions-popover" : undefined;

  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          minWidth: 130,
          position: "relative",
        }}
      >
        {/* Close Icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: 2,
            right: 2,
          }}
          onClick={handleClose}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>

        {!hideEdit && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              onEdit(selectedRow);
              handleClose();
            }}
          >
            <EditIcon sx={{ height: "20px", color: "primary.main" }} />
            <Typography variant="body1" sx={{ fontSize: 16 }}>
              Edit
            </Typography>
          </Box>
        )}

        {!hideView && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              onView(selectedRow);
              handleClose();
            }}
          >
            <VisibilityIcon sx={{ height: "20px", color: "text.secondary" }} />
            <Typography variant="body1" sx={{ fontSize: 16 }}>
              View
            </Typography>
          </Box>
        )}

        {!hideDelete && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              onDelete(selectedRow);
              handleClose();
            }}
          >
            <DeleteIcon sx={{ height: "20px", color: "error.main" }} />
            <Typography variant="body1" sx={{ fontSize: 16 }}>
              Delete
            </Typography>
          </Box>
        )}

        {!hideBlock && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              onView(selectedRow);
              handleClose();
            }}
          >
            <PersonOffIcon sx={{ height: "20px", color: "text.secondary" }} />
            <Typography variant="body1" sx={{ fontSize: 16 }}>
              Block
            </Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default ActionPopover;
