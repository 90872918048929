import { createSlice } from "@reduxjs/toolkit";

const TokenConfigSlice = createSlice({
  name: "tokenConfig",
  initialState: {
    items: null,
    isCollapse: false,
    tokenConfigView: [],
    selectedTokenConfigView: [],
    selectedTokenConfigDisplay: [],
    collectTokenConfig: [],
    trackActiveStatus: [],
  },
  reducers: {
    tokenConfigData: (state, action) => {
      state.items = action.payload;
    },
    collectFetchTokenConfig: (state, action) => {
      state.collectTokenConfig = action.payload;
    },
    setIsCollapse: (state, action) => {
      state.isCollapse = !state.isCollapse;
    },
    setTokenConfigView: (state, action) => {
      state.tokenConfigView = action.payload;
    },
    setSelectedTokenConfigView: (state, action) => {
      state.selectedTokenConfigView = action.payload;
    },
    setSelectedTokenConfigDisplay: (state, action) => {
      state.selectedCounterDisplay = action.payload;
    },
    setTrackActiveStatus: (state, action) => {
      state.trackActiveStatus = action.payload;
    },

    clearTokenConfigData: (state) => {
      state.items = null;
      state.isCollapse = false;
      state.tokenConfigView = [];
      state.selectedcounterView = [];
      state.selectedTokenConfigDisplay = [];
      state.collectTokenConfig = [];
    },
  },
});

// Export the actions for use in components
export const {
  tokenConfigData,
  setIsCollapse,
  setTokenConfigView,
  setSelectedTokenConfigView,
  setSelectedTokenConfigDisplay,
  clearTokenConfigData,
  collectFetchTokenConfig,
  setTrackActiveStatus,
} = TokenConfigSlice.actions;

// Export the reducer to be used in the store
export default TokenConfigSlice.reducer;
