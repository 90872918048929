import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
} from "recharts";
import {
  Clock,
  CheckCircle,
  PauseCircle,
  ArrowRightCircle,
  TrendingUp,
} from "lucide-react";
import { DashBoardTATApi, TOKENS_LIST } from "../../Utils/Constant";

const InstituteDashboard = () => {
  const [stats, setStats] = useState({
    issued: 0,
    completed: 0,
    held: 0,
    transferred: 0,
  });
  const [dasWiseTAT, setDasWiseTAT] = useState([]);
  const [loading, setLoading] = useState({
    tokens: true,
    dasTAT: true,
    serviceTAT: true,
  });
  const [error, setError] = useState({
    tokens: null,
    dasTAT: null,
    serviceTAT: null,
  });

  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector((state) => state.userManagement.items);
  const userType = useSelector(
    (store) => store?.userManagement?.items?.user_details?.user_type
  );

  const STATUSES = {
    ISSUED: "",
    COMPLETED: "Completed",
    HELD: "On Hold",
    TRANSFERRED: "Transfered",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${months[date.getMonth()]} ${date.getDate()}`;
  };

  const fetchTokenStats = async (status) => {
    try {
      const payload = {
        get_today_data: true,
        status: status,
        count_only: true,
      };
      const response = await fetch(TOKENS_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      return responseData.count;
    } catch (error) {
      console.error(`Error fetching ${status} tokens:`, error);
      return 0;
    }
  };

  const fetchDasTAT = async () => {
    try {
      const payload = {
        inst_id: userData?.user_details?.inst_id,
        day_based_list: true,
        no_of_days: 7,
      };
      const response = await fetch(DashBoardTATApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const daysTATData = await response.json();
      return daysTATData.data;
    } catch (error) {
      console.error(`Error fetching DaysData:`, error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading((prev) => ({ ...prev, tokens: true }));
      try {
        const [issued, completed, held, transferred] = await Promise.all([
          fetchTokenStats(STATUSES.ISSUED),
          fetchTokenStats(STATUSES.COMPLETED),
          fetchTokenStats(STATUSES.HELD),
          fetchTokenStats(STATUSES.TRANSFERRED),
        ]);

        setStats({
          issued,
          completed,
          held,
          transferred,
        });
      } catch (error) {
        setError((prev) => ({ ...prev, tokens: error.message }));
      } finally {
        setLoading((prev) => ({ ...prev, tokens: false }));
      }

      setLoading((prev) => ({ ...prev, dasTAT: true }));
      const dasData = await fetchDasTAT();
      setDasWiseTAT(dasData);
      setLoading((prev) => ({ ...prev, dasTAT: false }));
    };

    fetchAllData();

    const intervalId = setInterval(fetchAllData, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [
    token,
    deviceId,
    userData?.user_details?.profile_details?.branch_id,
    userType,
  ]);

  const chartData = [
    { name: "Issued", value: stats.issued, color: "#4CAF50" },
    { name: "Completed", value: stats.completed, color: "#2196F3" },
    { name: "Held", value: stats.held, color: "#FFC107" },
    { name: "Transferred", value: stats.transferred, color: "#FF5722" },
  ];

  const StatCard = ({ title, value, color, icon: Icon }) => (
    <div
      className={`bg-white rounded-xl p-5 flex items-center space-x-4 shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1`}
      style={{ borderTop: `4px solid ${color}` }}
    >
      <div
        className={`p-3 rounded-full`}
        style={{ backgroundColor: `${color}10` }}
      >
        <Icon size={24} color={color} />
      </div>
      <div className="flex-grow">
        <div className="text-sm font-medium text-gray-500">{title}</div>
        <div className="text-2xl font-bold" style={{ color }}>
          {value}
        </div>
      </div>
    </div>
  );

  const ChartSection = ({ title, loading, error, children, icon: Icon }) => (
    <div className="bg-white rounded-xl p-6 shadow-md hover:shadow-xl transition-all duration-300">
      <div className="flex items-center mb-4 space-x-3">
        {Icon && <Icon className="text-blue-500" size={24} />}
        <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
      </div>
      <div className="h-64">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <div className="animate-pulse text-gray-500">Loading data...</div>
          </div>
        ) : error ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-red-400">Error: {error}</div>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );

  return (
    <div className="p-6 bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-500">
              Queue Management System
            </h2>
            <p className="text-xl text-gray-400">Real-time Analytics</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <StatCard
            title="Issued Tokens"
            value={stats.issued}
            color="#4CAF50"
            icon={Clock}
          />
          <StatCard
            title="Completed Tokens"
            value={stats.completed}
            color="#2196F3"
            icon={CheckCircle}
          />
          <StatCard
            title="Held Tokens"
            value={stats.held}
            color="#FFC107"
            icon={PauseCircle}
          />
          <StatCard
            title="Transferred Tokens"
            value={stats.transferred}
            color="#FF5722"
            icon={ArrowRightCircle}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-8">
          <ChartSection
            title="Token Distribution"
            loading={loading.tokens}
            error={error.tokens}
            icon={TrendingUp}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {chartData?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                />
              </PieChart>
            </ResponsiveContainer>
          </ChartSection>
        </div>

        <div>
          <ChartSection
            title="Day-wise TAT (Last 7 Days)"
            loading={loading.dasTAT}
            error={error.dasTAT}
            icon={TrendingUp}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={dasWiseTAT?.map(({ transaction_date, avg_tat_mins }) => ({
                  date: formatDate(transaction_date),
                  averageTAT: avg_tat_mins,
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#E0E0E0" />
                <XAxis dataKey="date" stroke="#666" />
                <YAxis
                  stroke="#666"
                  domain={[0, 60]}
                  label={{
                    value: "Avg TAT (mins)",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ddd",
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="averageTAT"
                  stroke="#4CAF50"
                  strokeWidth={2}
                  dot={{ fill: "#4CAF50" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartSection>
        </div>
      </div>
    </div>
  );
};

export default InstituteDashboard;
