import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PANEL_DISPLAY } from "../../Utils/Constant";

const PanelDisplay = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector((state) => state.userManagement.items);
  const userType = useSelector(
    (store) => store?.userManagement?.items?.user_details?.user_type
  );

  // Function to parse concatenated JSON from stream
  const parseStreamData = (text) => {
    const results = [];
    let remaining = text;
    
    while (remaining.length > 0) {
      try {
        let openBraces = 0;
        let startPos = remaining.indexOf('{');
        if (startPos === -1) break;
        
        let endPos = -1;
        for (let i = startPos; i < remaining.length; i++) {
          if (remaining[i] === '{') openBraces++;
          if (remaining[i] === '}') openBraces--;
          if (openBraces === 0) {
            endPos = i + 1;
            break;
          }
        }
        
        if (endPos === -1) break;
        
        const jsonStr = remaining.substring(startPos, endPos);
        const parsedData = JSON.parse(jsonStr);
        results.push(parsedData);
        
        remaining = remaining.substring(endPos);
      } catch (err) {
        console.error("Error parsing JSON chunk:", err);
        break;
      }
    }
    
    return { results, remaining };
  };

  // Function to fetch and handle streaming data
  const fetchLiveStreamData = useCallback(async (signal) => {
    setLoading(true);
    setError(null);
    let buffer = '';

    try {
      const payload = {
        order_by: 0,
        branch_id: userData?.user_details?.profile_details?.branch_id,
        get_today_data: true,
        active_only: true,
        user_based_list: false,
        get_unlinked_list: false,
        token_based_list: true,
        profile_based_list: true,
      };

      const response = await fetch(PANEL_DISPLAY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
          "Request-Type": "Live-Stream",
        },
        body: JSON.stringify(payload),
        signal,
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        
        const { results, remaining } = parseStreamData(buffer);
        buffer = remaining;

        results.forEach(parsedData => {
          parsedData.data.forEach((counter) => {
            setData(prevData => {
              const existingIndex = prevData.findIndex(
                item => item?.counter_id === counter?.counter_id
              );

              if (existingIndex !== -1) {
                const newData = [...prevData];
                newData[existingIndex] = counter;
                return newData;
              } else {
                return [...prevData, counter];
              }
            });

            setTotalCount(parsedData.count);
          });
        });
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Stream aborted");
      } else {
        console.error("Stream error:", error);
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [token, deviceId, userData, userType]);

  useEffect(() => {
    const controller = new AbortController();
    fetchLiveStreamData(controller.signal);
    return () => controller.abort();
  }, [fetchLiveStreamData]);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <div className="h-screen bg-black text-white flex flex-col">
      {/* Compact Header */}
      <div className="bg-blue-900 py-2">
        <div className="container mx-auto flex justify-between items-center px-4">
          <h1 className="text-2xl font-bold">TOKEN DISPLAY SYSTEM</h1>
          <div className="text-xl font-bold">{formatTime(currentTime)}</div>
        </div>
      </div>

      {/* Compact Column Headers */}
      <div className="bg-blue-800 py-2 border-b border-blue-600">
        <div className="container mx-auto grid grid-cols-2 text-center">
          <div className="text-lg font-bold">{t("COUNTER")}</div>
          <div className="text-lg font-bold">{t("NOW SERVING")}</div>
        </div>
      </div>

      {/* Counter Rows Container - Fixed Height */}
      <div className="flex-1 container mx-auto overflow-hidden">
        <div className="grid grid-rows-10 h-full">
          {data.map((counter, index) => (
            <div 
              key={counter?.counter_id}
              className={`grid grid-cols-2 items-center ${
                index % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800'
              } border-b border-gray-700`}
            >
              {/* Counter Name */}
              <div className="text-center py-1">
                <div className="text-xl font-bold text-yellow-400">
                  {counter.counter_name}
                </div>
              </div>

              {/* Current Token */}
              <div className="text-center">
                <div className="inline-block bg-green-900 rounded px-4 py-1">
                  <div className="text-2xl font-bold text-green-400">
                    {counter.current_Token || "-"}
                  </div>
                </div>
              </div>
            </div>
          ))}
          
          {/* Fill empty rows if less than 10 counters */}
          {[...Array(Math.max(0, 10 - data.length))].map((_, index) => (
            <div 
              key={`empty-${index}`}
              className={`grid grid-cols-2 items-center ${
                (index + data.length) % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800'
              } border-b border-gray-700`}
            >
              <div className="text-center py-1">
                <div className="text-xl font-bold text-gray-700">-</div>
              </div>
              <div className="text-center">
                <div className="inline-block bg-gray-700 rounded px-4 py-1">
                  <div className="text-2xl font-bold text-gray-600">-</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Loading Indicator */}
      {loading && (
        <div className="fixed bottom-0 left-0 right-0 bg-blue-600 text-white text-center py-1 text-sm">
          Updating counter information...
        </div>
      )}

      {/* Error Display */}
      {error && (
        <div className="fixed bottom-0 left-0 right-0 bg-red-600 text-white text-center py-1 text-sm">
          Error: {error}
        </div>
      )}
    </div>
  );
};

export default PanelDisplay;