import React, { useState, useEffect } from "react";
import Table from "../CustomReusableComps/Table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ShimmerTable from "../useShimmerTable";
import { HOLD_TOKEN_LIST, SERVE_HELD_TOKEN } from "../../Utils/Constant";
import { toast } from "react-toastify";

const RecallTokenList = ({ onClose, onServeToken }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userData = useSelector((state) => state.userManagement.items);

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(HOLD_TOKEN_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          status: "On Hold",
          profile_based_list : true,
          branch_id: userData?.user_details?.profile_details?.branch_id,
          order_by: 1,
          get_today_data : true
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch held tokens");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const serveHeldToken = async (token_id) => {
    try {
      const response = await fetch(SERVE_HELD_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          token_id: token_id,
        }),
      });

      if (response.message === "Invalid user") {
        toast.success("You are not the valid person to recall this token");
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      onServeToken(data); // Pass the response data to parent
      onClose(); // Close the modal
      toast.success("Token Recalled successfully");
    } catch (error) {
      console.error("Error serving token:", error);
      toast.error("Failed to serve token");
    }
  };

  const headers = [
    { key: "token_id", label: "Token Id" },
    { key: "token", label: t("Token") },
    {
      key: "status",
      label: t("Token Status"),
    },
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <button
            onClick={() => serveHeldToken(rowData.token_id)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Serve
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by token name "
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      )}
    </div>
  );
};

export default RecallTokenList;
