import { SERVICE_GET_BYID } from "../Utils/Constant";

export const getServiceById = async (svc_group_id, token, deviceId, profile_based_fetch) => {
    try {
      const response = await fetch(SERVICE_GET_BYID, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
            svc_group_id: svc_group_id,
            profile_based_fetch: profile_based_fetch
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Service by ID:', error);
      throw error;
    }
  };
  