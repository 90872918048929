import React, { useState, useEffect } from "react";
import Table from "../../CustomReusableComps/Table";
import { IconButton } from "@mui/material";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import { PROFILE_LIST, DELETE_PROFILE } from "../../../Utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ShimmerTable from "../../useShimmerTable";
import { setSelectedProfileView } from "../../../Redux/ProfileSlice";
import { getProfilleById } from "../../../Api_Services/profileApi";
const Counter = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userManagement.items);
  console.log(userData);
  const selectedProfileData = useSelector(
    (state) => state.profile.selectedProfileView
  );
  console.log("selectedProfileData", selectedProfileData);

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    console.log(selectedRow);
  };

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(PROFILE_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          user_based_list: true,
          order_by: 1,
          keyword: "",
          inst_details: true,
          inst_id: userData?.user_details?.inst_id,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
      //   dispatch(collectFetchUsers(responseData.data))
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddUserClick = () => {
    dispatch(setSelectedProfileView(null));
    navigate("/body/createProfile");
  };

  const handleEdit = async (user_profile_id) => {
    try {
      const profileData = await getProfilleById(
        user_profile_id,
        token,
        deviceId
      );
      dispatch(setSelectedProfileView({ ...profileData }));
      navigate("/body/createProfile");
    } catch (error) {
      console.log("Failed to fetch profile details", error);
    }
  };

  const handleView = (rowData) => {
    dispatch(setSelectedProfileView(rowData));
    navigate("/body/viewProfile");
  };

  const handleDelete = (rowData) => {
    console.log("Delete clicked for row:", rowData);
    setSelectedRow(rowData);
    handleDeleteCounter(rowData);
  };

  const handleDeleteCounter = async (rowData) => {
    if (!rowData || !rowData.user_profile_id) {
      toast.error("Invalid Profile data for deletion.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(DELETE_PROFILE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          user_profile_id: rowData.user_profile_id,
        }),
      });
      const responseData = await response.json();
      console.log("Delete response:", responseData);

      if (response.ok) {
        fetchData(currentPage, pageSize);
        toast.success("Profile deleted successfully!");
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete profile: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting the user.");
    } finally {
      setLoading(false);
      handleCloseActions();
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    { key: "user_profile_id", label: "Profile Id" },
    {
      key: "user_details.username",
      label: t("User Name"),
      content: (item) => item.user_details?.username || "",
    },
    {
      key: "department_details.department_name",
      label: t("Department Name"),
      content: (item) => item.department_details?.department_name || "",
    },
    // {
    //   key: "svc_grp_details?.[0]?.svc_group_name",
    //   label: t("Service Name"),
    //   content: (item) => item.svc_grp_details?.[0]?.svc_group_name || "",
    // },
    {
      key: "counter_details.counter_name",
      label: t("Counter Name"),
      content: (item) => item.counter_details?.counter_name || "",
    },
    {
      key: "status",
      label: t("Profile Status"),
      content: (item) => (item?.status === 1 ? t("Active") : t("In-Active")),
    },
    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" mb-4 flex justify-between items-center">
        <Button name="Add Profile" onClick={handleAddUserClick} />
        <Breadcrumbs
          options={[{ name: "Profile", pathName: "/body/profile" }]}
        />
      </div>

      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by name or email"
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      )}

      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={() => handleEdit(selectedRow?.user_profile_id)}
        onView={handleView}
        onDelete={handleDelete}
        hideBlock={true}
      />
    </div>
  );
};

export default Counter;
