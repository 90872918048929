import React from "react";
import InsititutionForm from "./InsititutionForm";
import { ADD_INSTITUTION } from "../../../Utils/Constant";
import useAdd from "../../../Hooks/useAdd";

const AddInsititution = () => {
  const { handleAddSubmit, isSubmitting } = useAdd(
    ADD_INSTITUTION,
    "/body/institution"
  );

  return (
    <div>
      <InsititutionForm
        onSubmit={handleAddSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default AddInsititution;
