import { GET_USER_BYID } from "../Utils/Constant";

export const getUserById = async (user_id, decrypt_pwd, token, deviceId) => {
    try {
      const response = await fetch(GET_USER_BYID, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          user_id: user_id,       // Pass only the user_id (a primitive value)
          decrypt_pwd: decrypt_pwd,  // Pass decrypt_pwd as true
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();  // Parse the response as JSON
      console.log('Fetched user data:', data);  // For debugging
      
      return data;  // Return the user data
  
    } catch (error) {
      console.error("Error fetching Users by ID:", error);
      throw error;  // Re-throw the error for further handling
    }
  };
  
