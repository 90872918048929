import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';

const SuccessPopup = ({ isVisible, onClose, tokenData }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="bg-white w-96 py-6 px-6 rounded-xl shadow-2xl relative text-center overflow-hidden">
        <button 
          className="absolute top-2 right-2 text-gray-600 hover:text-black transition"
          onClick={onClose}
        >
          <IoCloseOutline className="h-8 w-8" />
        </button>

        {/* Floating Flowers Container */}
        <div className="relative h-20 mb-4">
          {/* Floating Flower Animation */}
          <div className="absolute left-0 bottom-0 animate-[flower-1_5s_ease-in-out_infinite]">
            <div className="w-12 h-12 bg-pink-400 rounded-full"></div>
          </div>
          
          <div className="absolute right-0 bottom-2 animate-[flower-2_5.5s_ease-in-out_infinite]">
            <div className="w-12 h-12 bg-purple-400 rounded-full"></div>
          </div>
          
          <div className="absolute left-6 bottom-0 animate-[flower-4_5.3s_ease-in-out_infinite]">
            <div className="w-12 h-12 bg-yellow-400 rounded-full"></div>
          </div>
          
          <div className="absolute right-6 bottom-2 animate-[flower-4_4.7s_ease-in-out_infinite]">
            <div className="w-12 h-12 bg-blue-400 rounded-full"></div>
          </div>
        </div>

        {/* Centered Content */}
        <h2 className="text-3xl font-semibold text-green-600 mb-4">
          Token Issued Successfully!
        </h2>
        <p className="text-gray-800 text-lg font-medium">Token: <span className="font-bold">{tokenData?.token}</span></p>
        <p className="text-gray-600 text-sm mt-2">{tokenData?.description}</p>

        <button
          className="mt-4 bg-green-600 text-white px-8 py-3 rounded-full hover:bg-green-500 transition"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessPopup;
