import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Pagination, Select } from "antd";
import SearchBar from "../../Components/SearchBar";
import useSortIcon from "../../Components/useSortIcon";

const Table = ({
  data = [],
  headers,
  filterOptions = [], // [{ key: 'status', label: 'Status', options: [{value: 'active', label: 'Active'}, ...] }]
  rowActions = [],
  searchPlaceholder,
  totalRowsLabel,
  loading,
  currentPage,
  totalItems,
  onPageChange,
  pageSize: propPageSize,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(propPageSize || 10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeFilters, setActiveFilters] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (propPageSize) {
      setRowsPerPage(propPageSize);
    }
  }, [propPageSize]);

  // Initialize activeFilters based on filterOptions with proper type checking
  useEffect(() => {
    const initialFilters = {};
    if (Array.isArray(filterOptions)) {
      filterOptions.forEach((filter) => {
        if (filter && filter.key) {
          initialFilters[filter.key] = "all";
        }
      });
    }
    setActiveFilters(initialFilters);
  }, [filterOptions]);

  const handleSearchChange = (query) => setSearchQuery(query);

  const handleFilterChange = (filterKey, value) => {
    setActiveFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const handlePageChange = (page, pageSize) => {
    if (onPageChange) {
      onPageChange(page, pageSize);
    }
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const SortIconComponent = (column) => {
    const SortIcon = useSortIcon(sortColumn, sortOrder, column);
    return <SortIcon />;
  };

  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    let result = data?.filter((item) => {
      // Apply search filter
      const matchesSearch = Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      // Apply all active filters
      const matchesFilters = Object.entries(activeFilters).every(
        ([key, value]) => {
          if (!value || value === "all") return true;
          return item[key]?.toString() === value?.toString();
        }
      );

      return matchesSearch && matchesFilters;
    });

    // Apply sorting
    if (sortColumn) {
      result = result.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortOrder === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }

    return result;
  }, [data, searchQuery, sortColumn, sortOrder, activeFilters]);

  // Ensure filterOptions is an array before rendering filters
  const safeFilterOptions = Array.isArray(filterOptions) ? filterOptions : [];

  return (
    <div className="bg-color-white border shadow-md p-2 mt-2 rounded-md font-roboto text-nowrap">
      <div className="mt-2 flex flex-col justify-start items-start">
        <div className="flex justify-between items-center mb-4 w-full">
          <div className="flex items-center gap-4 flex-wrap">
            <SearchBar
              placeholder={searchPlaceholder || t("search")}
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
            />
            {safeFilterOptions.map((filter) => {
              // Only render filter if it has the required properties
              if (!filter || !filter.key || !Array.isArray(filter.options)) {
                return null;
              }

              return (
                <div key={filter.key} className="flex items-center gap-2">
                  <span className="text-sm text-gray-600">
                    {t(filter.label || filter.key)}:
                  </span>
                  <Select
                    value={activeFilters[filter.key]}
                    onChange={(value) => handleFilterChange(filter.key, value)}
                    style={{ width: 120 }}
                    options={[
                      { value: "all", label: t("All") },
                      ...filter.options.map((option) => ({
                        value: option.value,
                        label: t(option.label),
                      })),
                    ]}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="relative w-full max-h-[28rem] overflow-x-auto overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-gray-300 scrollbar-track-transparent hover:scrollbar-thumb-gray-500">
          {loading ? (
            <div className="text-center py-4">{t("Loading...")}</div>
          ) : (
            <table className="w-full divide-y h-full divide-color-gray-200 table-auto">
              <thead className="bg-color-dim-gray">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      onClick={() => handleSort(header.key)}
                      className="text-base font-bold border text-color-header-dark-gray tracking-wider p-4 sticky top-0 bg-color-dim-gray z-10"
                      style={
                        header.key === "action"
                          ? { position: "sticky", right: 0 }
                          : {}
                      }
                    >
                      <div className="flex justify-center items-center">
                        {t(header.label)}
                        <span>{SortIconComponent(header.key)}</span>
                      </div>
                    </th>
                  ))}
                  {rowActions.length > 0 && (
                    <th className="text-base font-bold border text-color-header-dark-gray tracking-wider w-32 px-8 sticky top-0 bg-color-dim-gray z-10">
                      {t("UserPage.actions")}
                    </th>
                  )}
                </tr>
              </thead>
              {filteredData.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan={headers.length + (rowActions.length > 0 ? 1 : 0)}
                      className="text-center text-color-dark-gray py-4"
                    >
                      {t("no data")}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.id}>
                      {headers.map((header, i) => (
                        <td
                          key={i}
                          className="text-nowrap border text-center text-[15px] text-color-dark-gray p-4"
                          style={
                            header.key === "action"
                              ? {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#f3f8fc",
                                  zIndex: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }
                              : {}
                          }
                        >
                          {header.content
                            ? header.content(item)
                            : item[header.key]}
                        </td>
                      ))}
                      {rowActions.length > 0 && (
                        <td className="whitespace-nowrap text-sm border text-color-dark-gray bg-color-white sticky top-0 right-0">
                          {rowActions.map((action, actionIndex) => (
                            <button
                              key={actionIndex}
                              onClick={() => action.handler(item)}
                              className="text-sm text-color-link mr-2"
                            >
                              {t(action.label)}
                            </button>
                          ))}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>

        <Pagination
          size="small"
          current={currentPage}
          total={totalItems}
          pageSize={rowsPerPage}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={["10", "20", "50", "100"]}
          onChange={handlePageChange}
          showTotal={(total) => t(totalRowsLabel, { count: total })}
          className="mt-4 ml-auto font-roboto"
        />
      </div>
    </div>
  );
};

export default Table;
