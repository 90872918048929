import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CounterDashBoard from "./CounterDashboard";
import BranchDashboard from "./BranchDashBoard";
import InstituteDashboard from "./InstituteDashBoard";
import MainAdminDashboard from "./MainAdminDashboard";

const Dashborad = () => {
  const userType = useSelector(
    (store) => store?.userManagement?.items?.user_details?.user_type
  );
  useEffect(() => {
    // Push the current path to history to trap the back button
    window.history.pushState(null, "", window.location.href);

    const handleBackNavigation = () => {
      window.history.pushState(null, "", window.location.href); // Override back action
    };

    window.addEventListener("popstate", handleBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, []);

  return (
    <div>
      {userType === 4 && <CounterDashBoard />}
      {userType === 3 && <BranchDashboard />}
      {userType === 2 && <InstituteDashboard />}
      {userType === 1 && <MainAdminDashboard/>}
    </div>
  );
};

export default Dashborad;
