import { createSlice } from "@reduxjs/toolkit";

const TerminalSlice = createSlice({
  name: "terminal",
  initialState: {
    items: null,
    isCollapse: false,
    terminalView: [],
    selectedTerminalView: [],
    selectedTerminalDisplay: [],
    collectTerminal:[]
  },
  reducers: {
    terminalData: (state, action) => {
      state.items = action.payload; 
    },
    collectFetchTerminal : (state, action) => {
     state.terminalView = action.payload
    },
    setIsCollapse: (state, action) => {
      state.isCollapse = !state.isCollapse;
    },
    setTerminalView: (state, action) => {
      state.terminalView = action.payload;
    },
    setSelectedTerminalView: (state, action) => {
      state.selectedTerminalView = action.payload;
    },
    setSelectedTerminalDisplay: (state, action) =>{
      state.selectedTerminalDisplay = action.payload;
    },

    clearTerminalData: (state) => {
      state.items = null;
      state.isCollapse = false;
      state.terminalView = [];
      state.selectedTerminalView = [];
      state.selectedTerminalDisplay = [];
      state.collectTerminal = []
    },
  },
});

// Export the actions for use in components
export const { terminalData, setIsCollapse, setTerminalView, setSelectedTerminalView , setSelectedTerminalDisplay, clearTerminalData, collectFetchTerminal} =
TerminalSlice.actions;

// Export the reducer to be used in the store
export default TerminalSlice.reducer;
