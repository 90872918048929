import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../CustomReusableComps/InputField";
import { toast } from "react-toastify";
import { EDIT_USER, PP_LIST } from "../../../Utils/Constant";
import { setSelectedUserView } from "../../../Redux/UserManagementSlice";
import { FiEye, FiEyeOff } from "react-icons/fi";
import ToggleButton from "../../CustomReusableComps/ToggleButton";
import RenderSelect from "../../MUI_Reusable_Comps/RenderSelect";

const UserForm = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux selectors
  const instView = useSelector((state) => state.setting.instView);
  console.log("instView", instView);

  const selectedUserData = useSelector(
    (state) => state?.userManagement?.selecteduserView?.data
  );
  console.log("selectedUserDataaaaaaaaaa", selectedUserData);

  const userData = useSelector((state) => state.userManagement.items);
  console.log("userData", userData);

  const brachlist = useSelector((state) => state.setting.branchView);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const userType = useSelector(
    (store) => store?.userManagement?.items?.user_details?.user_type
  );

  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordPolicyData, setPasswordPolicyData] = useState([]);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    pwd: "",
    mobile_number: "",
    alternate_mob_number: "",
    email: "",
    alternate_email: "",
    gender: "",
    inst_id: userData?.user_details?.inst_id || null,
    user_type:
      userType === 1 ? 2 : userType === 2 ? 3 : userType === 3 ? 4 : "",
    branch_id: userData?.user_details?.branch_id || null,
    status: null,
  });

  useEffect(() => {
    if (!selectedUserData) {
      dispatch(setSelectedUserView(null));
    }
  }, [dispatch, selectedUserData]);
  useEffect(() => {
    const fetchData = async (page = 1, pageSize = 10) => {
      try {
        const response = await fetch(PP_LIST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            DeviceID: deviceId,
          },
          body: JSON.stringify({
            page: page,
            no_of_data: pageSize,
            inst_id: userData?.inst_id,
            order_by: 1,
            keyword: "",
            active_only: true,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        setPasswordPolicyData(responseData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [token, deviceId, userData?.inst_id]);

  useEffect(() => {
    if (selectedUserData) {
      setFormData({
        user_id: selectedUserData.user_id,
        firstname: selectedUserData.firstname || "",
        lastname: selectedUserData.lastname || "",
        pwd: selectedUserData.pwd || "",
        mobile_number: selectedUserData.mobile_number || "",
        alternate_mob_number: selectedUserData.alternate_mob_number || "",
        email: selectedUserData.email || "",
        alternate_email: selectedUserData.alternate_email || "",
        inst_id: selectedUserData.inst_id || null,
        branch_id: selectedUserData.branch_id || null,
        user_type: selectedUserData.user_type || null,
        gender: selectedUserData.gender,
        status: selectedUserData.status || null,
        pwd_policy_id: selectedUserData.pwd_policy_id || null,
      });
    }
  }, [selectedUserData]);

  const handleEditUser = async () => {
    try {
      // Create payload object excluding pwd field initially
      const payload = {
        user_id: selectedUserData.user_id,
        firstname: formData.firstname || "",
        lastname: formData.lastname || "",
        mobile_number: formData.mobile_number || "",
        alternate_mob_number: formData.alternate_mob_number || "",
        email: formData.email || "",
        alternate_email: formData.alternate_email || "",
        inst_id: formData.inst_id || null,
        branch_id: formData.branch_id || null,
        user_type: formData.user_type || null,
        gender: formData.gender || "",
        status: formData.status,
        pwd_policy_id: formData.pwd_policy_id || null,
      };

      // Only add pwd to payload if it's been changed
      if (formData.pwd !== selectedUserData.pwd) {
        payload.pwd = formData.pwd;
      }

      const response = await fetch(EDIT_USER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success("User updated successfully!");
        dispatch(setSelectedUserView(null));
        setFormData({
          firstname: "",
          lastname: "",
          pwd: "",
          mobile_number: "",
          alternate_mob_number: "",
          email: "",
          alternate_email: "",
          inst_id: null,
          user_Type: null,
          gender: "",
          pwd_policy_id: "",
        });
        dispatch(setSelectedUserView(null));
        navigate("/body/user");
      } else {
        toast.error(
          `Failed to update user: ${responseData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("An error occurred while updating the user.");
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{6,12}$/;
    return phoneRegex.test(phone);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile_number" || name === "alternate_mob_number") {
      const numbersOnly = value.replace(/\D/g, "");
      setFormData((prev) => ({
        ...prev,
        [name]: numbersOnly.slice(0, 12),
      }));
    } else if (name === "pwd_policy_id") {
      setFormData((prev) => ({
        ...prev,
        [name]: value ? parseInt(value, 10) : null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleInstitutionChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);

    setFormData((prev) => ({
      ...prev,
      inst_id:
        userType === 1
          ? selectedId
          : prev.inst_id || userType === 2
          ? userData?.user_details?.inst_id
          : "",
      branch_id: userType === 2 ? selectedId : prev.branch_id,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const hasPhoneError =
      formData.mobile_number && !validatePhone(formData.mobile_number);
    const hasEmailError = formData.email && !validateEmail(formData.email);
    const hasAltPhoneError =
      formData.alternate_mob_number &&
      !validatePhone(formData.alternate_mob_number);
    const hasAltEmailError =
      formData.alternate_email && !validateEmail(formData.alternate_email);

    if (
      hasPhoneError ||
      hasEmailError ||
      hasAltPhoneError ||
      hasAltEmailError
    ) {
      return;
    }

    if (selectedUserData) {
      handleEditUser(formData);
    } else if (onSubmit) {
      onSubmit(formData);
    }
  };

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, status: prev.status === 0 ? 1 : 0 }));
  };

  const firstNameLabel =
    formSubmitted && !formData?.firstname ? (
      <div className="text-red-500 mt-1">{t("First Name *")}</div>
    ) : (
      t("First Name *")
    );
  const passwordLabel =
    formSubmitted && !formData?.pwd ? (
      <div className="text-red-500 mt-1">{t("Password *")}</div>
    ) : (
      t("Password *")
    );
  const phnLabel = formSubmitted ? (
    !formData?.mobile_number ? (
      <div className="text-red-500 mt-1">{t("Phone Number *")}</div>
    ) : !validatePhone(formData.mobile_number) ? (
      <div className="text-red-500 mt-1">
        {t("Enter 6-12 digit phone number *")}
      </div>
    ) : (
      t("Phone Number *")
    )
  ) : (
    t("Phone Number *")
  );
  const altPhnLabel =
    formSubmitted &&
    formData?.alternate_mob_number &&
    !validatePhone(formData.alternate_mob_number) ? (
      <div className="text-red-500 mt-1">
        {t("Enter 6-12 digit phone number")}
      </div>
    ) : (
      t("Alternate Mobile Number")
    );
  const emailLabel = formSubmitted ? (
    !formData?.email ? (
      <div className="text-red-500 mt-1">{t("E-mail *")}</div>
    ) : !validateEmail(formData.email) ? (
      <div className="text-red-500 mt-1">
        {t("Enter valid email address *")}
      </div>
    ) : (
      t("E-mail *")
    )
  ) : (
    t("E-mail *")
  );

  const altEmailLabel =
    formSubmitted &&
    formData?.alternate_email &&
    !validateEmail(formData.alternate_email) ? (
      <div className="text-red-500 mt-1">{t("Enter valid email address")}</div>
    ) : (
      t("Alternate E-mail")
    );

  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold font-sans">
        {userType === 1
          ? selectedUserData === "Edit User"
            ? "Edit User"
            : "Add Institution User"
          : null}
        {userType === 2 && "Add Branch User"}
        {userType === 3 && "Add Counter User"}
      </h1>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <div>
              <InputField
                name="firstname"
                label={firstNameLabel}
                placeholder={t("Enter first name")}
                value={formData.firstname}
                onChange={handleInputChange}
                className="w-full"
              />
            </div>
            <InputField
              name="lastname"
              label={t("Last Name")}
              placeholder={t("Enter last name")}
              value={formData.lastname}
              onChange={handleInputChange}
              className="w-full"
            />
            <div className="relative w-full">
              <InputField
                name="pwd"
                label={passwordLabel}
                type={showPassword ? "text" : "password"}
                placeholder={t("Enter your password")}
                value={formData.pwd}
                onChange={handleInputChange}
                className="w-full"
              />
              <div
                className="absolute right-3 top-1/2 transform -translate-y-1/7 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="gender"
                className="block mb-2 font-medium text-gray-700"
              >
                {t("Gender *")}
              </label>
              <RenderSelect
                name="gender"
                onChange={handleInputChange}
                value={formData.gender}
                options={[
                  { id: "male", name: t("Male") },
                  { id: "female", name: t("Female") },
                  { id: "trans", name: t("Transgender") },
                ]}
                fullWidth
                height= "41px"
              />
            </div>

            <InputField
              name="mobile_number"
              label={phnLabel}
              placeholder={t("Enter your phone number")}
              value={formData.mobile_number}
              onChange={handleInputChange}
              className="w-full"
              maxLength={12}
            />
            <InputField
              name="alternate_mob_number"
              label={altPhnLabel}
              placeholder={t("Enter alternate phone number")}
              value={formData.alternate_mob_number}
              onChange={handleInputChange}
              className="w-full"
              maxLength={12}
            />

            <InputField
              name="email"
              type="Email"
              label={emailLabel}
              placeholder={t("Enter your email")}
              value={formData.email}
              onChange={handleInputChange}
              className="w-full"
            />

            <InputField
              name="alternate_email"
              type="email"
              label={altEmailLabel}
              placeholder={t("Enter alternate email")}
              value={formData.alternate_email}
              onChange={handleInputChange}
              className="w-full"
            />
            <div className="w-full">
              {userType !== 3 && (
                <>
                  <label
                    htmlFor="institution"
                    className="block mb-2 font-medium text-gray-700"
                  >
                    {userType === 1
                      ? t("Select Institution *")
                      : t("Select Branch *")}
                  </label>
                  <select
                    id="institution"
                    name={userType === 1 ? "inst_id" : "branch_id"}
                    value={
                      userType === 1
                        ? formData.inst_id || ""
                        : formData.branch_id || ""
                    }
                    onChange={handleInstitutionChange}
                    className="block w-full p-3 border border-gray-300 rounded"
                  >
                    <option value="" disabled>
                      {userType === 1
                        ? t("Select Institution")
                        : t("Select Branch")}
                    </option>
                    {userType === 1 &&
                      instView?.map((institution) => (
                        <option
                          key={institution?.inst_id}
                          value={institution?.inst_id}
                        >
                          {institution?.inst_name}
                        </option>
                      ))}
                    {userType === 2 &&
                      brachlist?.map((branch) => (
                        <option
                          key={branch?.branch_id}
                          value={branch?.branch_id}
                        >
                          {branch?.branch_name}
                        </option>
                      ))}
                  </select>
                </>
              )}
              {formSubmitted && !formData.inst_id && (
                <p className="text-red-500 text-sm mt-1">{t("required *")}</p>
              )}
              {/* {formSubmitted && !formData.branch_id && (
                <p className="text-red-500 text-sm mt-1">{t("required *")}</p>
              )} */}
            </div>

            {userType === 2 && (
              <div className="w-full">
                <label className="block mb-2 font-medium text-gray-700">
                  {t("Password Policy *")}
                </label>
                <select
                  id="pwd_policy_id"
                  name="pwd_policy_id"
                  value={formData.pwd_policy_id || ""}
                  onChange={handleInputChange}
                  className="block w-full p-3 border border-gray-300 rounded"
                >
                  <option value="" disabled>
                    {t("Select Password Policy")}
                  </option>
                  {passwordPolicyData?.map((policy) => (
                    <option
                      key={policy.pwd_policy_id}
                      value={policy.pwd_policy_id}
                    >
                      {policy.policy_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <ToggleButton
              isOn={formData.status === 1}
              onToggle={handleToggleStatus}
              label={formData.status === 1 ? "User Active" : "User Inactive"}
            />
          </div>

          <div className="flex flex-row mt-7 gap-3">
            <button
              type="submit"
              className={`bg-[#0f52ba] text-white py-2 px-6 rounded shadow hover:bg-blue-700 transition ${
                isSubmitting && "opacity-50 cursor-not-allowed"
              }`}
              disabled={isSubmitting}
            >
              {selectedUserData ? t("Update") : t("Submit")}
            </button>
            <button
              type="button"
              onClick={() => navigate("/body/user")}
              className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
            >
              {t("Back")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
