import { createSlice } from "@reduxjs/toolkit";

const ProfileSlice = createSlice({
  name: "profile",
  initialState: {
    items: null,
    isCollapse: false,
    profileView: [],
    selectedProfileView: [],
    selectedProfileDisplay: true,
    collectProfile:[]
  },
  reducers: {
    profileData: (state, action) => {
      state.items = action.payload; 
    },
    collectFetchProfile : (state, action) => {
     state.collectProfile = action.payload
    },
    setIsCollapse: (state, action) => {
      state.isCollapse = !state.isCollapse;
    },
    setProfileView: (state, action) => {
      state.profileView = action.payload;
    },
    setSelectedProfileView: (state, action) => {
      state.selectedProfileView = action.payload;
    },
    setSelectedProfileDisplay: (state, action) =>{
      state.selectedProfileDisplay = action.payload;
    },

    clearProfileData: (state) => {
      state.items = null;
      state.isCollapse = false;
      state.profileView = [];
      state.selectedProfileView = [];
      state.selectedProfileDisplay = false;
      state.collectusers = []
    },
  },
});

// Export the actions for use in components
export const { profileData, setIsCollapse, setProfileView, setSelectedProfileView , setSelectedProfileDisplay, clearProfileData, collectFetchProfile} =
ProfileSlice.actions;

// Export the reducer to be used in the store
export default ProfileSlice.reducer;
