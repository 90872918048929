import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import ToggleButton from "../../CustomReusableComps/ToggleButton";
import { InputField } from "../../CustomReusableComps/InputField";

const DepartmentForm = ({ selectedDepartment, onSubmit, isSubmitting }) => {
  const userData = useSelector((state) => state.userManagement.items.user_details
  );
  console.log(userData);
  const [departName, setDepartName] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [departmentStatus, setDepartmentStatus] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (selectedDepartment) {
      setDepartName(selectedDepartment?.department_name || "");
      setDepartmentStatus(selectedDepartment?.status);
    } else {
      setDepartName("");
      setDepartmentStatus(0);
    }
  }, [selectedDepartment]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    const payload = {
      department_id : selectedDepartment?.department_id || null,
      department_name: departName,
      status: departmentStatus,
      inst_id : userData.inst_id,
      branch_id : userData.branch_id
    };
    onSubmit(payload);
    console.log("Submitting payload:", payload);
  };

  const handleToggleStatus = (e) => {
    e.preventDefault();
    setDepartmentStatus((prevStatus) => (prevStatus === 0 ? 1 : 0));
  };

  const departmentNameLabel =
    formSubmitted && !departName ? (
      <div className="text-red-500 mt-1">
        {t("Department.Department_Name")} <span className="text-red-500">*</span>
      </div>
    ) : (
      t("Department.Department_Name")
    );

  return (
    <div>
      <h1 className="text-2xl ml-8 my-4 font-bold font-sans">{t("Department.adddepartment")}</h1>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg mx-6">
        <form onSubmit={handleSubmit} noValidate>
          <div className="flex flex-col px-10 pb-10 font-roboto w-full  flex-nowrap">
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
              <div className="mb-4 flex flex-col items-start w-full sm:w-1/2">
                <InputField
                label={departmentNameLabel}
                  type="text"
                  id="departName"
                  value={departName}
                  onChange={(e) => setDepartName(e.target.value.trim())}
                  className="w-full border p-2 rounded outline-none"
                  placeholder={t("Department.department_name_placeholder")}
                  required
                />
              </div>
              <Box display="flex" alignItems="center" gap={2} mt={2} mb={2}>
                <ToggleButton
                  isOn={departmentStatus === 1}
                  onToggle={handleToggleStatus}
                  label={departmentStatus === 1 ? t("Department.active") : t("Department.inactive")}
                />
              </Box>
            </div>

            <div className="flex flex-row mt-7 gap-3">
              <button
                type="submit"
                className={`bg-blue-500 text-white rounded shadow py-2 px-6 hover:bg-blue-600 transition  ${
                  isSubmitting && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? t("Deparment.submitting")
                  : t("Department.submit")}
              </button>
              <button
                type="button"
                onClick={() => navigate("/body/departmentList")}
                className="bg-slate-50 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
              >
                {t("Department.back")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DepartmentForm;
