import React, { useState, useEffect } from "react";
import Table from "../../CustomReusableComps/Table";
import { IconButton } from "@mui/material";
import Breadcrumbs from "../../MUI_Reusable_Comps/BreadCrumbs";
import Button from "../../MUI_Reusable_Comps/Button";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import ActionPopover from "../../MUI_Reusable_Comps/ActionPopOver";
import { COUNTER_LIST, DELETE_COUNTER } from "../../../Utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setCounterView,
  setSelectedCounterView,
} from "../../../Redux/CounterSlice";
import ShimmerTable from "../../useShimmerTable";

const Counter = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userManagement.items);
  console.log(userData);

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleOpenActions = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    console.log(selectedRow);
  };

  const fetchData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await fetch(COUNTER_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          page: page,
          no_of_data: pageSize,
          user_based_list: true,
          order_by: 1,
          keyword: "",
          inst_details: true,
          inst_id: userData?.user_details?.inst_id,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
      setTotalCount(responseData.count);
      dispatch(setCounterView(responseData.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddUserClick = () => {
    dispatch(setSelectedCounterView(null));
    navigate("/body/addCounter");
  };

  const handleEdit = (rowData) => {
    dispatch(setSelectedCounterView(rowData));
    navigate("/body/addCounter");
  };

  const handleView = (rowData) => {
    dispatch(setSelectedCounterView(rowData));
    navigate("/body/counterView");
  };

  const handleDelete = (rowData) => {
    console.log("Delete clicked for row:", rowData);
    setSelectedRow(rowData);
    handleDeleteCounter(rowData);
  };

  const handleDeleteCounter = async (rowData) => {
    if (!rowData || !rowData?.counter_id) {
      toast.error("Invalid user data for deletion.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(DELETE_COUNTER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify({
          counter_id: rowData?.counter_id,
        }),
      });
      const responseData = await response.json();
      console.log("Delete response:", responseData);

      if (response.ok) {
        fetchData(currentPage, pageSize);
        toast.success("User deleted successfully!");
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to delete user: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An error occurred while deleting the user.");
    } finally {
      setLoading(false);
      handleCloseActions();
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  const headers = [
    { key: "counter_id", label: "counters.counterid" },
    { key: "counter_name", label: t("counters.countername") },
    {
      key: "status",
      label: t("Department.status"),
      content: (item) => (item.status === 1 ? t("services.active") : t("services.inactive")),
    },
    { key: "created_by", label: t("services.createdby") },
    { key: "created_time", label: t("Department.created_time") },
    {
      key: "updated_by",
      label: t("constants.Updated_By"),
      content: (item) => (
        <div>{item && item.updated_by === 0 ? "N/A" : item.updated_by}</div>
      ),
    },
    {
      key: "updated_time",
      label: t("constants.Updated_Time"),
      content: (item) => (
        <div>
          {item && item.updated_time ? item.updated_time : "N/A"}
        </div>
      ),
    },

    {
      key: "action",
      label: t("UserPage.Actions"),
      nonExportable: true,
      columnStyle: { width: "1%" },
      content: (rowData) => (
        <div>
          <IconButton
            onClick={(event) => handleOpenActions(event, rowData)}
            color="primary"
            sx={{ height: "20px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="pt-8 pb-2 px-8 overflow-hidden">
      <div className=" mb-4 flex justify-between items-center">
        <Button name={t("counters.addcounter")} onClick={handleAddUserClick} />
        <Breadcrumbs
          options={[{ name: t("counter"), pathName: "/body/counter" }]}
        />
      </div>

      {loading ? (
        <ShimmerTable />
      ) : (
        <div className="">
          <Table
            data={data || []}
            headers={headers}
            loading={loading}
            searchPlaceholder="Search by name or email"
            filterOptions={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            totalRowsLabel={`Total Rows: ${totalCount}`}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            totalItems={totalCount}
          />
        </div>
      )}

      <ActionPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleCloseActions}
        selectedRow={selectedRow}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleView}
        hideBlock={true}
      />
    </div>
  );
};

export default Counter;
