import React, { useEffect, useState } from "react";
import { Switch, TextField, Button, Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmBox from "../../MUI_Reusable_Comps/ConfirmBox";
import { ADD_SERVICE, EDIT_SERVICE } from "../../../Utils/Constant";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setSelectedServicetView } from "../../../Redux/SettingsSlice";
import ToggleButton from "../../CustomReusableComps/ToggleButton";
import { useTranslation } from "react-i18next";

const ServiceManagement = () => {
  const [serviceGrpName, setServiceGrpName] = useState("");
  const [serviceGrpStatus, setServiceGrpStatus] = useState(0);
  const [addLayersService, setAddLayersService] = useState(false)
  const [childServices, setChildServices] = useState([]);
  const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [serviceToRemove, setServiceToRemove] = useState(null);
  const [ serviceAlreadyExistError, setServiceAlreadyExistError] = useState(false)
  console.log("serviceAlreadyExistError", serviceAlreadyExistError);
  const {t}=useTranslation()
  
  const token = useSelector((store) => store.token.token);
  const deviceId = useSelector((store) => store.token.deviceId);
  const selectedService = useSelector(
    (state) => state.setting.selectedServiceView
  );
  const userData = useSelector(
    (state) => state.userManagement.items.user_details
  );
  console.log("userData", userData);

  const [isEditMode, setIsEditMode] = useState(false);
  const [removedServices, setRemovedServices] = useState([]);
  console.log("removedServices", removedServices);

  const navigate = useNavigate();

  // Function to handle adding a child service layer
  const addChildServiceLayer = () => {
    const newLayer = {
      service_level: childServices.length + 1,
      service_name: "",
      status: 0,
      parent_id: 0,
    };
    setChildServices([...childServices, newLayer]);
  };

  useEffect(() => {
    if (selectedService) {
      setServiceGrpName(selectedService?.data?.svc_group_name || "");
      setServiceGrpStatus(selectedService?.data?.status);
      setAddLayersService(selectedService?.data?.multi_layer_flag)
      if (Array.isArray(selectedService?.data?.svc_layers)) {
        setChildServices(
          selectedService?.data?.svc_layers.map((layer) => ({
            service_level: layer.service_level,
            service_name: layer.service_name,
            status: layer.status,
            parent_id: layer.parent_id,
            service_id: layer.service_id,
          }))
        );
      }
      setIsEditMode(true);
    }
  }, [selectedService]);

  // Function to update child service layer details
  const updateChildService = (index, field, value) => {
    const updatedServices = childServices.map((service, idx) =>
      idx === index ? { ...service, [field]: value } : service
    );
    setChildServices(updatedServices);
  };

  const removeChildServiceLayer = () => {
    if (serviceToRemove !== null) {
      const serviceToDelete = childServices[serviceToRemove];
      console.log("serviceToDelete", serviceToDelete);

      // Add the removed service's ID to the removedServices array
      setRemovedServices([...removedServices, serviceToDelete.service_id]); // Assuming each service has a unique `id` property

      const updatedServices = childServices
        .filter((_, idx) => idx !== serviceToRemove)
        .map((service, idx) => ({
          ...service,
          service_level: idx + 1,
        }));

      setChildServices(updatedServices);
      setServiceToRemove(null);
      setOpenConfirmRemoveModal(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenConfirmRemoveModal(false);
  };

  const handleDeleteConfirm = (index) => {
    setServiceToRemove(index);
    setOpenConfirmRemoveModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    try {
      const url = isEditMode ? EDIT_SERVICE : ADD_SERVICE;
      const method = "POST";
      const payload = {
        svc_group_name: serviceGrpName,
        multi_layer_flag : addLayersService,
        svc_layers: childServices,
        status: serviceGrpStatus,
        svc_group_id: isEditMode
          ? selectedService?.data?.svc_group_id
          : undefined,
        del_ids: removedServices,
        inst_id: userData?.inst_id,
        branch_id: userData?.branch_id,
      };

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          DeviceID: deviceId,
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      console.log("Service response:", responseData);

      if(responseData.message === "Service name already exists"){
        setServiceAlreadyExistError(true)
      }
      if (response.ok) {
        toast.success(
          isEditMode
            ? "Service updated successfully!"
            : "Service added successfully!"
        );
     
        navigate("/body/service");
        resetForm();
      } else {
        toast.error(
          `Failed to ${isEditMode ? "update" : "add"} service: ${
            responseData.message || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error("Error submitting service:", error);
      toast.error("An error occurred");
    }
  };

  // Reset form after submission
  const resetForm = () => {
    setServiceGrpName("");
    setServiceGrpStatus(0);
    setChildServices([]);
    setIsEditMode(false);
    setSelectedServicetView(null);
  };
  const handleToggleStatus = (e) => {
    e.preventDefault();
    setServiceGrpStatus((prevStatus) => (prevStatus === 0 ? 1 : 0));
  };
  const handleAddLayers = (e) => {
    e.preventDefault();
    setAddLayersService((prevStatus) => (prevStatus === false ? true : false));
    console.log('addLayersService',addLayersService );
    
  }


  const serviceGrpNameLabel =
  formSubmitted && serviceAlreadyExistError ? (
    <div className="text-red-500 mt-1">
      {"Service Already Exist"} <span className="text-red-500">*</span>
    </div>
  ) : (
    ("Service Name")
  );

  return (
    <div>
      <h1 className="text-2xl mt-3 ml-8 my-1 font-bold font-sans">
        {isEditMode ? t("services.editservice") : t("services.addservice")}
      </h1>
      <div className="mt-5 bg-white p-6 rounded-lg shadow-lg mx-6 h-[700px] overflow-hidden">
        <Box>
          <TextField
            label={serviceGrpNameLabel}
            value={serviceGrpName}
            onChange={(e) => setServiceGrpName(e.target.value)}
            fullWidth
            margin="normal"
            error={serviceAlreadyExistError || ""}
          />

          {/* Service Group Status Toggle */}
          <Box display="flex" alignItems="center" gap={2} mt={2} mb={2}>
            <span className="text-xl font-medium text-gray-700">
              Service Status:
            </span>
            <ToggleButton
              isOn={serviceGrpStatus === 1}
              onToggle={handleToggleStatus}
              label={
                serviceGrpStatus === 1 ? t("services.active") : t("services.inactive")
              }
            />
          </Box>
          <div className="flex justify-end">
          <ToggleButton
              isOn={addLayersService === true}
              onToggle={handleAddLayers}
              label={"Add Multi Layers"}
            />
          </div>
          {/* Add Child Service Layer Button */}
          {childServices.length === 0 && addLayersService === true && (
            <Button
              variant="contained"
              color="primary"
              onClick={addChildServiceLayer}
              // disabled={!serviceGrpName.trim()}
            >
              Add Service Layers
            </Button>
          )}

          {/* Render Child Service Layers */}
          <Box
            className="space-y-4"
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              paddingRight: 2,
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#555",
                },
              },
            }}
          >
            {childServices.map((service, index) => (
              <Box
                key={service.service_level}
                className="border rounded-lg p-6 relative bg-gray-50"
              >
                <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
                  {t("services.ServiceLevel")}: {service.service_level}
                </div>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteConfirm(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <TextField
                  label={`(${t("services.servicename")} (${t("services.Level")} ${service.service_level})`}
                  value={service.service_name}
                  onChange={(e) =>
                    updateChildService(
                      index,
                      "service_name",
                      e.target.value.trim()
                    )
                  }
                  fullWidth
                  margin="normal"
                  error={formSubmitted && !service.service_name}
                  helperText={
                    formSubmitted && !service.service_name
                      ? "Service name is required *"
                      : ""
                  }
                />
                <Box display="flex" alignItems="center" gap={2}>
                  <span>{t("services.servicestatus")}:</span>
                  <Switch
                    checked={service.status === 1}
                    onChange={(e) =>
                      updateChildService(
                        index,
                        "status",
                        e.target.checked ? 1 : 0
                      )
                    }
                    color="primary"
                  />
                  <span>{service.status === 1 ? t("services.active") : t("services.inactive")}</span>
                </Box>
                <div className="flex justify-end">
                  {index === childServices.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addChildServiceLayer}
                    >
                      Add Another Service Layer
                    </Button>
                  )}
                </div>
              </Box>
            ))}
          </Box>

          {/* Conditionally Render Submit Button */}

         {/* {serviceGrpName.trim() && childServices.length > 0 && ( */}
             <div className="flex flex-row gap-4">
             <Button
               variant="contained"
               onClick={handleSubmit}
               sx={{
                 backgroundColor: "#FF5733",
                 color: "#FFFFFF",
                 "&:hover": {
                   backgroundColor: "#C13B1A",
                 },
                 marginTop: "2%",
               }}
             >
               {isEditMode ? "Update Service" : "Submit Service"}
             </Button>
             <button
               onClick={() => navigate("/body/service")}
               className="bg-slate-50 h-11 mt-3 text-color-light-blue rounded py-2 px-6 border-blue-500 border shadow hover:bg-white transition"
             >
               Back
             </button>
           </div>
         {/* )} */}
        </Box>

        {/* Confirmation Dialog */}
        <ConfirmBox
          open={openConfirmRemoveModal}
          onCancel={handleCloseDialog}
          onAgree={removeChildServiceLayer}
          title={t("services.confirm")}
          content={t("services.content")}
          remove={t("delete")}
          cancel={t("cancel")}
          disabled={false}
          style={{ minWidth: 400 }}
          focusOnCancel={true}
        />
      </div>
    </div>
  );
};

export default ServiceManagement;
